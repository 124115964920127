import {
  Dialog,
  Box,
  Typography,
  IconButton,
  Tooltip,
  styled,
  Button,
  TextField,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { CancelOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const HtmlLabel = styled("label")(`
    color : #828282ff;
    font-size : '1.1rem';

`);

const Btn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "5px 15px",
  borderRadius: 20,
  color: "#FFF",
  background: "#3DBE72",
  boxShadow: "none",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
}));

const Btn1 = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "5px 15px",
  borderRadius: 20,
  bgcolor: "#fff",
  color: "#3dbe72",
  boxShadow: "none",
}));

const CreateCampaignReportDialog = ({ open, set }) => {
  const navigate = useNavigate();

  const [campaignName, setCampaignName] = useState("");
  const [brandName, setBrandName] = useState("");
  const [budget, setBudget] = useState(null);

  const handleCreateReport = () => {
    navigate("/dashboard/campaign-report/create");
  };

  return (
    <Dialog
      open={open}
      onClose={() => set(false)}
      PaperProps={{
        sx: {
          borderRadius: 3,
          border: "2px solid black",
          padding: 4,
        },
      }}
    >
      <Box>
        <Grid container>
          <Grid item size={{ md: 11, xs: 11 }}>
            <Typography
              sx={{
                color: "#3dbf73ff",
                fontWeight: "bold",
                fontSize: "1.2rem",
              }}
            >
              Create Campaign Report
            </Typography>
          </Grid>
          <Grid item size={{ md: 1, xs: 1 }} align="end">
            <Tooltip title={"Close"} arrow placement="right">
              <IconButton size={"small"} onClick={() => set(false)}>
                <CancelOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box>
              <HtmlLabel htmlFor="name">Campaign Name</HtmlLabel>
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                id="name"
                label="Enter the Campaign Name"
                variant="outlined"
                value={campaignName}
                onChange={(e) => setCampaignName(e.target.value)}
              />
            </Box>
          </Grid>

          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box>
              <HtmlLabel htmlFor="brandName">Brand Name</HtmlLabel>
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                id="brandName"
                label="Enter Brand Name"
                variant="outlined"
                value={brandName}
                onChange={(e) => setBrandName(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Box>
              <HtmlLabel htmlFor="budget">Budget</HtmlLabel>
            </Box>
            <Box mt={1}>
              <TextField
                fullWidth
                id="budget"
                type="number"
                label="Enter budget"
                variant="outlined"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid container sx={{ marginTop: 2, justifyContent: "end" }}>
          <Grid item size={{ md: 2, xs: 2 }} align="end">
            <Btn1 onClick={() => set(false)} variant="text">
              Cancel
            </Btn1>
          </Grid>
          <Grid item size={{ md: 2, xs: 2 }} align="end">
            <Btn
              disabled={!campaignName || !brandName || !budget}
              onClick={() => handleCreateReport()}
            >
              Create
            </Btn>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default CreateCampaignReportDialog;
