import {
  Box,
  Button,
  Container,
  Grid2,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import CreateCampaignTabs from "../subcomponents/campaign/CreateCampaignTabs";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const Btn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "5px 15px",
  marginTop: "1rem",
  borderRadius: 20,
  background: "#3DBE72",
  boxShadow: "none",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
}));

const CreateCampaign = () => {
  return (
    <Box sx={{ width: "100%", paddingY: 5, paddingLeft: 3, paddingRight: 0 }}>
      <Grid2 container sx={{ paddingRight: 0, width: "100%" }}>
        <Grid2 item size={{ sm: 12, lg: 8 }}>
          <Title>Campaigns</Title>
        </Grid2>
      </Grid2>
      <Grid2 container sx={{ paddingRight: 0, width: "100%" }}>
        <CreateCampaignTabs />
        {/* <InfluencerCreateCampaignTabs /> */}
      </Grid2>
    </Box>
  );
};

export default CreateCampaign;
