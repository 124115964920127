import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import CampaignAccordion from "./CampaignAccordion";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Checkbox from "@mui/material/Checkbox";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import LinkIcon from "@mui/icons-material/Link";
import HelpIcon from "@mui/icons-material/Help";
import IG from "../../../images/insta-sm.png";
import YT from "../../../images/youtube-sm.png";
import FB from "../../../images/facebook.png";
import YouTubeIcon from "@mui/icons-material/YouTube";

import {
  rowData,
  campaignInfluencerData,
  draftContentData,
  deliverablesData,
} from "../../campaigns/campaigndata";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Button,
  FormControl,
  Grid2,
  Input,
  InputAdornment,
  LinearProgress,
  linearProgressClasses,
  MenuItem,
  styled,
  Switch,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import CampaignDataTable from "./CampaignDataTable";
import { useState } from "react";
import InviteDialog from "./InviteDialog";
import InviteDialog1 from "./InviteDialog1";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextsmsIcon from "@mui/icons-material/Textsms";
import DeleteIcon from "@mui/icons-material/Delete";
import { FileUploader } from "react-drag-drop-files";
import CustomizeListDialog from "./CustomizeListDialog";
import MinMaxSliderComponent from "./MinMaxSliderComponent";
import { CalendarMonthOutlined } from "@mui/icons-material";
import Menu from "@mui/material/Menu";
import { useEffect } from "react";
import { useMemo } from "react";
import OverviewTab from "./OverviewTab";
import { Link } from "react-router-dom";
import ChatComponent from "./ChatComponent";

const temptDP =
  "https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg";

const Btn = styled(Button)(({ theme }) => ({
  color: "#FFF",
  textTransform: "none",
  padding: "5px 15px",
  // marginTop: "1rem",
  borderRadius: 20,
  background: "#3DBE72",
  boxShadow: "none",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
}));

const CustomTab = styled(Tab)(({ isActive }) => ({
  border: isActive ? `1px solid #3DBE72` : "none", // Change the color as needed
  borderRadius: 5,
  color: isActive ? "#3DBE72" : "#000", // Change text color for active tab
  "&:hover": {
    color: "#3DBE72", // Change text color on hover
  },
}));

const CustomTab1 = styled(Tab)(({ isActive }) => ({
  backgroundColor: isActive ? "#3DBE72" : "#fff", // Custom background color for active tab
  color: isActive ? "white !important" : "#000", // Custom text color for active tab
  borderRadius: isActive ? 30 : "0px", // Custom border radius for active tab
  "&:hover": {
    backgroundColor: isActive ? "#3DBE72" : "#f0f0f0", // Change background color on hover
    color: "#3DBE72", // Change text color on hover
  },
  minHeight: "30px !important",
  height: "30px !important",
  maxHeight: "30px !important",
  marginLeft: 3,
  marginRight: 3,
  paddingTop: 2,
  paddingBottom: 2,
}));

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const label1 = { inputProps: { "aria-label": "Switch demo" } };

const socials = [
  { value: "Instagram", label: "Instagram", logo: IG },
  { value: "Youtube", label: "Youtube", logo: IG },
  { value: "Twitter", label: "Twitter", logo: IG },
  { value: "Facebook", label: "Facebook", logo: IG },
];

const sortOptions = [
  { value: "video views", label: "Video views" },
  { value: "engagement", label: "Engagement" },
  { value: "cpv", label: "CPV" },
  { value: "cpe", label: "CPE" },
];

const contentPostedArr = [
  "All",
  "None",
  "1 Post",
  "2 Posts",
  "3 Posts",
  "4 Posts",
  "5 Posts",
  "6 Posts",
  "7 Posts",
  "8 Posts",
  "9 Posts",
  "10 Posts",
];

const customStyle = {
  head: {
    style: {
      fontSize: 16,
      fontWeight: 500,
    },
  },
  rows: {
    style: {
      minHeight: 250,
      cursor: "pointer",
    },
  },
};

function formatNumber(num) {
  try {
    // Convert the input to a number in case it's a string
    const number = Number(num);

    // Check if the conversion was successful
    if (isNaN(number)) {
      throw new Error("Invalid number");
    }

    // Format the number based on its value
    if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + "M"; // Convert to millions
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + "K"; // Convert to thousands
    } else {
      return number.toString(); // Return the number as is
    }
  } catch (error) {
    return "error";
  }
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ padding: 0, width: "100%", margin: 0 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            py: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            paddingX: 0,
            marginX: 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function a11yProps1(index) {
  return {
    id: `simple-tab1-${index}`,
    "aria-controls": `simple-tabpanel1-${index}`,
  };
}

function a11yProps2(index) {
  return {
    id: `simple-tab2-${index}`,
    "aria-controls": `simple-tabpanel2-${index}`,
  };
}

function a11yProps3(index) {
  return {
    id: `simple-tab3-${index}`,
    "aria-controls": `simple-tabpanel3-${index}`,
  };
}

export default function InfluencerCampaignTabs() {
  const [value, setValue] = useState(0);
  const [value1, setValue1] = useState(0);
  const [value2, setValue2] = useState(0);
  const [value3, setValue3] = useState(0);
  const [value4, setValue4] = useState(0);
  const [value5, setValue5] = useState(0);

  const [openCustomizeList, setOpenCustomizeList] = useState(false);

  const [file, setFile] = useState(null);

  const fileTypes = ["JPG", "PNG", "GIF"];

  // file change handler
  const handleChangeFile = (file) => {
    setFile(file);
    console.log(file);
  };

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleRowClick = (row) => {
    setSelectedRow(row);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedRow(null);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChange1 = (event, newValue) => {
    setValue1(newValue);
  };

  const handleChange2 = (event, newValue) => {
    setValue2(newValue);
  };

  const handleChange3 = (event, newValue) => {
    setValue3(newValue);
  };

  const handleChange4 = (event, newValue) => {
    setValue4(newValue);
  };

  // draft content data state
  const [draftContentDataState, setDraftContentDataState] =
    useState(draftContentData);

  // force state update
  const [dataKey, setDataKey] = useState(0);

  // status change handler
  const handleStatusChange = (rowId, newStatus) => {
    setDraftContentDataState((prevRows) => {
      const updatedRows = prevRows.map((row) =>
        row.id === rowId ? { ...row, status: newStatus } : row
      );
      setDataKey((prev) => prev + 1);
      console.log(updatedRows); // Log the updated rows to verify
      return updatedRows; // Return the updated rows
    });
  };

  useEffect(() => {}, [draftContentDataState]);

  // custom columns for the datatable
  const findBackgroundColor = (status) => {
    switch (status) {
      case "Invited":
        return "#FFA84C";
      case "Not Invited":
        return "red";
      case "Rejected":
        return "red";
      case "Approved":
        return "green";
      case "Inactive":
        return "#FF2323";
      case "Pending":
        return "#cddc39";
      default:
        return "#FFF";
    }
  };

  const CustomAccount = ({ row }) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, py: 1 }}>
      <Avatar alt={row.name} src={row.platform.logo} />
      <Typography sx={{ display: "flex", flexDirection: "column" }}>
        <span style={{ fontWeight: 550 }}>{row.name}</span>
        <span style={{ fontSize: 13 }}>{row?.handle}</span>
      </Typography>
    </Box>
  );

  const CustomProfile = ({ row }) => (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        py: 1,
        width: "100%",
      }}
    >
      {row?.draft_media?.map(({ url }) => (
        <Box sx={{ display: "flex", alignItems: "center", gap: 4 }}>
          <img
            alt={url}
            src={url}
            width={150}
            height={200}
            style={{
              borderRadius: "10px",
              border: "1px solid #3DBE72",
              objectFit: "cover",
            }}
          />
          <Typography sx={{ display: "flex", flexDirection: "column" }}>
            <span style={{ fontWeight: 550 }}>{row.name}</span>
            <span style={{ fontSize: 13 }}>{row?.handle}</span>
          </Typography>
        </Box>
      ))}
    </Box>
  );

  const CustomDeliverables = ({ row }) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <YouTubeIcon color="grey" sx={{ color: "grey", fontSize: "15px" }} />
      <Typography sx={{ fontSize: "14px" }}>
        <span style={{ fontWeight: 550 }}>8</span> of 8
      </Typography>
    </Box>
  );

  const CustomPlatform = ({ row }) => (
    <Box>
      <Avatar alt={row.name} src={row.platform.logo} sizes="20" />
    </Box>
  );

  const CustomVerified = ({ row }) => (
    <span style={{ marginLeft: "auto", marginRight: "auto" }}>
      {row?.isVerified ? (
        <CheckCircleIcon sx={{ color: "#75f94c" }} />
      ) : (
        <CancelIcon sx={{ color: "#eb3223" }} />
      )}
    </span>
  );

  const CustomStatus = ({ row }) => (
    <Box
      sx={{
        background: findBackgroundColor(row.status),
        padding: 1,
        width: "100%",
        textAlign: "center",
        color: "#FFFFFF",
        borderRadius: 30,
      }}
    >
      {row.status}
    </Box>
  );

  const CustomDraft = ({ row }) => (
    <Box
      sx={{
        width: "120px",
        height: "150px",
        borderRadius: 3,
        overflow: "hidden",
        marginY: 3,
      }}
    >
      {row?.draft_media?.map(({ url }) => {
        return (
          <img
            src={url}
            alt="draft media"
            style={{ width: "100%", height: "100" }}
          />
        );
      })}
    </Box>
  );

  const CustomMenu = ({ row }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (newStatus) => {
      handleStatusChange(row.id, newStatus); // Update the status
      setAnchorEl(null);
    };

    return (
      <Box>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          Edit Status
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <MenuItem onClick={() => handleClose("Approved")}>Approve</MenuItem>
          <MenuItem onClick={() => handleClose("Rejected")}>Reject</MenuItem>
        </Menu>
      </Box>
    );
  };

  const CustomRejectionMsg = ({ row }) => (
    <Box
      sx={{
        borderRadius: 3,
        bgcolor: "#dde8f8",
        width: "100%",
      }}
    >
      {row?.status === "Rejected" && (
        <TextareaAutosize
          minRows={6}
          style={{
            resize: "none",
            border: "none",
            backgroundColor: "#dde8f8",
            borderRadius: 10,
            width: "100%",
            padding: "7px",
          }}
          placeholder="Enter reason for rejection..."
        />
      )}
    </Box>
  );

  const CustomLink = ({ row }) => (
    <Link to={"#"}>
      <img
        src={IG}
        alt="social link"
        style={{ width: 30, height: 30, objectFit: "cover" }}
      />
    </Link>
  );

  const CustomReport = ({ row }) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <DescriptionIcon
        color="action"
        sx={{ cursor: "pointer" }}
        onClick={() => console.log(row, "Report")}
      />
    </Box>
  );

  const CustomActions = ({ row }) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <RemoveRedEyeIcon
        // sx={{ cursor: "pointer" }}
        color="action"
        onClick={() => console.log(row, "Hide row")}
      />
      {/* sx={{ cursor: "pointer" }} */}
      <LinkIcon color="action" onClick={() => console.log("Share link")} />
    </Box>
  );

  const CustomActions1 = ({ row }) => (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
      <AddCircleIcon
        color="action"
        // sx={{ cursor: "pointer" }}
        onClick={() => console.log(row, "Hide row")}
      />
      <TextsmsIcon
        color="action"
        // sx={{ cursor: "pointer" }}
        onClick={() => console.log(row, "Hide row")}
      />
      <DeleteIcon
        color="action"
        // sx={{ cursor: "pointer" }}
        onClick={() => console.log(row, "Hide row")}
      />
    </Box>
  );

  const allColumns = [
    {
      name: "S.NO.",
      selector: (row) => row.id,
      center: true,
      minWidth: "50px",
      maxWidth: "auto",
      width: "100px",
      reorder: true,
      cell: (row, rowIndex) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={false}
              onClick={(e) => {
                console.log("Clicked");
              }}
            />
          </Box>
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.brand,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomAccount row={row} />,
      width: "180px",
    },
    {
      name: "Platform",
      selector: (row) => row.platform.name,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomPlatform row={row} />,
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomStatus row={row} />,
    },
    {
      name: "Your offer",
      selector: (row) => row.your_offer,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Counter offer",
      selector: (row) => row.counter_offer,
      sortable: true,
      reorder: true,
      minWidth: "150px",
    },
    {
      name: "Invited",
      selector: (row) => row.invited,
      sortable: true,
      reorder: true,
    },
    {
      name: "Last Updated",
      selector: (row) => row.date,
      sortable: true,
      reorder: true,
      width: "150px",
    },
    {
      name: " ",
      sortable: false,
      reorder: false,
      cell: (row) => <CustomActions row={row} />,
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
      headerAlign: "center",
    },
  }));

  const invitesColumn = [
    {
      name: "S.NO.",
      selector: (row) => row.id,
      center: true,
      minWidth: "50px",
      maxWidth: "auto",
      width: "100px",
      reorder: true,
      cell: (row, rowIndex) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={false}
              onClick={(e) => {
                console.log("Clicked");
              }}
            />
          </Box>
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.brand,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomAccount row={row} />,
      width: "180px",
    },
    {
      name: "Platform",
      selector: (row) => row.platform.name,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomPlatform row={row} />,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomStatus row={row} />,
    },
    {
      name: "Your offer",
      selector: (row) => row.your_offer,
      sortable: true,
      reorder: true,
      // width: '120px',
    },
    {
      name: "Counter offer",
      selector: (row) => row.counter_offer,
      sortable: true,
      reorder: true,
      minWidth: "150px",
    },
    {
      name: "Invited",
      selector: (row) => row.invited,
      sortable: true,
      reorder: true,
      // width: '120px',
    },
    {
      name: "Last Updated",
      selector: (row) => row.date,
      sortable: true,
      reorder: true,
      // cell: row => <CustomAverage row={row} />
    },
    {
      name: " ",
      sortable: false,
      reorder: false,
      cell: (row) => <CustomActions row={row} />,
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
      headerAlign: "center",
    },
  }));

  const approvedColumn = [
    {
      name: "S.NO.",
      selector: (row) => row.id,
      center: true,
      minWidth: "50px",
      maxWidth: "auto",
      width: "fit-content",
      reorder: true,
      cell: (row, rowIndex) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={false}
              onClick={(e) => {
                console.log("Clicked");
              }}
            />
          </Box>
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.brand,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomAccount row={row} />,
      width: "180px",
    },
    {
      name: "Platform",
      selector: (row) => row.platform.name,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomPlatform row={row} />,
      width: "120px",
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Followers",
      selector: (row) => row.followers,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Engagement",
      selector: (row) => row.engagement_rate,
      sortable: true,
      reorder: true,
      width: "140px",
    },
    {
      name: "Avg. Comments",
      selector: (row) => row.avg_comments,
      sortable: true,
      reorder: true,
      width: "180px",
    },
    {
      name: "Avg. Likes",
      selector: (row) => row.avg_likes,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      reorder: true,
    },
    {
      name: "Verified",
      selector: (row) => row.isVerified,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomVerified row={row} />,
    },
    {
      name: "Added date",
      selector: (row) => row.added_date,
      sortable: true,
      reorder: true,
      width: "140px",
    },
    {
      name: "% Male",
      selector: (row) => row.male_percentage,
      sortable: true,
      reorder: true,
    },
    {
      name: "% Female",
      selector: (row) => row.female_percentage,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Report",
      sortable: true,
      reorder: true,
      cell: (row) => <CustomReport row={row} />,
    },
    {
      name: " ",
      sortable: false,
      reorder: false,
      cell: (row) => <CustomActions1 row={row} />,
      width: "140px",
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
      headerAlign: "center",
    },
  }));

  const influencersColumn = [
    {
      name: "S.NO.",
      selector: (row) => row.id,
      center: true,
      minWidth: "50px",
      maxWidth: "auto",
      width: "fit-content",
      reorder: true,
      cell: (row, rowIndex) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={false}
              onClick={(e) => {
                console.log("Clicked");
              }}
            />
          </Box>
        );
      },
    },
    {
      name: "Influencer Name",
      selector: (row) => row.brand,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomAccount row={row} />,
      width: "180px",
    },
    {
      name: "Platform",
      selector: (row) => row.platform.name,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomPlatform row={row} />,
      width: "120px",
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Followers",
      selector: (row) => row.followers,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Engagement",
      selector: (row) => row.engagement_rate,
      sortable: true,
      reorder: true,
      width: "140px",
    },
    {
      name: "Avg. Comments",
      selector: (row) => row.avg_comments,
      sortable: true,
      reorder: true,
      width: "180px",
    },
    {
      name: "Avg. Likes",
      selector: (row) => row.avg_likes,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      reorder: true,
    },
    {
      name: "Verified",
      selector: (row) => row.isVerified,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomVerified row={row} />,
    },
    {
      name: "Added date",
      selector: (row) => row.added_date,
      sortable: true,
      reorder: true,
      width: "140px",
    },
    {
      name: "% Male",
      selector: (row) => row.male_percentage,
      sortable: true,
      reorder: true,
    },
    {
      name: "% Female",
      selector: (row) => row.female_percentage,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Report",
      sortable: true,
      reorder: true,
      cell: (row) => <CustomReport row={row} />,
    },
    {
      name: " ",
      sortable: false,
      reorder: false,
      cell: (row) => <CustomActions1 row={row} />,
      width: "140px",
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
      headerAlign: "center",
    },
  }));

  const draftColumns = [
    {
      name: "S.NO.",
      selector: (row) => row.id,
      center: true,
      minWidth: "50px",
      maxWidth: "auto",
      width: "100px",
      reorder: true,
      cell: (row, rowIndex) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={false}
              onClick={(e) => {
                console.log("Clicked");
              }}
            />
          </Box>
        );
      },
    },
    {
      name: "Name",
      selector: (row) => row.brand,
      sortable: true,
      cell: (row) => <CustomAccount row={row} />,
      width: "180px",
    },
    {
      name: "Platform",
      selector: (row) => row.platform.name,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomPlatform row={row} />,
      width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomStatus row={row} />,
      width: "120px",
    },
    {
      name: "Draft",
      selector: (row) => row.draft_media,
      sortable: false,
      reorder: true,
      cell: (row) => <CustomDraft row={row} />,
    },
    {
      name: "Actions",
      selector: (row) => row,
      sortable: false,
      reorder: false,
      cell: (row) => <CustomMenu row={row} />,
    },
    {
      name: " ",
      selector: (row) => row,
      sortable: false,
      reorder: false,
      cell: (row) => <CustomRejectionMsg row={row} />,
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
      headerAlign: "center",
    },
  }));

  const deliverableColumn = [
    {
      name: "S.NO.",
      selector: (row) => row.id,
      center: true,
      minWidth: "50px",
      maxWidth: "auto",
      width: "100px",
      reorder: true,
      cell: (row, rowIndex) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={false}
              onClick={(e) => {
                console.log("Clicked");
              }}
            />
          </Box>
        );
      },
    },
    {
      name: "Influencer Profile",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomAccount row={row} />,
      width: "180px",
    },
    {
      name: "Deliverables",
      selector: (row) => row.deliverables,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomDeliverables row={row} />,
      width: "150px",
    },
    {
      name: "Total Cost",
      selector: (row) => row.total_cost,
      sortable: true,
      reorder: true,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.total_cost)}
        </Typography>
      ),
      width: "120px",
    },
    {
      name: "Likes",
      selector: (row) => row.likes,
      sortable: true,
      reorder: true,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.likes)}
        </Typography>
      ),
      width: "100px",
    },
    {
      name: "Comments",
      selector: (row) => row.comments,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.comments)}
        </Typography>
      ),
      width: "130px",
    },
    {
      name: "Video Views",
      selector: (row) => row.video_views,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.video_views)}
        </Typography>
      ),
      width: "140px",
    },
    {
      name: "CPV",
      selector: (row) => row.cpv,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>{row.cpv}</Typography>
      ),
      width: "80px",
    },
    {
      name: "CPE",
      selector: (row) => row.cpe,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>{row.cpe}</Typography>
      ),
      width: "80px",
    },
    {
      name: "Engagement Rate",
      selector: (row) => row.engagement_rate,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.engagement_rate)}
        </Typography>
      ),
      width: "max-content",
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
      headerAlign: "center",
    },
  }));

  const contentStatColumn = [
    {
      name: "S.NO.",
      selector: (row) => row.id,
      center: true,
      minWidth: "50px",
      maxWidth: "auto",
      width: "100px",
      reorder: true,
      cell: (row, rowIndex) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={false}
              onClick={(e) => {
                console.log("Clicked");
              }}
            />
          </Box>
        );
      },
    },
    {
      name: "Influencer Profile",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomAccount row={row} />,
      width: "180px",
    },
    {
      name: "Deliverables",
      selector: (row) => row.deliverables,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomDeliverables row={row} />,
      width: "150px",
    },
    {
      name: "Total Reach",
      selector: (row) => row.likes,
      sortable: true,
      reorder: true,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.likes)}
        </Typography>
      ),
      width: "100px",
    },
    {
      name: "Total Engagements",
      selector: (row) => row.comments,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.comments)}
        </Typography>
      ),
      width: "130px",
    },
    {
      name: "CPV",
      selector: (row) => row.cpv,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>{row.cpv}</Typography>
      ),
      width: "80px",
    },
    {
      name: "CPE",
      selector: (row) => row.cpe,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>{row.cpe}</Typography>
      ),
      width: "80px",
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
      headerAlign: "center",
    },
  }));

  const publishedContentColumn = [
    {
      name: "S.NO.",
      selector: (row) => row.id,
      center: true,
      minWidth: "50px",
      maxWidth: "auto",
      width: "100px",
      reorder: true,
      cell: (row, rowIndex) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={false}
              onClick={(e) => {
                console.log("Clicked");
              }}
            />
          </Box>
        );
      },
    },
    {
      name: "Profile",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomProfile row={row} />,
      width: "350px",
    },
    {
      name: "Post Link",
      selector: (row) => row.deliverables,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomLink row={row} />,
      width: "100px",
    },

    {
      name: "Likes",
      selector: (row) => row.likes,
      sortable: true,
      reorder: true,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.likes)}
        </Typography>
      ),
      width: "100px",
    },
    {
      name: "Comments",
      selector: (row) => row.comments,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.comments)}
        </Typography>
      ),
      width: "130px",
    },
    {
      name: "Video Views",
      selector: (row) => row.video_views,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.video_views)}
        </Typography>
      ),
      width: "140px",
    },
    {
      name: "CPV",
      selector: (row) => row.cpv,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>{row.cpv}</Typography>
      ),
      width: "80px",
    },
    {
      name: "CPE",
      selector: (row) => row.cpe,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>{row.cpe}</Typography>
      ),
      width: "80px",
    },
    {
      name: "Engagement Rate",
      selector: (row) => row.engagement_rate,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.engagement_rate)}
        </Typography>
      ),
      // width: "max-content",
    },
    {
      name: "Uploaded at",
      selector: (row) => row.engagement_rate,
      sortable: true,
      reorder: false,
      cell: (row) => <Typography sx={{ fontWeight: 500 }}>date</Typography>,
      // width: "max-content",
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
      headerAlign: "center",
    },
  }));

  const customDropZoneContent = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "2px dashed #abababff",
        height: "120px",
        borderRadius: 2,
        cursor: "pointer",
      }}
    >
      {file ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{file.name}</Typography>

          {/* <Tooltip title="Remove" size={'small'} arrow placement='right'>
                <IconButton size='small' onClick={() => setFile(null)}>
                    <DeleteOutlineOutlined/>
                </IconButton>
                </Tooltip> */}
        </Box>
      ) : (
        <p>
          {" "}
          Click to{" "}
          <span style={{ color: "#3dbf73ff", padding: "0px 5px" }}>
            {" "}
            Browse{" "}
          </span>{" "}
          File or Drag & Drop to upload
        </p>
      )}
    </Box>
  );

  const paymentMethods = [
    { label: "Before Live", value: "Before Live" },
    { label: "After Live", value: "After Live" },
    { label: "After Approval", value: "After Approval" },
    { label: "Custom", value: "Custom" },
  ];

  // sort data state
  const [selectedSortOption, setSelectedSortOption] = useState("video views");

  const sortData = (data) => {
    return data.sort((a, b) => {
      switch (selectedSortOption) {
        case "video views":
          return b.video_views - a.video_views; // Sort by video views in descending order
        case "engagement":
          return b.engagement - a.engagement; // Sort by engagement in descending order
        case "cpv":
          return b.cpv - a.cpv; // Sort by CPV in descending order
        case "cpe":
          return b.cpe - a.cpe; // Sort by CPE in descending order
        default:
          return 0; // No sorting
      }
    });
  };

  let sortedRowData = useMemo(() => {
    return sortData([...deliverablesData]); // Make a copy of the data to sort
  }, [selectedSortOption, deliverablesData]);

  const handleSortChange = (event) => {
    setSelectedSortOption(event.target.value);
  };

  useEffect(() => {}, [sortedRowData]);

  const [anchorElContentPosted, setAnchorElContentPosted] = useState(null);
  const openContentPosted = Boolean(anchorElContentPosted);

  const handleContentPostedClick = (event) => {
    setAnchorElContentPosted(event.currentTarget);
  };

  const handleContentPostedClose = (newStatus) => {
    setAnchorElContentPosted(null);
  };

  return (
    <Box sx={{ width: "100%", py: 5, px: 0 }}>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              display: "none", // Hide the underline
            },
          }}
        >
          <Tab label="Campaign details" {...a11yProps(0)} />
          <Tab label="Draft Content" {...a11yProps(1)} />
          <Tab label="My Content" {...a11yProps(2)} />
          <Tab label="Content Statistics" {...a11yProps(2)} />
          <Tab label="Chat" {...a11yProps(2)} />
        </Tabs>
      </Box>
      {/* Campaign tab  */}
      <CustomTabPanel value={value} index={0}>
        {/* campaign details  */}
        <Accordion
          defaultExpanded
          sx={{
            "& .MuiPaper-root": { boxShadow: 0 },
            "& .MuiAccordion-root": { boxShadow: 0 },
            boxShadow: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "1.3rem" }}>
              1. Campaign Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container sx={{ rowGap: 5 }}>
              <Grid2
                container
                size={{ xs: 12 }}
                sx={{ justifyContent: "space-between" }}
              >
                <Grid2 item size={{ xs: 12, md: 7.25 }}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Campaign Name
                  </Typography>
                  {/* <TextField
                    sx={{
                      bgcolor: "#dde8f8",
                      borderRadius: 3,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // Remove the border
                        },
                        "&:hover fieldset": {
                          border: "none", // Remove the border on hover
                        },
                        "&.Mui-focused fieldset": {
                          border: "none", // Remove the border when focused
                        },
                      },
                    }}
                    fullWidth
                    size="small"
                    variant="outlined"
                  /> */}
                  <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                    Campaign Name
                  </Typography>
                </Grid2>
                <Grid2 item size={{ xs: 12, md: 4.25 }}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Campaign Platform
                  </Typography>
                  {/* <TextField
                    id="outlined-select-socials"
                    select
                    defaultValue=""
                    sx={{
                      bgcolor: "#dde8f8",
                      borderRadius: 3,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", 
                        },
                        "&:hover fieldset": {
                          border: "none", 
                        },
                        "&.Mui-focused fieldset": {
                          border: "none", 
                        },
                      },
                    }}
                    fullWidth
                    size="small"
                    variant="outlined"
                  >
                    {socials.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography sx={{ width: "100%", fontSize: 15 }}>
                          {option.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </TextField> */}
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                      Platform
                    </Typography>
                    <img src={IG} alt="platform logo" />
                  </Box>
                </Grid2>
                <Grid2 item sx={{ marginTop: 5 }} size={{ xs: 12, md: 4.25 }}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Influencer Account
                  </Typography>
                  {/* <TextField
                    id="outlined-select-socials"
                    select
                    defaultValue=""
                    sx={{
                      bgcolor: "#dde8f8",
                      borderRadius: 3,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", 
                        },
                        "&:hover fieldset": {
                          border: "none", 
                        },
                        "&.Mui-focused fieldset": {
                          border: "none", 
                        },
                      },
                    }}
                    fullWidth
                    size="small"
                    variant="outlined"
                  >
                    {socials.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{
                          display: "flex",
                          width: "100%",
                          alignItems: "center",
                          gap: 1,
                        }}
                      >
                        <Typography sx={{ width: "100%", fontSize: 15 }}>
                          {option.label}
                        </Typography>
                      </MenuItem>
                    ))}
                  </TextField> */}
                  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                    <Avatar
                      src={temptDP}
                      sx={{ width: 50, height: 50 }}
                      alt="influencer avatar"
                    />
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 0.5,
                      }}
                    >
                      <Typography sx={{ fontWeight: 500, fontSize: 15 }}>
                        Influencer name
                      </Typography>
                      <Typography sx={{ fontWeight: 500, fontSize: 15 }}>
                        @handle
                      </Typography>
                    </Box>
                  </Box>
                </Grid2>
              </Grid2>
              {/* <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Btn>Save</Btn>
              </Box> */}
            </Grid2>
          </AccordionDetails>
        </Accordion>

        {/* brand details  */}
        <Accordion
          defaultExpanded
          sx={{
            "& .MuiPaper-root": { boxShadow: 0 },
            "& .MuiAccordion-root": { boxShadow: 0 },
            boxShadow: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                justifyContent: "flex-start",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                2. Brand Details
              </Typography>
              <img src={IG} alt="Brand logo" />
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container sx={{ rowGap: 5 }}>
              <Grid2
                container
                size={{ xs: 12 }}
                sx={{ justifyContent: "space-between" }}
              >
                <Grid2 item size={{ xs: 12, md: 5.5 }}>
                  <Typography sx={{ marginBottom: 1 }}>Brand Name</Typography>
                  {/* <TextField
                    placeholder="Enter Brand Name"
                    sx={{
                      bgcolor: "#dde8f8",
                      borderRadius: 3,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // Remove the border
                        },
                        "&:hover fieldset": {
                          border: "none", // Remove the border on hover
                        },
                        "&.Mui-focused fieldset": {
                          border: "none", // Remove the border when focused
                        },
                      },
                    }}
                    fullWidth
                    size="small"
                    variant="outlined"
                  /> */}
                  <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                    name
                  </Typography>
                </Grid2>
                <Grid2 item size={{ xs: 12, md: 5.5 }}>
                  <Typography sx={{ marginBottom: 1 }}>
                    Brand Hashtags
                  </Typography>
                  {/* <TextField
                    placeholder="Enter Brand Hashtags"
                    sx={{
                      bgcolor: "#dde8f8",
                      borderRadius: 3,
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none", // Remove the border
                        },
                        "&:hover fieldset": {
                          border: "none", // Remove the border on hover
                        },
                        "&.Mui-focused fieldset": {
                          border: "none", // Remove the border when focused
                        },
                      },
                    }}
                    fullWidth
                    size="small"
                    variant="outlined"
                  /> */}
                  <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                    Hashtags
                  </Typography>
                </Grid2>
              </Grid2>
              <Grid2 container size={{ xs: 12 }}>
                <Typography sx={{ marginBottom: 1 }}>Brand Socials</Typography>
                <Grid2
                  container
                  size={{ xs: 12 }}
                  sx={{ justifyContent: "space-between" }}
                >
                  <Grid2 item size={{ xs: 12, md: 5.5 }}>
                    {/* <FormControl
                      variant="outlined"
                      size="large"
                      sx={{
                        width: "100%",
                        height: "100%",
                        bgcolor: "#dde8f8",
                        borderRadius: 3,
                        px: 2,
                        py: 1,
                      }}
                    >
                      <Input
                        sx={{
                          width: "100%",
                          height: "100%",
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none", // Remove the border
                            },
                            "&:hover fieldset": {
                              border: "none", // Remove the border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // Remove the border when focused
                            },
                          },
                        }}
                        fullWidth
                        size="small"
                        variant="outlined"
                        disableUnderline
                        placeholder="Enter the instagram username"
                        id="input-with-icon-adornment"
                        startAdornment={
                          <InputAdornment position="start">
                            <Avatar src={IG} sx={{ width: 24, height: 24 }} />
                          </InputAdornment>
                        }
                      />
                    </FormControl> */}
                    <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                      Socials
                    </Typography>
                  </Grid2>
                </Grid2>
              </Grid2>
              <Grid2 container size={{ xs: 12 }}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center",
                      width: "50%",
                    }}
                  >
                    <Checkbox {...label} />
                    <Typography>Brand Socials Mentions Mandatory</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      alignItems: "center",
                      width: "50%",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography sx={{ fontSize: 12 }}>
                      check out the social media handles of the brand:{" "}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                        alignItems: "center",
                      }}
                    >
                      <Avatar src={IG} sx={{ width: 20, height: 20 }} />
                      <Avatar src={YT} sx={{ width: 20, height: 20 }} />
                      <Avatar src={FB} sx={{ width: 20, height: 20 }} />
                    </Box>
                  </Box>
                </Box>
              </Grid2>
              {/* <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Btn>Save</Btn>
              </Box> */}
            </Grid2>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          sx={{
            "& .MuiPaper-root": { boxShadow: 0 },
            "& .MuiAccordion-root": { boxShadow: 0 },
            boxShadow: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "1.3rem" }}>
              3. Campaign Proposal
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container sx={{ rowGap: 5 }}>
              <Grid2 item size={{ xs: 12 }}>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Tabs
                    value={value1}
                    onChange={handleChange1}
                    aria-label="basic tabs example1"
                    sx={{
                      "& .MuiTabs-indicator": {
                        display: "none", // Hide the underline
                      },
                    }}
                  >
                    <CustomTab
                      isActive={value1 === 0}
                      sx={{ borderRadius: 1, marginX: 1 }}
                      label="Paid"
                      {...a11yProps1(0)}
                    />
                    <CustomTab
                      isActive={value1 === 1}
                      sx={{ borderRadius: 1, marginX: 1 }}
                      label="Barter"
                      {...a11yProps1(1)}
                    />
                    <CustomTab
                      isActive={value1 === 2}
                      sx={{ borderRadius: 1, marginX: 1 }}
                      label="Custom"
                      {...a11yProps1(2)}
                    />
                  </Tabs>
                </Box>
                <CustomTabPanel value={value1} index={0}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 4,
                        width: "100%",
                        marginBottom: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ marginBottom: 1 }}>
                          Individual Budget
                        </Typography>
                        <Box sx={{ width: 450 }}>
                          {/* <MinMaxSliderComponent /> */}
                          <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                            Budget
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ marginBottom: 1 }}>
                          Negotiated Price
                        </Typography>
                        <Box sx={{ width: 450 }}>
                          {/* <MinMaxSliderComponent /> */}
                          <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                            Price
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 2,
                      }}
                    >
                      <Typography sx={{ marginBottom: 1 }}>
                        Payment Method by Brand
                      </Typography>
                      <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                        Payment Method
                      </Typography>
                      {/* <TextField
                        sx={{
                          bgcolor: "#dde8f8",
                          borderRadius: 3,
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              border: "none", // Remove the border
                            },
                            "&:hover fieldset": {
                              border: "none", // Remove the border on hover
                            },
                            "&.Mui-focused fieldset": {
                              border: "none", // Remove the border when focused
                            },
                          },
                        }}
                        size="small"
                        placeholder="Before Live"
                        variant="outlined"
                        id="outlined-select-socials"
                        select
                        defaultValue=""
                      >
                        {paymentMethods.map((option) => (
                          <MenuItem
                            key={option.value}
                            value={option.value}
                            sx={{
                              display: "flex",
                              width: "100%",
                              alignItems: "center",
                              gap: 1,
                            }}
                          >
                            <Typography sx={{ width: "100%", fontSize: 15 }}>
                              {option.label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </TextField> */}
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: 2,
                      }}
                    >
                      <Typography sx={{ marginBottom: 1 }}>
                        Payment Method to Influencer
                      </Typography>
                      <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                        Payment Method
                      </Typography>
                    </Box>
                  </Box>
                </CustomTabPanel>
                <CustomTabPanel value={value1} index={1}>
                  <Grid2 container size={{ xs: 12 }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                        width: "100%",
                        marginBottom: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ marginBottom: 1 }}>
                          Product Name
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                          Name
                        </Typography>
                        {/* <TextField
                          sx={{
                            bgcolor: "#dde8f8",
                            borderRadius: 3,
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none", // Remove the border
                              },
                              "&:hover fieldset": {
                                border: "none", // Remove the border on hover
                              },
                              "&.Mui-focused fieldset": {
                                border: "none", // Remove the border when focused
                              },
                            },
                          }}
                          fullWidth
                          size="small"
                          variant="outlined"
                        /> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ marginBottom: 1 }}>
                          Product Value
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                          Value
                        </Typography>
                        {/* <TextField
                          sx={{
                            bgcolor: "#dde8f8",
                            borderRadius: 3,
                            "& .MuiOutlinedInput-root": {
                              "& fieldset": {
                                border: "none", // Remove the border
                              },
                              "&:hover fieldset": {
                                border: "none", // Remove the border on hover
                              },
                              "&.Mui-focused fieldset": {
                                border: "none", // Remove the border when focused
                              },
                            },
                          }}
                          fullWidth
                          size="small"
                          variant="outlined"
                        /> */}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: 2,
                        width: "100%",
                        marginBottom: 2,
                      }}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          marginBottom: 2,
                        }}
                      >
                        <Typography sx={{ marginBottom: 1 }}>
                          Product Link
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                          Link
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography sx={{ marginBottom: 1 }}>
                          Product Description
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                          Description
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        marginTop: 3,
                      }}
                    >
                      <Typography>Picture of the product</Typography>
                      <Box mt={1} mx={"auto"}>
                        <img
                          width={"100%"}
                          height={"200px"}
                          style={{
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                          src={temptDP}
                          alt="product"
                        />
                      </Box>
                    </Box>
                  </Grid2>
                </CustomTabPanel>
                <CustomTabPanel value={value1} index={2}>
                  <Grid2 container size={{ xs: 12 }}>
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ marginBottom: 1 }}>Title</Typography>
                        <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                          Title
                        </Typography>
                        {/* <TextareaAutosize
                          minRows={10}
                          style={{
                            resize: "none",
                            border: "none",
                            backgroundColor: "#dde8f8",
                            borderRadius: 10,
                            width: "100%",
                          }}
                        /> */}
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Typography sx={{ marginBottom: 1 }}>
                          Description
                        </Typography>
                        <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                          Description
                        </Typography>
                        {/* <TextareaAutosize
                          minRows={10}
                          style={{
                            resize: "none",
                            border: "none",
                            backgroundColor: "#dde8f8",
                            borderRadius: 10,
                            width: "100%",
                          }}
                        /> */}
                      </Box>
                    </Box>
                  </Grid2>
                </CustomTabPanel>
              </Grid2>
              {/* <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Btn>Save</Btn>
              </Box> */}
            </Grid2>
          </AccordionDetails>
        </Accordion>
        <Accordion
          defaultExpanded
          sx={{
            "& .MuiPaper-root": { boxShadow: 0 },
            "& .MuiAccordion-root": { boxShadow: 0 },
            boxShadow: 0,
          }}
        >
          <AccordionSummary
            expandIcon={<ArrowDropDownIcon />}
            aria-controls="panel2-content"
            id="panel2-header"
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "1.3rem" }}>
              4. Deliverables
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container sx={{ rowGap: 5 }}>
              <Grid2 item size={{ xs: 12 }}>
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ marginBottom: 1 }}>Title</Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                      Title
                    </Typography>
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ marginBottom: 1 }}>
                      Redirect URL
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                      Redirect URL
                    </Typography>
                  </Box>
                </Box>
              </Grid2>
              <Grid2
                container
                size={{ xs: 12 }}
                sx={{ justifyContent: "space-between" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ marginBottom: 1 }}>Type</Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                      Type
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ marginBottom: 1 }}>Quantity</Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                      Quantity
                    </Typography>
                  </Box>
                </Box>
              </Grid2>
              <Grid2
                container
                size={{ xs: 12 }}
                sx={{ justifyContent: "space-between" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ marginBottom: 1 }}>
                      Approved by
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                      Approved by
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ marginBottom: 1 }}>
                      Upload live dates
                    </Typography>
                    <Typography sx={{ fontWeight: 500, fontSize: 18 }}>
                      Upload live dates
                    </Typography>
                  </Box>
                </Box>
              </Grid2>
              <Grid2
                container
                size={{ xs: 12 }}
                sx={{ justifyContent: "space-between" }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ marginBottom: 1 }}>
                      Upload time
                    </Typography>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                      Upload time
                    </Typography>
                  </Box>
                  <Box sx={{ width: "100%" }}>
                    <Typography sx={{ marginBottom: 1 }}>
                      Description
                    </Typography>
                    <Typography sx={{ fontSize: 18, fontWeight: 500 }}>
                      Description
                    </Typography>
                  </Box>
                </Box>
              </Grid2>
              {/* <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Btn>Save</Btn>
              </Box> */}
            </Grid2>
          </AccordionDetails>
        </Accordion>
      </CustomTabPanel>

      {/* Draft content tab  */}
      <CustomTabPanel value={value} index={1}>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: 2,
            }}
          >
            <Tabs
              value={value2}
              onChange={handleChange2}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  display: "none", // Hide the underline
                },
                display: "flex",
                alignItems: "center",
              }}
            >
              <CustomTab1
                isActive={value2 === 0}
                label="Upload draft"
                {...a11yProps2(0)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value2} index={0}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                px: 2,
                gap: 3,
              }}
            >
              <Box sx={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontSize: 18,
                    fontWeight: 500,
                    width: "100%",
                    marginBottom: 1,
                  }}
                >
                  Upload a draft for review
                </Typography>
                <Typography sx={{ fontSize: 15, width: "100%" }}>
                  You can upload a draft that will be reviewed by the admin.
                </Typography>
              </Box>
              <Box sx={{ width: "100%" }}>
                <FileUploader
                  id={"logo"}
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                  hoverTitle="Drop here"
                  children={customDropZoneContent}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 5,
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 500,
                      width: "100%",
                      marginBottom: 1,
                    }}
                  >
                    Email for notification
                  </Typography>
                  <TextField
                    id="email"
                    sx={{ width: "100%" }}
                    // label="Select"
                    // value={selectedSortOption}
                    // onChange={handleSortChange}
                    size="small"
                    variant="outlined"
                  />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      fontSize: 18,
                      fontWeight: 500,
                      width: "100%",
                      marginBottom: 1,
                    }}
                  >
                    Description
                  </Typography>
                  <TextField
                    id="desc"
                    sx={{ width: "100%" }}
                    // label="Select"
                    // value={selectedSortOption}
                    // onChange={handleSortChange}
                    size="small"
                    variant="outlined"
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 2,
                }}
              >
                <Btn sx={{ width: 150 }}>Submit</Btn>
              </Box>
            </Box>
          </CustomTabPanel>
        </Box>
      </CustomTabPanel>

      {/* my content tab */}
      <CustomTabPanel value={value} index={2}>
        <Box sx={{ width: "100%" }}>
          <Box>
            <Tabs
              value={value3}
              onChange={handleChange3}
              aria-label="basic tabs example"
              sx={{
                "& .MuiTabs-indicator": {
                  display: "none", // Hide the underline
                },
              }}
            >
              <CustomTab1
                isActive={value3 === 0}
                label="Published Post"
                {...a11yProps3(0)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value3} index={0}>
            <CampaignDataTable
              columns={publishedContentColumn}
              onRowClick={handleRowClick}
              rowData={sortedRowData}
              customStyle={customStyle}
            />
          </CustomTabPanel>
        </Box>
      </CustomTabPanel>

      {/* content stats */}
      <CustomTabPanel value={value} index={3}>
        <Box sx={{ width: "100%" }}>
          <CampaignDataTable
            columns={deliverableColumn}
            onRowClick={handleRowClick}
            rowData={sortedRowData}
          />
        </Box>
      </CustomTabPanel>

      {/* chat */}
      <CustomTabPanel value={value} index={4}>
        <Box sx={{ width: "100%" }}>
          <ChatComponent />
        </Box>
      </CustomTabPanel>
    </Box>
  );
}
