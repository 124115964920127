import {
  Box,
  Button,
  Container,
  Grid2,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CreateCampaignTabs from "../subcomponents/campaign/CreateCampaignTabs";
import { AddCircleOutlineRounded, AddOutlined } from "@mui/icons-material";
import { useState } from "react";
import AddPostDialog from "../subcomponents/campaign/AddPostDialog";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
  width: "100%",
}));

const Btn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "6px 15px",
  borderRadius: 3,
  background: "#f7f7f6",
  color: "grey",
  boxShadow: "none",
  "&:hover": {
    // background: "#3DBE72",
    background: "#f7f7f6",
    boxShadow: "none",
  },
}));

const AddButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "6px 15px",
  borderRadius: 3,
  background: "#3DBE72",
  boxShadow: "none",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
}));

const CreateCampaignReport = () => {
  const navigate = useNavigate();

  const [dialogOpen, setDialogOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [file, setFile] = useState(null);

  const fileTypes = ["JPG", "PNG", "GIF"];

  const handleGenerateReport = () => {
    // setTimeout(() => {
    //   navigate("/dashboard/campaign-report/report");
    // }, 1000);
    navigate("/dashboard/campaign-report/report");
  };

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        paddingY: 5,
        paddingBottom: 10,
        paddingLeft: 3,
        paddingRight: 0,
        bgcolor: "#dde7f3",
      }}
    >
      <Grid2 container sx={{ paddingRight: 0, width: "100%" }}>
        <Grid2 item size={{ xs: 12 }} sx={{ textAlign: "center" }}>
          <Title>Enter Campaign Data And Generate Report</Title>
          <Typography sx={{ fontSize: 15, marginTop: 2 }}>
            Analyse realtime report of your past campaigns anytime
          </Typography>
        </Grid2>
      </Grid2>
      <Box
        sx={{
          position: "relative",
          top: "50%",
          width: "fit-content",
          height: "100%",
          px: 3,
          py: 0,
          borderRadius: 3,
          background: "#fff",
          boxShadow: 1,
          marginX: "auto",
          display: "flex",
          alignItems: "center",
          gap: 5,
        }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <AddButton
            onClick={(e) => {
              e.stopPropagation();
              setDialogOpen(true);
            }}
            variant="contained"
            startIcon={<AddCircleOutlineRounded />}
          >
            Add Post
          </AddButton>
          <AddPostDialog
            file={file}
            setFile={setFile}
            fileTypes={fileTypes}
            open={dialogOpen}
            set={setDialogOpen}
            setSubmitted={setSubmitted}
          />

          <Btn disabled={!submitted} onClick={() => handleGenerateReport()}>
            Generate Report
          </Btn>
        </Box>
      </Box>
    </Box>
  );
};

export default CreateCampaignReport;
