const rowData = [
  {
    id: 1,
    brand: "Coca-cola",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Under Review",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      brand: "Instagram",
    },
    invited: "15th Nov 2023",
    date: "15th Nov 2023",
    campaign: "ABC Campaign",
  },
  {
    id: 2,
    brand: "Nike",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      brand: "Instagram",
    },
    invited: "20th Jan 2024",
    date: "20th Jan 2024",
    campaign: "ABC Campaign",
  },

  {
    id: 3,
    brand: "Apple",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      brand: "Twitter",
    },
    invited: "5th Feb 2024",
    date: "5th Feb 2024",
    campaign: "ABC Campaign",
  },
  {
    id: 4,
    brand: "Adidas",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      brand: "Facebook",
    },
    invited: "10th Mar 2024",
    date: "10th Mar 2024",
    campaign: "ABC Campaign",
  },
  {
    id: 5,
    brand: "Samsung",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Under Review",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      brand: "YouTube",
    },
    invited: "18th Apr 2024",
    date: "18th Apr 2024",
    campaign: "ABC Campaign",
  },
  {
    id: 6,
    brand: "Microsoft",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      brand: "Twitter",
    },
    invited: "25th May 2024",
    date: "25th May 2024",
    campaign: "ABC Campaign",
  },
  {
    id: 7,
    brand: "Amazon",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      brand: "Facebook",
    },
    invited: "3rd Jun 2024",
    date: "3rd Jun 2024",
    campaign: "ABC Campaign",
  },
  {
    id: 8,
    brand: "Google",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    platform: {
      logo: "https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png",
      brand: "LinkedIn",
    },
    invited: "12th Jul 2024",
    date: "12th Jul 2024",
    campaign: "ABC Campaign",
  },
  {
    id: 9,
    brand: "Sony",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Under Review",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      brand: "Twitter",
    },
    invited: "21st Aug 2024",
    date: "21st Aug 2024",
    campaign: "ABC Campaign",
  },
  {
    id: 10,
    brand: "Tesla",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      brand: "YouTube",
    },
    invited: "9th Sep 2024",
    date: "9th Sep 2024",
    campaign: "ABC Campaign",
  },
  {
    id: 11,
    brand: "Puma",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Puma_logo.svg/1280px-Puma_logo.svg.png",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      brand: "Instagram",
    },
    invited: "17th Oct 2024",
    date: "17th Oct 2024",
    campaign: "ABC Campaign",
  },
];

const campaignReportData = [
  {
    id: 1,
    brand: "Coca-cola",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Under Review",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      brand: "Instagram",
    },
    invited: "15th Nov 2023",
    date: "15th Nov 2023",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },
  {
    id: 2,
    brand: "Nike",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      brand: "Instagram",
    },
    invited: "20th Jan 2024",
    date: "20th Jan 2024",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },

  {
    id: 3,
    brand: "Apple",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      brand: "Twitter",
    },
    invited: "5th Feb 2024",
    date: "5th Feb 2024",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },
  {
    id: 4,
    brand: "Adidas",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      brand: "Facebook",
    },
    invited: "10th Mar 2024",
    date: "10th Mar 2024",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },
  {
    id: 5,
    brand: "Samsung",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Under Review",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      brand: "YouTube",
    },
    invited: "18th Apr 2024",
    date: "18th Apr 2024",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },
  {
    id: 6,
    brand: "Microsoft",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      brand: "Twitter",
    },
    invited: "25th May 2024",
    date: "25th May 2024",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },
  {
    id: 7,
    brand: "Amazon",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      brand: "Facebook",
    },
    invited: "3rd Jun 2024",
    date: "3rd Jun 2024",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },
  {
    id: 8,
    brand: "Google",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    platform: {
      logo: "https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png",
      brand: "LinkedIn",
    },
    invited: "12th Jul 2024",
    date: "12th Jul 2024",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },
  {
    id: 9,
    brand: "Sony",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Under Review",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      brand: "Twitter",
    },
    invited: "21st Aug 2024",
    date: "21st Aug 2024",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },
  {
    id: 10,
    brand: "Tesla",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      brand: "YouTube",
    },
    invited: "9th Sep 2024",
    date: "9th Sep 2024",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },
  {
    id: 11,
    brand: "Puma",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Puma_logo.svg/1280px-Puma_logo.svg.png",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      brand: "Instagram",
    },
    invited: "17th Oct 2024",
    date: "17th Oct 2024",
    campaign: "ABC Campaign",
    total_budget: 2340085,
  },
];

const campaignInfluencerData = [
  {
    id: 1,
    name: "Coca-cola",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Invited",
    platform: {
      logo: "https://e7.pngegg.com/pngimages/875/247/png-clipart-product-design-number-logo-instalogo-purple-text.png",
      name: "Instagram",
    },
    invited: "15th Nov 2023",
    date: "15th Nov 2023",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 2,
    name: "Nike",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      name: "Instagram",
    },
    invited: "20th Jan 2024",
    date: "20th Jan 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },

  {
    id: 3,
    name: "Apple",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      name: "Twitter",
    },
    invited: "5th Feb 2024",
    date: "5th Feb 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 4,
    name: "Adidas",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Not Invited",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      name: "Facebook",
    },
    invited: "10th Mar 2024",
    date: "10th Mar 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 5,
    name: "Samsung",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      name: "YouTube",
    },
    invited: "18th Apr 2024",
    date: "18th Apr 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 6,
    name: "Microsoft",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      name: "Twitter",
    },
    invited: "25th May 2024",
    date: "25th May 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 7,
    name: "Amazon",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      name: "Facebook",
    },
    invited: "3rd Jun 2024",
    date: "3rd Jun 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 8,
    name: "Google",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Not Invited",
    platform: {
      logo: "https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png",
      name: "LinkedIn",
    },
    invited: "12th Jul 2024",
    date: "12th Jul 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 9,
    name: "Sony",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      name: "Twitter",
    },
    invited: "21st Aug 2024",
    date: "21st Aug 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 10,
    name: "Tesla",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      name: "YouTube",
    },
    invited: "9th Sep 2024",
    date: "9th Sep 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 11,
    name: "Puma",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Puma_logo.svg/1280px-Puma_logo.svg.png",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      name: "Instagram",
    },
    invited: "17th Oct 2024",
    date: "17th Oct 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
];

const draftContentData = [
  {
    id: 1,
    name: "Coca-cola",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    platform: {
      logo: "https://e7.pngegg.com/pngimages/875/247/png-clipart-product-design-number-logo-instalogo-purple-text.png",
      name: "Instagram",
    },
    invited: "15th Nov 2023",
    date: "15th Nov 2023",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 2,
    name: "Nike",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      name: "Instagram",
    },
    invited: "20th Jan 2024",
    date: "20th Jan 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },

  {
    id: 3,
    name: "Apple",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      name: "Twitter",
    },
    invited: "5th Feb 2024",
    date: "5th Feb 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 4,
    name: "Adidas",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      name: "Facebook",
    },
    invited: "10th Mar 2024",
    date: "10th Mar 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 5,
    name: "Samsung",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      name: "YouTube",
    },
    invited: "18th Apr 2024",
    date: "18th Apr 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 6,
    name: "Microsoft",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      name: "Twitter",
    },
    invited: "25th May 2024",
    date: "25th May 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 7,
    name: "Amazon",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      name: "Facebook",
    },
    invited: "3rd Jun 2024",
    date: "3rd Jun 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 8,
    name: "Google",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    platform: {
      logo: "https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png",
      name: "LinkedIn",
    },
    invited: "12th Jul 2024",
    date: "12th Jul 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 9,
    name: "Sony",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      name: "Twitter",
    },
    invited: "21st Aug 2024",
    date: "21st Aug 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 10,
    name: "Tesla",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      name: "YouTube",
    },
    invited: "9th Sep 2024",
    date: "9th Sep 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 11,
    name: "Puma",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Puma_logo.svg/1280px-Puma_logo.svg.png",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      name: "Instagram",
    },
    invited: "17th Oct 2024",
    date: "17th Oct 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
];

const deliverablesData = [
  {
    id: 1,
    name: "Coca-cola",
    handle: "@cola",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 6700,
    likes: 800,
    comments: 789,
    video_views: 3500,
    cpv: "5",
    cpe: "12",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Invited",
    platform: {
      logo: "https://e7.pngegg.com/pngimages/875/247/png-clipart-product-design-number-logo-instalogo-purple-text.png",
      name: "Instagram",
    },
    invited: "15th Nov 2023",
    date: "15th Nov 2023",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 2,
    name: "Nike",
    handle: "@Nike",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 21000,
    likes: 78,
    comments: 23,
    video_views: 5000,
    cpv: "5",
    cpe: "12",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      name: "Instagram",
    },
    invited: "20th Jan 2024",
    date: "20th Jan 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },

  {
    id: 3,
    name: "Apple",
    handle: "@Apple",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 2500,
    likes: 2781,
    comments: 421,
    video_views: 35000,
    cpv: "5",
    cpe: "12",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      name: "Twitter",
    },
    invited: "5th Feb 2024",
    date: "5th Feb 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 4,
    name: "Adidas",
    handle: "@Adidas",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 34000,
    likes: 2728,
    comments: 420,
    video_views: 15000,
    cpv: "5",
    cpe: "12",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Not Invited",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      name: "Facebook",
    },
    invited: "10th Mar 2024",
    date: "10th Mar 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 5,
    name: "Samsung",
    handle: "@Samsung",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 50000,
    likes: 2178,
    comments: 142,
    video_views: 30000,
    cpv: "5",
    cpe: "12",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      name: "YouTube",
    },
    invited: "18th Apr 2024",
    date: "18th Apr 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 6,
    name: "Microsoft",
    handle: "@Microsoft",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 10000,
    likes: 1278,
    comments: 42,
    video_views: 12000,
    cpv: "5",
    cpe: "12",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      name: "Twitter",
    },
    invited: "25th May 2024",
    date: "25th May 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 7,
    name: "Amazon",
    handle: "@Amazon",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 120000,
    likes: 278,
    comments: 4200,
    video_views: 10000,
    cpv: "5",
    cpe: "12",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      name: "Facebook",
    },
    invited: "3rd Jun 2024",
    date: "3rd Jun 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 8,
    name: "Google",
    handle: "@Google",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 25000,
    likes: 2780,
    comments: 4200,
    video_views: 24000,
    cpv: "5",
    cpe: "12",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Not Invited",
    platform: {
      logo: "https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png",
      name: "LinkedIn",
    },
    invited: "12th Jul 2024",
    date: "12th Jul 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 9,
    name: "Sony",
    handle: "@Sony",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 20500,
    likes: 2780,
    comments: 4200,
    video_views: 35600,
    cpv: "5",
    cpe: "12",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      name: "Twitter",
    },
    invited: "21st Aug 2024",
    date: "21st Aug 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 10,
    name: "Tesla",
    handle: "@Tesla",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 21000,
    likes: 2780,
    comments: 4200,
    video_views: 13500,
    cpv: "5",
    cpe: "12",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      name: "YouTube",
    },
    invited: "9th Sep 2024",
    date: "9th Sep 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
  {
    id: 11,
    name: "Puma",
    handle: "@Puma",
    deliverables: "8 of 8",
    engagement_rate: 2456,
    total_cost: 20000,
    likes: 2780,
    comments: 4200,
    video_views: 25300,
    cpv: "5",
    cpe: "12",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Puma_logo.svg/1280px-Puma_logo.svg.png",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      name: "Instagram",
    },
    invited: "17th Oct 2024",
    date: "17th Oct 2024",
    draft_media: [
      {
        url: "https://images.pexels.com/photos/1955134/pexels-photo-1955134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      },
    ],
  },
];

export {
  rowData,
  campaignInfluencerData,
  draftContentData,
  deliverablesData,
  campaignReportData,
};
