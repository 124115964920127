import { CloseOutlined, SendOutlined } from "@mui/icons-material";
import { Avatar, Box, Button, TextField, Typography } from "@mui/material";

const temptDP =
  "https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg";

const ChatComponent = () => {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        pr: 3,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          borderBottom: 1,
          pb: 2,
          px: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box
          sx={{ width: "100%", display: "flex", alignItems: "center", gap: 2 }}
        >
          <Avatar sx={{ width: 30, height: 30 }} src={temptDP} />
          <Typography sx={{ width: "100%", fontWeight: 500, fontSize: 18 }}>
            Influencer Name
          </Typography>
        </Box>
        {/* <CloseOutlined /> */}
      </Box>
      <Box
        sx={{ width: "100%", height: "50vh", backgroundColor: "#f9f5ff" }}
      ></Box>
      <Box
        sx={{
          marginTop: "auto",
          width: "100%",
          display: "flex",
          alignItems: "center",
          borderTop: 1,
          py: 2,
        }}
      >
        <TextField
          placeholder="Enter a message"
          variant="outlined"
          size="small"
          sx={{ width: "100%" }}
        />
        <Button endIcon={<SendOutlined />}>Send</Button>
      </Box>
    </Box>
  );
};

export default ChatComponent;
