import {
  Box,
  styled,
  TextField,
  IconButton,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  Avatar,
  Dialog,
  Tooltip,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
  Autocomplete,
  CircularProgress,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DataTable from "react-data-table-component";
// import IconButton from '@mui/material/IconButton';
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import {
  AddCircleOutlineRounded,
  GridViewRounded,
  ViewListRounded,
} from "@mui/icons-material";
// import { rowData } from './campaigndata'
import { campaign } from "../subcomponents/campaign/campaignCardData.js";
import DialogBox from "../subcomponents/campaign/DialogBox.jsx";
import CampaignCard from "../subcomponents/campaign/CampaignCard.jsx";
// import Table from '@mui/material/Table';

import SBicon from "../../images/sb.png";
import LISTicon from "../../images/lst.png";
import { useEffect, useState } from "react";
import { campaignReportData, rowData } from "./campaigndata.js";
import { height, width } from "@mui/system";
import CreateCampaignDialog from "./CreateCampaignDialog.jsx";
import { getAllBrands } from "../../../service/brands/getAllBrands.js";
import { getAllLocations } from "../../../service/influencers/getAllLocations.js";
import { createCampaign } from "../../../service/campaign api/createCampaign.js";
import CollectionTable from "../collection/CollectionTable.jsx";
import { getAllFilterCollecetion } from "../../../service/collections api/getAllCollection.js";
import ShareRoundedIcon from "@mui/icons-material/ShareRounded";
import FileDownloadRoundedIcon from "@mui/icons-material/FileDownloadRounded";
import CreateCampaignReportDialog from "./CreateCampaignReportDialog.jsx";

const findBackgroundColor = (status) => {
  switch (status) {
    case "Rejected":
      return "red";
    case "Under Review":
      return "#FFA84C";
    case "Approved":
      return "green";
    case "Inactive":
      return "#FF2323";
    case "Pending":
      return "#cddc39";
    default:
      return "#FFF";
  }
};

const customStyles = {
  head: {
    style: {
      fontSize: "18px", // Set the desired font size for the header
    },
  },
  rows: {
    style: {
      minHeight: "80px",
    },
  },
};
const CustomCampaign = ({ row }) => (
  <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
    {row.campaign}
  </Typography>
);

const CustomBrand = ({ row }) => (
  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
    <Avatar
      alt={row.brand}
      src={row.logo}
      sx={{ width: 40, height: 40, objectFit: "cover" }}
    />
    <Typography sx={{ fontWeight: "500", fontSize: "1rem" }}>
      {row.brand}
    </Typography>
  </Box>
);

const CustomPlatform = ({ row }) => (
  <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
    <Avatar
      sx={{ width: 30, height: 30 }}
      alt={row.platform.brand}
      src={row.platform.logo}
    />
    <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
      {row.platform.brand}
    </Typography>
  </Box>
);

const CustomStatus = ({ row }) => (
  <Box
    sx={{
      background: findBackgroundColor(row.status),
      padding: 1,
      width: "100%",
      textAlign: "center",
      color: "#FFFFFF",
      borderRadius: 30,
      marginX: "auto",
    }}
  >
    {row.status}
  </Box>
);

const CustomActions = ({ row }) => (
  <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
    <ShareRoundedIcon sx={{ "&:hover": { cursor: "pointer" } }} />
    <FileDownloadRoundedIcon sx={{ "&:hover": { cursor: "pointer" } }} />
  </Box>
);

function formatNumber(num) {
  try {
    // Convert the input to a number in case it's a string
    const number = Number(num);

    // Check if the conversion was successful
    if (isNaN(number)) {
      throw new Error("Invalid number");
    }

    // Format the number based on its value
    if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + "M"; // Convert to millions
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + "K"; // Convert to thousands
    } else {
      return number.toString(); // Return the number as is
    }
  } catch (error) {
    return "error";
  }
}

const columns = [
  {
    name: "Sr.No.",
    selector: (row) => row.id,
    sortable: true,
    center: true,
    width: "110px",
  },
  {
    name: "Brand Name",
    selector: (row) => row.brand,
    sortable: true,
    // width: "180px",
    cell: (row) => <CustomBrand row={row} />,
  },
  {
    name: "Campaign Name",
    selector: (row) => row.campaign,
    sortable: true,
    reorder: true,
    // width: "150px",
    cell: (row) => <CustomCampaign row={row} />,
  },
  {
    name: "Total Budget",
    selector: (row) => row.total_budget,
    sortable: true,
    reorder: true,
    width: "180px",
    cell: (row) => (
      <Typography sx={{ fontWeight: "bold", fontSize: 16 }}>
        {formatNumber(row.total_budget)}
      </Typography>
    ),
  },
  {
    name: "Created on",
    selector: (row) => row.date,
    sortable: true,
    reorder: true,

    // cell: row => <CustomAverage row={row} />
  },
  {
    name: "Actions",
    selector: (row) => row,
    sortable: false,
    reorder: false,
    cell: (row) => <CustomActions row={row} />,
  },
].map((column) => ({
  ...column,
  style: {
    fontSize: "13px",
  },
}));

const Container = styled(Box)(({ theme }) => ({
  margin: "2rem 1rem",
  // background : 'blue',
  // width: '100%',
  // height: '100%'
}));

const SearchBar = styled(TextField)(({ theme }) => ({
  //    border : 'none'
  width: "100%",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none", // Remove the outline
  },
  border: "1px solid black",
  borderRadius: 20,
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const AddButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "5px 15px",
  borderRadius: 20,
  background: "#3DBE72",
  boxShadow: "none",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  color: "black",
  textTransform: "none",
  fontWeight: "bold",
}));

const UnderReviewBtn = styled(Button)(({ theme }) => ({
  background: "#FFA84C",
  borderRadius: 20,
  textTransform: "none",
  boxShadow: "none",
  "&:hover": {
    background: "#FFA84C",
    boxShadow: "none",
  },
}));

// ***************
// Dialog custom styles

const CampaignReport = () => {
  const [variants, setVariants] = useState("text");
  const [createCampaignReportDialog, setCreateCampaignReportDialog] =
    useState(false);
  const [tableData, setTableData] = useState(campaignReportData);
  const [cardData, setCardData] = useState(campaign);
  const [view, setViews] = useState("true");
  const [brandName, setBrandName] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [locations, setLocations] = useState();

  // const [createCampaignData, setCreateCampaignData] = useState({
  //   name: name,
  //   brand_name: brand_name,
  //   brand_id: brand_id,
  //   description: description,
  //   status: status,
  // });

  // const handleCreateCampaign = async () => {
  //   const res = await createCampaign(createCampaignData);
  // console.log("create campaign response", res);
  // };

  const [view1, setView1] = useState("list");
  const [filterBtn, setFilterBtn] = useState("all");

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [newPageAdded, setNewPageAdded] = useState(1);
  const [customHide, setCustomHide] = useState(false);
  const [customColumn, setCustomColumn] = useState([]);
  const [newCollection, setNewCollection] = useState({});
  const [allBrands, setAllBrands] = useState([]);
  const [displayCollection, setDisplayCollection] = useState([]);
  const [allCollection, setAllCollection] = useState([]);
  const [loading, setLoading] = useState(false);
  const [startdate, setStartDate] = useState("");
  const [enddate, setEndDate] = useState("");

  const [brand, setBrand] = useState(null);
  const [min_influencer, setMin_influencer] = useState(null);
  const [max_influencer, setMax_influencer] = useState(null);
  const [showFilterOptions, setShowFilterOptions] = useState(false);

  const handleFilter = async () => {
    let date = null;

    // Check if startdate is a valid date, then add it to the date array
    if (!isNaN(new Date(startdate).getTime())) {
      const dateObj = new Date(startdate);
      const day = String(dateObj.getDate()).padStart(2, "0");
      const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Months are zero-based
      const year = dateObj.getFullYear();
      date = `${day}-${month}-${year}`;
    }
    // Construct the payload
    const payload = {
      date,
      brand,
      min_influencer,
      max_influencer,
      limit: rowsPerPage,
    };

    const filteredPayload = Object.fromEntries(
      Object.entries(payload).filter(([key, value]) => value != null)
    );

    console.log(filteredPayload);

    // Here you would use the payload to make your API call or whatever next steps are required
    try {
      const response = await getAllFilterCollecetion(filteredPayload);

      console.log("filterresponse", response.collection);
      setDisplayCollection(response.collection);
      // console.log("filter response", response);
      setShowFilterOptions(false);
      setLoading(false); // Replace filterCollectionApi with your actual API call function
      // Handle the response
    } catch (error) {
      console.error("Error filtering collections:", error);
      setDisplayCollection(allCollection);
      alert("No data found");
      setLoading(false);
      // Handle the error
    }
  };

  const handleBrandNameSearch = (value) => {
    setBrandName(value);
    console.log("searched brand: ", value);
    let filteredData = tableData.filter((data) => data.brand === brandName);
    if (value !== "" || value !== null) setTableData(filteredData);
    else setTableData(rowData);
  };

  useEffect(() => {}, [tableData, brandName]);

  const handleClick = () => {
    setVariants();
  };

  const handleToggleButtonGroup = (event, newView) => {
    setView1(newView);
  };

  const handleFilterBtnChange = (event, newFilter) => {
    console.log(newFilter);
    setFilterBtn(newFilter);
  };

  const getBrands = async () => {
    const response = await getAllBrands();
    setBrandList(response);
  };

  const fetchLocations = async () => {
    try {
      const response = await getAllLocations();
      setLocations(response.locations);

      console.log("locations list: ", response);
    } catch (error) {
      console.error("Error fetching locations: ", error);
    }
  };

  const handleOpenDialog = () => {
    setCreateCampaignReportDialog(true);
    fetchLocations();
  };

  useEffect(() => {
    getBrands();
  }, []);

  return (
    <Container>
      {/* <DialogBox/> */}
      <CreateCampaignReportDialog
        open={createCampaignReportDialog}
        set={setCreateCampaignReportDialog}
      />

      <Grid container spacing={1} sx={{ marginBottom: 2 }}>
        <Grid item size={{ sm: 12, lg: 8 }}>
          <Title>Campaign Report</Title>
        </Grid>
        <Grid item size={{ sm: 12, lg: 4 }} align="right">
          <AddButton
            onClick={() => setCreateCampaignReportDialog(true)}
            variant="contained"
            startIcon={<AddCircleOutlineRounded />}
          >
            Create Report
          </AddButton>
        </Grid>
      </Grid>

      <Box
        py={3}
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Grid item size={{ md: 12, xs: 12 }} px={3}>
          <ToggleButtonGroup
            value={view1}
            exclusive
            onChange={handleToggleButtonGroup}
            aria-label="text alignment"
            size="small"
          >
            <ToggleButton
              value="list"
              style={{
                color: view1 === "list" ? "#3dbf73ff" : "inherit", // Set the color to red when the button is active
                border: view1 === "list" ? "2px solid #3dbf73ff" : "inherit",
              }}
            >
              <ViewListRounded />
            </ToggleButton>
            <ToggleButton
              value="card"
              style={{
                color: view1 === "card" ? "#3dbf73ff" : "inherit", // Set the color to red when the button is active
                border: view1 === "card" ? "2px solid #3dbf73ff" : "inherit",
              }}
            >
              <GridViewRounded />
            </ToggleButton>
          </ToggleButtonGroup>

          {/* <Tooltip title="Cards view" arrow placement='top'>
            <IconButton size='small'  >
              <img src={SBicon} alt="" width={30} onClick={() => setViews(true)} />
            
            </IconButton>
          </Tooltip>

          <Tooltip title="List view" arrow placement='top' onClick={() => setViews(false)}>
            <IconButton size='small' >
              <img src={LISTicon} alt="" width={30} />
            </IconButton>
          </Tooltip> */}
        </Grid>

        <Grid item size={{ lg: 12 }} style={{ marginBottom: "2rem" }}>
          <Tabs
            value={filterBtn}
            exclusive
            onChange={handleFilterBtnChange}
            aria-label="icon label tabs example"
            sx={{
              textTransform: "none",
              padding: 0,
              margin: 0,
            }}
          >
            <Tab
              label="All"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
              }}
              value={"all"}
            />
            <Tab
              value={"active"}
              label="Active"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
              }}
            />

            <Tab
              value={"ended"}
              label="Ended"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
              }}
            />
            <Tab
              value={"archieved"}
              label="Archieved"
              sx={{
                textTransform: "none",
                padding: 0,
                margin: 0,
              }}
            />
          </Tabs>
        </Grid>
      </Box>

      {/* ListViews  */}
      <Grid item size={{ md: 12, xs: 12 }}>
        <Grid container>
          {view1 === "card" ? (
            <Grid item size={{ md: 12, xs: 12 }} mt={2}>
              <Grid container p={2} spacing={{ md: 5, xs: 5 }}>
                {cardData?.map((item) => (
                  <Grid item size={{ md: 3, xs: 11 }} key={item.id}>
                    <CampaignCard data={item} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          ) : (
            <Grid item size={{ md: 12, xs: 12 }}>
              <DataTable
                columns={columns}
                data={tableData}
                pagination
                fixedHeader
                fixedHeaderScrollHeight="100%"
                customStyles={customStyles}
                // onRowClicked={handleRowClick}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};

export default CampaignReport;
