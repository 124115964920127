import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Icon,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import IG from "../../../images/insta-sm.png";
import youtubeIcon from "../../../images/youtube-sm.png";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import GroupAddIcon from "@mui/icons-material/GroupAdd";

const TemptDP =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTy_32Pr0SWJNqgaFj2ILPdMaE_j2DBZcl4ow&usqp=CAU";

const ProfileCard = ({
  data,
  selectedSocial,
  handleAddToCollectionDialogOpen,
  handleAddToCampaignDialogOpen,
}) => {
  let category = data?.influencer_category?.replace(/'/g, '"').split(",")[0];

  // Custom UI Components
  const Btn = styled(Button)(({ theme }) => ({
    borderColor: "#3DBE72",
    textTransform: "none",
    fontWeight: "bold",
    width: "100%",
    borderRadius: 30,
    "&:hover": { borderColor: "#3DBE72" },
    boxShadow: "none",
    paddingLeft: 5,
    paddingRight: 5,
  }));

  function formatNumber(num) {
    try {
      // Convert the input to a number in case it's a string
      const number = Number(num);

      // Check if the conversion was successful
      if (isNaN(number)) {
        throw new Error("Invalid number");
      }

      // Format the number based on its value
      if (number >= 1e6) {
        return (number / 1e6).toFixed(1) + "M"; // Convert to millions
      } else if (number >= 1e3) {
        return (number / 1e3).toFixed(1) + "K"; // Convert to thousands
      } else {
        return number.toString(); // Return the number as is
      }
    } catch (error) {
      return "error";
    }
  }

  return (
    <>
      <Card
        sx={{
          minWidth: 150,
          width: "100%",
          height: "100%",
          position: "relative",
          paddingBottom: "10px",
        }}
      >
        <Box
          sx={{
            padding: "25px 20px 10px",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            gap: 3,
          }}
        >
          <CardMedia
            sx={{ width: 80, height: 80, borderRadius: 50 }}
            image={data.photo_link || TemptDP}
            title={data.name}
            onError={(e) => {
              e.target.onrrror = null;
              e.target.src = TemptDP;
            }}
          />
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography gutterBottom variant="h6" component="div">
              {data?.name && data?.name.length >= 17
                ? data.name.substring(0, 17) + "..."
                : data.name}
            </Typography>
            <Button
              size="small"
              sx={{
                borderRadius: 30,
                width: "fit-content",
                px: 2,
                fontWeight: "normal",
                backgroundColor: "#3DBE72",
                color: "white",
                "&:hover": {
                  color: "white",
                  backgroundColor: "#3DBE72",
                },
              }}
            >
              {data?.influencer_category
                ? category
                : JSON.parse(data?.topics?.replace(/'/g, '"'))[0]}
            </Button>
          </Box>
        </Box>
        <CardContent
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "repeat(auto-fit,minmax(1fr,1fr))",
              sm: "repeat(auto-fit,minmax(25%,1fr))",
            },
            placeItems: "center center",
          }}
        >
          <Typography width={"100%"} paddingLeft={4}>
            {selectedSocial === "youtube" ? (
              <img
                src={youtubeIcon}
                alt={"youtube"}
                style={{ width: "24px", marginRight: "8px" }}
              />
            ) : (
              <img
                src={IG}
                alt={"instagram"}
                style={{ width: "24px", marginRight: "8px" }}
              />
            )}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{ fontWeight: 500 }}
              variant="body1"
              color="text.secondary"
            >
              {formatNumber(data?.subscriber)}
            </Typography>
            <Typography
              sx={{ fontWeight: 500 }}
              variant="body2"
              color="text.secondary"
              fontSize={12}
            >
              Subscribers
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontWeight: 500 }}
              variant="body1"
              color="text.secondary"
            >
              {data?.engagement_rate.toFixed(2)}
            </Typography>
            <Typography
              sx={{ fontWeight: 500 }}
              variant="body2"
              color="text.secondary"
              fontSize={12}
            >
              Engag. Rate
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography
              sx={{ fontWeight: 500 }}
              variant="body1"
              color="text.secondary"
            >
              {data?.avg_likes.toFixed(2)}
            </Typography>
            <Typography
              sx={{ fontWeight: 500 }}
              variant="body2"
              color="text.secondary"
              fontSize={12}
            >
              Avg. Likes
            </Typography>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            gap: 1,
            px: 3,
            width: "100%",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Btn
              size="small"
              variant="contained"
              startIcon={<BookmarkBorderIcon />}
              onClick={handleAddToCollectionDialogOpen}
            >
              Add to Collection
            </Btn>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Btn
              size="small"
              variant="outlined"
              startIcon={<GroupAddIcon />}
              onClick={handleAddToCampaignDialogOpen}
            >
              Add to Campaign
            </Btn>
          </Box>
        </CardActions>
      </Card>
    </>
  );
};

export default ProfileCard;
