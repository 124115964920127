import {
  Dialog,
  Box,
  Typography,
  IconButton,
  Tooltip,
  styled,
  Chip,
  Button,
  Checkbox,
  TextField,
  Autocomplete,
  TextareaAutosize,
  Switch,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Preview from "../../../images/invitation.svg";
import {
  CancelOutlined,
  DeleteOutlineOutlined,
  Add,
} from "@mui/icons-material";
import { borderRadius } from "@mui/system";

const label = { inputProps: { "aria-label": "Switch demo" } };

const fileTypes = ["JPG", "PNG", "GIF"];
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.1rem",
}));

const Btn = styled(Button)(({ theme }) => ({
  color: "#FFF",
  textTransform: "none",
  padding: "5px 15px",
  // marginTop: "1rem",
  borderRadius: 20,
  background: "#3DBE72",
  boxShadow: "none",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
}));

const HtmlLabel = styled("label")(`
      color : #828282ff;
      font-size : '1.1rem';
  
  `);

const HtmlInput = styled("input")(`
      background-color : #dce7f7ff;
      border-radius : 8px;
      width : 100%;
      height : 40px;
      padding : 0px 20px;
      outlied : none;
      border : none;
  `);

const HtmlTextArea = styled("textarea")(`
  width : 100%;
  padding : 10px 20px;
  border-radius : 8px;
  height : 120px;
  background-color : #dce7f7ff;
  border : none;
  `);

const HtmlInputNum = styled("input")(`
      width : 80px;
      text-align : center;
     border : none;
      border-radius : 10px;
      margin-right : 8px;
      background-color : #dce7f7ff;
  `);

const CustomizeListDialog = ({ open, set, selectedRow }) => {
  console.log("Selected Row: ", selectedRow);
  return (
    <Dialog
      open={open}
      onClose={() => set(false)}
      PaperProps={{
        sx: {
          borderRadius: 3,
          border: "2px solid black",
          padding: 4,
          width: 600,
        },
      }}
    >
      <Box>
        <Grid container>
          <Grid item size={{ md: 11, xs: 11 }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: "bold",
                fontSize: "1.35rem",
              }}
            >
              Customize List
            </Typography>
          </Grid>
          <Grid item size={{ md: 1, xs: 1 }} align="end">
            <Tooltip title={"Close"} arrow placement="right">
              <IconButton size={"small"} onClick={() => set(false)}>
                <CancelOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <Box sx={{ display: "flex", flexDirection: "column", py: 3 }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: "bold",
                fontSize: "1.25rem",
                width: "100%",
                py: 1,
              }}
            >
              Influencer
            </Typography>
            <Grid container size={{ xs: 12 }}>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              py: 3,
              borderBottom: 1,
            }}
          >
            <Typography
              sx={{
                color: "#000",
                fontWeight: "bold",
                fontSize: "1.25rem",
                width: "100%",
                py: 1,
              }}
            >
              Audience
            </Typography>
            <Grid container size={{ xs: 12 }}>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
              <Grid item size={{ xs: 3.5 }} sx={{ marginX: 1 }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Switch {...label} />
                  <Typography>Platform</Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ width: "100%", textAlign: "center", pt: 4 }}>
            <Btn sx={{ px: 4 }}>Save</Btn>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CustomizeListDialog;
