import {
  Dialog,
  Box,
  Typography,
  IconButton,
  Tooltip,
  styled,
  Chip,
  Button,
  Checkbox,
  TextField,
  Autocomplete,
  TextareaAutosize,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  MenuItem,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Preview from "../../../images/invitation.svg";
import {
  CancelOutlined,
  DeleteOutlineOutlined,
  Add,
  CheckRounded,
  CheckCircleRounded,
  Download,
} from "@mui/icons-material";
import { borderRadius } from "@mui/system";
import { GridExpandMoreIcon } from "@mui/x-data-grid";

const label = { inputProps: { "aria-label": "Switch demo" } };

const fileTypes = ["JPG", "PNG", "GIF"];
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.1rem",
}));

const socials = [
  {
    value: "Instagram",
    label: "Instagram",
    contentType: [
      { value: "Reels", label: "Reels" },
      { value: "Posts", label: "Posts" },
    ],
  },
  {
    value: "Youtube",
    label: "Youtube",
    contentType: [
      { value: "Shorts", label: "Shorts" },
      { value: "Video", label: "Video" },
    ],
  },
  {
    value: "Twitter",
    label: "Twitter",
    contentType: [
      { value: "Posts", label: "Posts" },
      { value: "Video", label: "Video" },
    ],
  },
  {
    value: "Facebook",
    label: "Facebook",
    contentType: [
      { value: "Posts", label: "Posts" },
      { value: "Video", label: "Video" },
    ],
  },
];

const HtmlLabel = styled("label")(`
        color : #828282ff;
        font-size : '1.1rem';
    
    `);

const HtmlInput = styled("input")(`
        background-color : #dce7f7ff;
        border-radius : 8px;
        width : 100%;
        height : 40px;
        padding : 0px 20px;
        outlied : none;
        border : none;
    `);

const HtmlTextArea = styled("textarea")(`
    width : 100%;
    padding : 10px 20px;
    border-radius : 8px;
    height : 120px;
    background-color : #dce7f7ff;
    border : none;
    `);

const HtmlInputNum = styled("input")(`
        width : 80px;
        text-align : center;
       border : none;
        border-radius : 10px;
        margin-right : 8px;
        background-color : #dce7f7ff;
    `);

const AddPostDialog = ({
  open,
  set,
  file,
  setFile,
  fileTypes,
  setSubmitted,
}) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedSocial, setSelectedSocial] = useState(null);
  const [addPostCount, setAddPostCount] = useState(1);

  const [value, setValue] = useState(0);

  // const [file, setFile] = useState(null);

  // const fileTypes = ["JPG", "PNG", "GIF"];

  // file change handler
  const handleChangeFile = (file) => {
    setFile(file);
    console.log(file);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const customDropZoneContent = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "2px dashed #abababff",
        height: "120px",
        borderRadius: 2,
        cursor: "pointer",
      }}
    >
      {file ? (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography>{file.name}</Typography>

          {/* <Tooltip title="Remove" size={'small'} arrow placement='right'>
                <IconButton size='small' onClick={() => setFile(null)}>
                    <DeleteOutlineOutlined/>
                </IconButton>
                </Tooltip> */}
        </Box>
      ) : (
        <p>
          {" "}
          Click to{" "}
          <span style={{ color: "#3dbf73ff", padding: "0px 5px" }}>
            {" "}
            Browse{" "}
          </span>{" "}
          File or Drag & Drop to upload
        </p>
      )}
    </Box>
  );

  const [openManual, setOpenManual] = useState(false);
  const [openUploadCSV, setOpenUploadCSV] = useState(false);

  const handleOpenDialogCSV = () => {
    setSelectedOption(1);
    setOpenUploadCSV(true);
  };

  const handleOpenDialogManual = () => {
    setSelectedOption(2);
    setOpenManual(true);
  };

  const handleCloseManual = () => {
    setOpenManual(false);
  };

  const handleCloseCSV = () => {
    setOpenUploadCSV(false);
  };

  return (
    <Dialog
      open={open}
      onClose={() => set(false)}
      PaperProps={{
        sx: {
          borderRadius: 3,
          border: "2px solid black",
          padding: 4,
          minWidth: 300,
          width: 650,
          maxWidth: 800,
        },
      }}
    >
      <Box>
        <Grid container>
          <Grid item size={{ md: 11, xs: 11 }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: "bold",
                fontSize: "1.35rem",
              }}
            >
              Choose an option to add post
            </Typography>
          </Grid>
          <Grid item size={{ md: 1, xs: 1 }} align="end">
            <Tooltip title={"Close"} arrow placement="right">
              <IconButton size={"small"} onClick={() => set(false)}>
                <CancelOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            pt: 3,
            gap: 2,
          }}
        >
          <Button
            sx={{
              p: 2,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              bgcolor: "#f2f7f8",
              justifyContent: "flex-start",
              textAlign: "start",
            }}
            onClick={handleOpenDialogCSV}
          >
            {selectedOption === 1 && (
              <CheckCircleRounded
                sx={{
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  bgcolor: "#",
                }}
              />
            )}
            <Typography sx={{ width: "100%", fontSize: 15 }}>
              Upload CSV File
            </Typography>
            <Typography sx={{ width: "100%", fontSize: 12, color: "grey" }}>
              Easy & fastest way to add data
            </Typography>
          </Button>
          <Button
            sx={{
              p: 2,
              position: "relative",
              display: "flex",
              flexDirection: "column",
              bgcolor: "#f2f7f8",
              justifyContent: "flex-start",
              textAlign: "start",
            }}
            onClick={handleOpenDialogManual}
          >
            {selectedOption === 2 && (
              <CheckCircleRounded
                sx={{
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  bgcolor: "#",
                }}
              />
            )}

            <Typography sx={{ width: "100%", fontSize: 15 }}>
              Add Manually
            </Typography>
            <Typography sx={{ width: "100%", fontSize: 12, color: "grey" }}>
              Add all campaign data manually
            </Typography>
          </Button>
        </Box>
      </Box>

      {/* add post manually dialog  */}
      <Dialog
        open={openManual}
        onClose={handleCloseManual}
        PaperProps={{
          sx: {
            borderRadius: 3,
            border: "2px solid black",
            padding: 4,
            minWidth: 300,
            width: 650,
            maxWidth: 800,
          },
        }}
      >
        <Box>
          <Grid container sx={{ borderBottom: 1, pb: 2 }}>
            <Grid item size={{ md: 11, xs: 11 }}>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1.35rem",
                }}
              >
                Add post
              </Typography>
            </Grid>
            <Grid item size={{ md: 1, xs: 1 }} align="end">
              <Tooltip title={"Close"} arrow placement="right">
                <IconButton size={"small"} onClick={handleCloseManual}>
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Box sx={{ py: 2 }}>
            <Accordion
              defaultExpanded
              sx={{
                boxShadow: "none", // Remove box shadow from the Accordion itself
                // "& .MuiAccordionSummary-root": {
                //   boxShadow: "none", // Remove box shadow from the AccordionSummary
                // },
                // "& .MuiPaper-root": {
                //   boxShadow: "none", // Remove box shadow from the Paper component
                // },
              }}
            >
              <AccordionSummary
                expandIcon={<GridExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                Post 1
              </AccordionSummary>
              <AccordionDetails
                sx={{ display: "flex", flexDirection: "column", gap: 3, pb: 0 }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                  >
                    <Autocomplete
                      disablePortal
                      options={socials}
                      sx={{ width: 220 }}
                      onChange={(event, newValue) => {
                        setSelectedSocial(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "& .MuiInputBase-root": {
                              paddingY: "1px !important", // Remove padding from the InputBase
                            },
                            "& .MuiOutlinedInput-root": {
                              paddingY: "1px !important", // Remove padding from the outlined input
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                    <Autocomplete
                      disablePortal
                      options={selectedSocial ? selectedSocial.contentType : []}
                      sx={{
                        width: 220,
                      }}
                      renderInput={(params) => (
                        <TextField
                          sx={{
                            "& .MuiInputBase-root": {
                              paddingY: "1px !important", // Remove padding from the InputBase
                            },
                            "& .MuiOutlinedInput-root": {
                              paddingY: "1px !important", // Remove padding from the outlined input
                            },
                          }}
                          {...params}
                        />
                      )}
                    />
                  </Box>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      gap: 3,
                    }}
                  >
                    <TextField
                      variant="outlined"
                      type="number"
                      sx={{
                        borderRadius: 2,
                        bgcolor: "#f0f0f0",
                        "& .MuiInputBase-input": {
                          border: "none",
                          outline: "none",
                          px: 2,
                          py: 1,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      placeholder="10000"
                    />
                    <TextField
                      variant="outlined"
                      sx={{
                        borderRadius: 2,
                        bgcolor: "#f0f0f0",
                        "& .MuiInputBase-input": {
                          border: "none",
                          outline: "none",
                          px: 2,
                          py: 1,
                        },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": {
                            border: "none",
                          },
                        },
                      }}
                      placeholder="Creator post link"
                    />
                  </Box>
                </Box>
                <Button
                  sx={{ marginLeft: "auto", p: 1, color: "#000", border: 1 }}
                  onClick={() => setAddPostCount((prev) => prev + 1)}
                >
                  Add More Post
                </Button>
                <Box
                  sx={{
                    width: "100%",
                    borderTop: 1,
                    pt: 2,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    gap: 2,
                  }}
                >
                  <Button
                    sx={{
                      bgcolor: "#f1f1f1",
                      color: "grey",
                      fontSize: 12,
                      px: 4,
                    }}
                    onClick={handleCloseManual}
                  >
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      bgcolor: "#000",
                      color: "white",
                      fontSize: 12,
                      px: 4,
                    }}
                    onClick={() => {
                      setSubmitted(true);
                      handleCloseManual();
                    }}
                  >
                    Submit
                  </Button>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
      </Dialog>

      {/* add post by uploading CSV dialog */}
      <Dialog
        open={openUploadCSV}
        onClose={handleCloseCSV}
        PaperProps={{
          sx: {
            borderRadius: 3,
            border: "2px solid black",
            padding: 4,
            minWidth: 300,
            width: 650,
            maxWidth: 800,
          },
        }}
      >
        <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
          <Grid container sx={{ borderBottom: 1, pb: 2 }}>
            <Grid item size={{ md: 11, xs: 11 }}>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "bold",
                  fontSize: "1.35rem",
                }}
              >
                Upload CSV File
              </Typography>
            </Grid>
            <Grid item size={{ md: 1, xs: 1 }} align="end">
              <Tooltip title={"Close"} arrow placement="right">
                <IconButton size={"small"} onClick={handleCloseCSV}>
                  <CancelOutlined />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Box my={5}>
            <FileUploader
              id={"logo"}
              handleChange={handleChange}
              name="file"
              types={fileTypes}
              hoverTitle="Drop here"
              children={customDropZoneContent}
            />
          </Box>
          <Button
            variant="text"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              marginLeft: "auto",
              marginBottom: 3,
            }}
          >
            <Download />
            Download Sample CSV
          </Button>
          <Box
            sx={{
              width: "100%",
              borderTop: 1,
              pt: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              gap: 2,
            }}
          >
            <Button
              sx={{
                bgcolor: "#f1f1f1",
                color: "grey",
                fontSize: 12,
                px: 4,
              }}
              onClick={handleCloseCSV}
            >
              Cancel
            </Button>
            <Button
              sx={{
                bgcolor: "#000",
                color: "white",
                fontSize: 12,
                px: 4,
              }}
              onClick={() => {
                setSubmitted(true);
                handleCloseCSV();
              }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Dialog>
    </Dialog>
  );
};

export default AddPostDialog;
