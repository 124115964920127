const campaign = [
  {
    id: 1,
    brand: "Coca-cola",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Under Review",
    completion: "70",
    platform: {
      logo: "https://e7.pngegg.com/pngimages/875/247/png-clipart-product-design-number-logo-instalogo-purple-text.png",
      brand: "Instagram",
    },
    invited: "15th Nov 2023",
    date: "15th Nov 2023",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },
  {
    id: 2,
    brand: "Nike",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    completion: "70",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      brand: "Instagram",
    },
    invited: "20th Jan 2024",
    date: "20th Jan 2024",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },

  {
    id: 3,
    brand: "Apple",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    completion: "70",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      brand: "Twitter",
    },
    invited: "5th Feb 2024",
    date: "5th Feb 2024",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },
  {
    id: 4,
    brand: "Adidas",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    completion: "70",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      brand: "Facebook",
    },
    invited: "10th Mar 2024",
    date: "10th Mar 2024",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },
  {
    id: 5,
    brand: "Samsung",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Under Review",
    completion: "70",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      brand: "YouTube",
    },
    invited: "18th Apr 2024",
    date: "18th Apr 2024",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },
  {
    id: 6,
    brand: "Microsoft",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    completion: "70",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      brand: "Twitter",
    },
    invited: "25th May 2024",
    date: "25th May 2024",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },
  {
    id: 7,
    brand: "Amazon",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    completion: "70",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/800px-Facebook_icon_2013.svg.png",
      brand: "Facebook",
    },
    invited: "3rd Jun 2024",
    date: "3rd Jun 2024",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },
  {
    id: 8,
    brand: "Google",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Rejected",
    completion: "70",
    platform: {
      logo: "https://cdn1.iconfinder.com/data/icons/logotypes/32/circle-linkedin-512.png",
      brand: "LinkedIn",
    },
    invited: "12th Jul 2024",
    date: "12th Jul 2024",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },
  {
    id: 9,
    brand: "Sony",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Under Review",
    completion: "70",
    platform: {
      logo: "https://png.pngtree.com/png-vector/20221018/ourmid/pngtree-twitter-social-media-round-icon-png-image_6315985.png",
      brand: "Twitter",
    },
    invited: "21st Aug 2024",
    date: "21st Aug 2024",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },
  {
    id: 10,
    brand: "Tesla",
    logo: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSffqxmWpUMIyY03fLbHP5MK1jukQ0PSpnPRw&usqp=CAU",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Approved",
    completion: "70",
    platform: {
      logo: "https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/youtube_circle-512.png",
      brand: "YouTube",
    },
    invited: "9th Sep 2024",
    date: "9th Sep 2024",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },
  {
    id: 11,
    brand: "Puma",
    logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/8/86/Puma_logo.svg/1280px-Puma_logo.svg.png",
    your_offer: "₹230",
    counter_offer: "₹400",
    status: "Pending",
    completion: "70",
    platform: {
      logo: "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e7/Instagram_logo_2016.svg/1024px-Instagram_logo_2016.svg.png",
      brand: "Instagram",
    },
    invited: "17th Oct 2024",
    date: "17th Oct 2024",
    campaign: "ABC Campaign",
    subtitle: "Marketing campaign by Parle",
  },
];

export { campaign };
