import Grid from "@mui/material/Grid2";
import { Typography, Box, Icon, IconButton, Button, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
// import { Box } from '@mui/system'

import DP from "../../../images/dp-lg.png";
import dp from "../../../images/dp.png";

import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const AccountAnalytics = ({ mediaData, data, ID, selectedSocial }) => {
  function convertDurationToSeconds(duration) {
    const parts = duration.split(":");
    const hours = parseInt(parts[0], 10) || 0;
    const minutes = parseInt(parts[1], 10) || 0;
    const seconds = parseInt(parts[2], 10) || 0;

    // Convert duration to total seconds
    return hours * 3600 + minutes * 60 + seconds;
  }

  function calculateAvgViewsVideo(mediaArray) {
    if (!mediaArray) {
      return "No Media History";
    }

    let totalViews = 0;

    mediaArray.forEach((media) => {
      totalViews += parseInt(media.viewcount) || 0;
    });

    totalViews = totalViews / mediaArray.length;

    return parseInt(totalViews);
  }

  function calculateAvgViewsShorts(mediaArray) {
    if (!mediaArray) {
      return "No Media History";
    }

    let totalViews = 0;

    mediaArray.forEach((media) => {
      const durationInSeconds = convertDurationToSeconds(media.duration);

      // Only add if media duration is greater than 60 seconds (1 minute)
      if (durationInSeconds < 60) {
        totalViews += parseInt(media.viewcount) || 0;
      }
    });

    totalViews = totalViews / mediaArray.length;

    return parseInt(totalViews);
  }

  function calculateAvgComments(mediaArray) {
    if (!mediaArray) {
      return "No Media History";
    }

    let totalcomment = 0;

    mediaArray.forEach((media) => {
      totalcomment += parseInt(media.commentcount) || 0;
    });

    totalcomment = totalcomment / mediaArray.length;

    return parseInt(totalcomment);
  }

  function calculateinterests(mediaArray) {
    if (!mediaArray) {
      return "No Media History";
    }

    // Use a Set to collect unique hashtags
    let hashtagsSet = new Set();

    mediaArray.forEach((media) => {
      // Check if media has keywords
      if (media.category) {
        hashtagsSet.add(media.category);
      }
    });
    // Convert the Set to an array and limit to 5
    return Array.from(hashtagsSet).slice(0, 5).join(", ");
  }

  function calculateInterests(media) {
    if (!media) {
      // Check if the user has media and if the media array is not empty
      return "No Media History";
    }

    // Use a Set to collect unique hashtags
    let hashtagsSet = new Set();

    // Check if media has keywords

    // Split the keywords string by comma and trim whitespace
    let keywordsArray = media
      .slice(1, media.length - 1)
      .replace(/'/g, "")
      .split(",")
      .map((keyword) => keyword.trim())
      .filter((keyword) => keyword !== "");

    keywordsArray.forEach((keyword) => {
      hashtagsSet.add(keyword); // Add each keyword to the Set
    });

    // Convert the Set to an array and limit to 5
    return Array.from(hashtagsSet)[0];
  }

  const formatRender = (value) => {
    let valuetype = typeof value;

    if (valuetype === "string") {
      // replace all single quotes with double quotes
      let temp = value.replace(/'/g, '"');
      temp = JSON.parse(temp);

      if (Array.isArray(temp)) {
        return temp.join(", ");
      } else {
        return temp;
      }
    } else if (valuetype === "number") {
      // convert the string to number and the make the decimal values fixed to 2
      return value.toFixed(2);
    } else {
      return value;
    }
  };

  const InstaOptions = [
    // { key: "influencer_category", title: "Influencer Category" },
    // { key: "influencer_gender", title: "Influencer Gender" },
    { key: "engagement_rate", title: "Engagement Rate" },
    { key: "average_reels_views", title: "Average Reels Views" },
    { key: "average_likes", title: "Average Likes" },
    { key: "average_comments", title: "Average Comments" },
    { key: "brand_mentions", title: "Brand Mentions" },
    { key: "monthly_followers_growth", title: "Monthly Followers Growth" },
  ];

  const YoutubeOptions = [
    // { key: "influencer_category", title: "Influencer Category" },
    // { key: "gender", title: "Influencer Gender" },
    {
      key: "average_video_views",
      title: "Average Video Views",
      value: mediaData && calculateAvgViewsVideo(mediaData),
    },
    {
      key: "average_comments",
      title: "Average Comments",
      value: mediaData && calculateAvgComments(mediaData),
    },
    {
      key: "topics",
      title: "Influencer Interests",
      value: data && calculateInterests(data.topics),
    },
    {
      key: "engagement_rate",
      title: "Engagement Rate",
      value: data && data?.engagement_rate?.toFixed(2),
    },
    // { key: "mentions", title: "Brand Mentions" },
    // { key: "monthly_subscribers_growth", title: "Monthly Subscribers Growth" }
  ];

  let encodedId = btoa(ID?.toString());
  return (
    <Box
      borderRadius={4}
      padding={3}
      marginTop={4}
      sx={{
        background: "#e3f2fd",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: { md: "center", xs: "flex-start" },
          marginBottom: 3,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <Typography sx={{ fontWeight: "bold", fontSize: 20 }}>
          Account Analytics{" "}
          {selectedSocial === "instagram" ? " Instagram" : " Youtube"}
        </Typography>
        <Link
          component={RouterLink}
          to={`/dashboard/find-influencers/profile/${encodedId}`}
          sx={{
            textDecoration: "none",
            color: "inherit",
            alignItems: "center",
          }}
        >
          <Button
            size="small"
            variant="contained"
            sx={{
              borderRadius: 30,
              color: "#FFFFFF",
              fontWeight: "bold",
              background: "#3DBE72",
              paddingY: 0.8,
              paddingX: 2,
            }}
          >
            View Full Report
          </Button>
        </Link>
      </Box>

      <Grid container spacing={2} columns={{ xs: 1, md: 2 }}>
        {selectedSocial === "instagram"
          ? InstaOptions.map((option, index) => (
              <Box
                item
                key={index}
                sx={{ background: "#FFFFFF", padding: 15, borderRadius: 10 }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography sx={{ fontSize: 12, fontWeight: "bold" }}>
                    {option.title}
                  </Typography>
                  <IconButton sx={{ color: "#3DBE72" }}>
                    <HelpOutlineRoundedIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                </Box>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.7rem" }}>
                  {"266"}
                </Typography>
              </Box>
            ))
          : YoutubeOptions.map((option, index) => {
              return (
                <Grid
                  size={1}
                  key={index}
                  sx={{ background: "#FFFFFF", padding: 2, borderRadius: 4 }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ fontSize: 14, color: "gray" }}>
                      {option.title}
                    </Typography>
                    <IconButton sx={{ color: "#3DBE72" }}>
                      <HelpOutlineRoundedIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Box>
                  <Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: "1.4rem" }}>
                      {option?.value ? option.value : "No data found"}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
      </Grid>
    </Box>
  );
};

export default AccountAnalytics;
