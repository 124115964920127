import {
  Dialog,
  Box,
  Typography,
  IconButton,
  Tooltip,
  styled,
  Chip,
  Button,
  Checkbox,
  TextField,
  Autocomplete,
  TextareaAutosize,
  Switch,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Preview from "../../../images/invitation.svg";
import {
  CancelOutlined,
  DeleteOutlineOutlined,
  Add,
} from "@mui/icons-material";
import { borderRadius } from "@mui/system";

const label = { inputProps: { "aria-label": "Switch demo" } };

const fileTypes = ["JPG", "PNG", "GIF"];
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.1rem",
}));

const HtmlLabel = styled("label")(`
      color : #828282ff;
      font-size : '1.1rem';
  
  `);

const HtmlInput = styled("input")(`
      background-color : #dce7f7ff;
      border-radius : 8px;
      width : 100%;
      height : 40px;
      padding : 0px 20px;
      outlied : none;
      border : none;
  `);

const HtmlTextArea = styled("textarea")(`
  width : 100%;
  padding : 10px 20px;
  border-radius : 8px;
  height : 120px;
  background-color : #dce7f7ff;
  border : none;
  `);

const HtmlInputNum = styled("input")(`
      width : 80px;
      text-align : center;
     border : none;
      border-radius : 10px;
      margin-right : 8px;
      background-color : #dce7f7ff;
  `);

const InviteDialog = ({ open, set, selectedRow }) => {
  console.log("Selected Row: ", selectedRow);
  return (
    <Dialog
      open={open}
      onClose={() => set(false)}
      PaperProps={{
        sx: {
          borderRadius: 3,
          border: "2px solid black",
          padding: 4,
          minWidth: 300,
          width: 650,
          maxWidth: 800,
        },
      }}
    >
      <Box>
        <Grid container>
          <Grid item size={{ md: 11, xs: 11 }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: "bold",
                fontSize: "1.35rem",
              }}
            >
              Preview Invite - @Grasp Marketing
            </Typography>
          </Grid>
          <Grid item size={{ md: 1, xs: 1 }} align="end">
            <Tooltip title={"Close"} arrow placement="right">
              <IconButton size={"small"} onClick={() => set(false)}>
                <CancelOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            height: "100%",
            pt: 3,
            gap: 2,
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                md: "row",
              },
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ borderRadius: 1, marginBottom: 3 }}>
                <img
                  width={"100%"}
                  height={150}
                  style={{ objectFit: "contain" }}
                  src={Preview}
                  alt="illustration"
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: 2,
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      color: "grey",
                      fontWeight: 550,
                    }}
                  >
                    <Typography>Show Description</Typography>
                    <Tooltip title="Description">
                      <IconButton size="small">
                        <HelpIcon fontSize="2" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <Switch {...label} defaultChecked />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      color: "grey",
                      fontWeight: 550,
                    }}
                  >
                    <Typography>Counter Offer</Typography>
                    <Tooltip title="Counter Offer">
                      <IconButton size="small">
                        <HelpIcon fontSize="2" />
                      </IconButton>
                    </Tooltip>
                  </Box>
                  <TextField
                    sx={{
                      backgroundColor: "#dde8f8",
                    }}
                    fullWidth
                    size="small"
                    placeholder="Enter counter offer"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                height: "100%",
              }}
            >
              <Typography
                sx={{ borderBottom: 1, borderColor: "grey", paddingBottom: 1 }}
                variant="h6"
              >
                {selectedRow?.name || "Campaign Name"}
              </Typography>
              <Typography variant="body2">Description</Typography>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <Typography variant="body2">Influencer Counter Offer</Typography>
              <Button
                sx={{
                  backgroundColor: "#dde8f8",
                  color: "black",
                  border: 1,
                }}
                size="small"
              >
                {selectedRow?.counter_offer}
              </Button>
            </Box>
            <Button
              sx={{
                borderRadius: 30,
                bgcolor: "#3dbe72",
                color: "#fff",
                marginTop: 4,
                fontSize: 13,
              }}
            >
              Preview Invitation
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InviteDialog;
