import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
// components
import Login from "./assets/components/Login";
import Signup from "./assets/components/Signup";
// import Dashboard from "./assets/components/Dashboard";
import MiniDrawer from "./assets/components/MiniDrawer";
import Home from "./assets/components/Home";
import FindInfluencer from "./assets/components/FindInfluencer";
import Profile from "./assets/components/subcomponents/findInfluencer/Profile";
import SignupForAddSocialMedia from "./assets/components/SignupForAddSocialMedia";
import InstagramConfirm from "./assets/components/InstagramConfirm";
import ManageBrands from "./assets/components/manage brands/ManageBrands";
import Reports from "./assets/components/reports comp/Reports";
import ManageInfluencers from "./assets/components/manage influencers/ManageInfluencers";
import ManageEmployees from "./assets/components/manage employees/ManageEmployees";
import Collections from "./assets/components/collection/Collections";
import CollectionProfile from "./assets/components/collection/CollectionProfile";
import Campaigns from "./assets/components/campaigns/Campaigns";
import InfluencerTable from "./assets/components/collection/InfluencerTable";
import CollectionProfileAuthFree from "./assets/components/collection/CollectionProfileAuthfree";
import Analytics from "./assets/components/collection/Analytics";
import CampaignProfile from "./assets/components/campaigns/CampaignProfile";
import Setting from "./assets/components/setting component/Setting";
import UserProfile from "./assets/components/profile/UserProfile";
import PublicInfluencerProfile from "./assets/components/public content/PublicInfluencerProfile";

import NotificationProvider from "./notificationProvider";

import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";

import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";

import axios from "axios";
import { CssBaseline } from "@mui/material";
import CreateCampaign from "./assets/components/campaigns/CreateCampaign";
import CampaignReport from "./assets/components/campaigns/CampaignReport";
import CreateCampaignsReport from "./assets/components/campaigns/CreateCampaignReport";
import Report from "./assets/components/campaigns/Report";
import InfluencerCampaigns from "./assets/components/campaigns/InfluencerCampaigns";
import InfluencerCampaignsInvite from "./assets/components/campaigns/InfluencerCampaignsInvite";
import BrandCampaigns from "./assets/components/campaigns/BrandCampaigns";

axios.defaults.withCredentials = true;

const RouteType = ({ children }) => {
  const isAuthorized = useSelector((state) => state.authorization);
  const userData = useSelector((state) => state.user);
  const socialVerification = useSelector(
    (state) => state.socialVerification.verificationStatus
  );
  const socialRedirectionURL = useSelector(
    (state) => state.socialVerification.redirectURL
  );
  const navigate = useNavigate();

  const role = userData?.user?.role;

  useEffect(() => {
    if (!isAuthorized) {
      // navigate('/login');
      window.location.href = "/login";
    } else {
      if (role == "1" || role == "2" || role == "3") {
        // navigate("/dashboard/home");
      } else if (role == "4") {
        if (socialVerification == false) {
          if (socialRedirectionURL != "") {
            navigate(socialRedirectionURL);
          } else {
            navigate("/add-social-accounts");
          }
        }
      }
    }
  }, [isAuthorized]);

  return (
    <>
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {});
        }
        return child;
      })}
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/public-content/influencer-profile/:id",
    element: <PublicInfluencerProfile />,
  },
  {
    path: "/dashboard",
    element: (
      <RouteType>
        <MiniDrawer />
      </RouteType>
    ),
    children: [
      {
        path: "/dashboard/home",
        element: (
          <RouteType>
            <Home />
          </RouteType>
        ),
      },
      // {
      //   index: true,
      //   element: (
      //     <Navigate to="/dashboard/home" replace />
      //   ),
      // },
      {
        path: "/dashboard/find-influencers",
        element: (
          <RouteType>
            <FindInfluencer />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/find-influencers/profile/:id",
        element: (
          <RouteType>
            <Profile />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/collection",
        element: (
          <RouteType>
            <Collections />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/collection/:id",
        element: (
          <RouteType>
            <CollectionProfile />
          </RouteType>
        ),
        children: [
          {
            path: "list",
            element: <InfluencerTable />,
          },
          {
            path: "",
            element: <InfluencerTable />,
          },
          {
            path: "analytics",
            element: <Analytics />,
          },
        ],
      },
      {
        path: "/dashboard/campaign",
        element: (
          <RouteType>
            <Campaigns />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/influencer-campaigns/invite",
        element: (
          <RouteType>
            <InfluencerCampaignsInvite />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/influencer-campaigns/dashboard",
        element: (
          <RouteType>
            <InfluencerCampaigns />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/brand-campaigns/dashboard",
        element: (
          <RouteType>
            <BrandCampaigns />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/campaign/create",
        element: (
          <RouteType>
            <CreateCampaign />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/campaign-report",
        element: (
          <RouteType>
            <CampaignReport />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/campaign-report/create",
        element: (
          <RouteType>
            <CreateCampaignsReport />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/campaign-report/report",
        element: (
          <RouteType>
            <Report />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/campaign/profile",
        element: (
          <RouteType>
            <CampaignProfile />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/manage-brands",
        element: (
          <RouteType>
            <ManageBrands />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/reports",
        element: (
          <RouteType>
            <Reports />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/manage-influencers",
        element: (
          <RouteType>
            <ManageInfluencers />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/manage-employees",
        element: (
          <RouteType>
            <ManageEmployees />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/setting",
        element: (
          <RouteType>
            <Setting />
          </RouteType>
        ),
      },
      {
        path: "/dashboard/profile",
        element: (
          <RouteType>
            <UserProfile />
          </RouteType>
        ),
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/add-social-accounts",
    element: <SignupForAddSocialMedia />,
  },
  {
    path: "/confirm-social-media",
    element: <InstagramConfirm />,
  },
  {
    path: "/public/collection/:id",
    element: <CollectionProfileAuthFree />,
  },
]);

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <NotificationProvider>
        <CssBaseline />
        <RouterProvider router={router} />
      </NotificationProvider>
    </ThemeProvider>
  );
};

export default App;
