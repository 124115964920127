import {
  Box,
  styled,
  TextField,
  Typography,
  Button,
  Chip,
  Avatar,
  ToggleButtonGroup,
  ToggleButton,
  Tabs,
  Tab,
  Autocomplete,
  IconButton,
  Container,
} from "@mui/material";

import Grid from "@mui/material/Grid2";
import DataTable from "react-data-table-component";
import { useEffect, useState } from "react";
import { GridViewRounded, ViewListRounded } from "@mui/icons-material";
import { campaign } from "./campaignCardData.js";
import CampaignCard from "./CampaignCard.jsx";

const customStyles = {
  head: {
    style: {
      fontSize: "15px", // Set the desired font size for the header
    },
  },
  rows: {
    style: {
      cursor: "pointer",
    },
  },
};

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const CampaignDataTable = ({ rowData, onRowClick, columns, customStyle }) => {
  const [tableData, setTableData] = useState(rowData);

  useEffect(() => {}, [tableData]);

  return (
    <Container
      // maxWidth={"xl"}
      sx={{
        minWidth: "100%",
        overflowX: "hidden",
        paddingX: "0 !important",
        paddingLeft: "0 !important",
        paddingRight: "0 !important",
        marginX: 0,
        marginTop: 0,
        width: "100%",
      }}
    >
      {/* ListViews  */}
      <DataTable
        columns={columns}
        data={tableData}
        pagination
        onRowClicked={onRowClick}
        fixedHeader
        fixedHeaderScrollHeight="100%"
        customStyles={customStyle ? customStyle : customStyles}
      />
    </Container>
  );
};

export default CampaignDataTable;
