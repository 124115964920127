import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Box,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  styled,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import TrendingUpRoundedIcon from "@mui/icons-material/TrendingUpRounded";
import {
  ChatBubble,
  ChatBubbleOutline,
  Help,
  HelpCenterOutlined,
  HelpCenterRounded,
  TrendingDownRounded,
} from "@mui/icons-material";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ padding: 0, width: "100%", margin: 0 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            py: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            paddingX: 0,
            marginX: 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 30,
  // width: "100%",
  borderRadius: 2,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
    ...theme.applyStyles("dark", {
      backgroundColor: theme.palette.grey[800],
    }),
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 2,
    backgroundColor: "#3dbe72",
    ...theme.applyStyles("dark", {
      backgroundColor: "#3dbe72",
    }),
  },
}));

function a11yProps4(index) {
  return {
    id: `simple-tab4-${index}`,
    "aria-controls": `simple-tabpanel4-${index}`,
  };
}

function a11yProps5(index) {
  return {
    id: `simple-tab5-${index}`,
    "aria-controls": `simple-tabpanel5-${index}`,
  };
}

const CustomTab = styled(Tab)(({ isActive }) => ({
  border: isActive ? `1px solid #3DBE72` : "none", // Change the color as needed
  borderRadius: 5,
  color: isActive ? "#3DBE72" : "#000", // Change text color for active tab
  "&:hover": {
    color: "#3DBE72", // Change text color on hover
  },
}));

const listItems = [
  "Item1",
  "Item2",
  "Item3",
  "Item4",
  "Item5",
  "Item6",
  "Item7",
  "Item8",
  "Item9",
  "Item10",
  "Item11",
  "Item12",
  "Item13",
  "Item14",
  "Item15",
  "Item16",
  "Item17",
  "Item18",
  "Item19",
  "Item20",
];

function formatNumber(num) {
  try {
    // Convert the input to a number in case it's a string
    const number = Number(num);

    // Check if the conversion was successful
    if (isNaN(number)) {
      throw new Error("Invalid number");
    }

    // Format the number based on its value
    if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + "M"; // Convert to millions
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + "K"; // Convert to thousands
    } else {
      return number.toString(); // Return the number as is
    }
  } catch (error) {
    return "error";
  }
}

const OverviewData = [
  {
    heading: "",
    titles: [
      {
        name: "Total Influencers",
        value: 0,
        desc: "Total influencers of the campaign",
      },
      {
        name: "Total Audience",
        value: 0,
        desc: "Addition of audience of all the influencers of the campaign",
      },
      {
        name: "Influencers Posted",
        value: 0,
        desc: "Number of influencers who have posted out of the total influencers",
      },
      {
        name: "Number of deliverables",
        values: [
          { name: "Long Video", value: 100000 },
          { name: "Short Video", value: 20000 },
        ],
        desc: "Number of videos or Shorts of the campaign",
      },
      {
        name: "Total Views",
        value: 0,
        desc: "Number of total views of all the posts of the campaign",
      },
      // {
      //   name: "Total Impressions",
      //   value: 0,
      //   desc: "Number of total impressions on all the posts of the campaign",
      // },
      {
        name: "Total Engagements",
        value: 0,
        desc: "Number of total engagements received on all the posts of the campaign. Total Likes | Total Comments | Total Shares",
      },
      {
        name: "Mentions Tracking",
        value: 0,
        desc: "Performance of the hashtags used in the campaign. Number of mentions the brand received in the influencer's content",
      },
      {
        name: "Top words and hashtags in the posts",
        value: 0,
        desc: "",
      },
    ],
  },
  {
    heading: "Budget Overview",
    titles: [
      { name: "Budget Spent", value: 0, desc: "" },
      {
        name: "Spend per influencer",
        value: 0,
        desc: "Amount paid to each influencer",
      },
      {
        name: "CPE (Cost Per Engagemet)",
        value: 0,
        desc: "Calculated as cost of campaign / total engagement of campaign",
      },
      {
        name: "CPC (Cost Per Click)",
        value: 0,
        desc: "Calculated as cost of campaign / total clicks in campaign",
      },
      {
        name: "CPM (Cost Per Mile)",
        value: 0,
        desc: "Amount spent per 1000 impressions",
      },
      {
        name: "ER (Engagement Rate)",
        value: 0,
        desc: "Average engagement rate per post",
      },
      {
        name: "EMV (Earned Media Value)",
        value: 0,
        desc: "Earned Media Value",
      },
      {
        name: "CPV (Cost Per View)",
        value: 0,
        desc: "Calculated as cost of campaign / total views of campaign",
      },
      {
        name: "CTR (Click Through Rate)",
        value: 0,
        desc: "How often people click on a link after seeing it",
      },
      {
        name: "ROI (Return on Investment) %",
        value: 0,
        desc: "Return on Investment percentage",
      },
    ],
  },
  {
    heading: "Audience Analytics",
    titles: [
      { name: "Age-Gender Split", values: { percentage: 0, num: 0 } },
      { name: "Language", values: { percentage: 0, num: 0 } },
      { name: "Interest", values: { percentage: 0, num: 0 } },
      {
        name: "Type - Real, Mass, Suspicious, Influencers",
        values: { percentage: 0, num: 0 },
      },
      { name: "Countries", values: { percentage: 0, num: 0 } },
    ],
  },
];

const OverviewTab = () => {
  const [value4, setValue4] = useState(0);
  const [value5, setValue5] = useState(0);

  const handleChange4 = (event, newValue) => {
    setValue4(newValue);
  };

  const handleChange5 = (event, newValue) => {
    setValue5(newValue);
  };

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      {/* Overview section  */}
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          flexWrap: "wrap",
          marginBottom: 5,
        }}
      >
        {OverviewData[0].titles
          .filter((title) => {
            if (
              title.name !== "Total Engagements" &&
              title.name !== "Top words and hashtags in the posts"
            )
              return true;
            return false;
          })
          .map((data, sectionIndex) => (
            <Box
              key={sectionIndex}
              sx={{
                borderRadius: 2,
                width: "260px",
                height: "120px",
                border: 1,
                borderColor: "grey",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                py: 1,
                px: 2,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  {data.name}
                </Typography>
                {data.value && (
                  <Typography sx={{ fontSize: 18, fontWeight: "normal" }}>
                    {data.value}
                  </Typography>
                )}
                {data.values && (
                  <Box
                    sx={{ display: "flex", gap: 1, flexDirection: "column" }}
                  >
                    {data.values.map((subdata) => (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={{ fontSize: 15, fontWeight: "normal" }}>
                          {subdata.name}
                        </Typography>
                        <Typography sx={{ fontSize: 15, fontWeight: "normal" }}>
                          {formatNumber(subdata.value)}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                )}
              </Box>
              <Tooltip title={data.desc}>
                <IconButton>
                  <Help sx={{ fontSize: "15px", color: "#3dbe72" }} />
                </IconButton>
              </Tooltip>
            </Box>
          ))}
      </Box>

      {/* stats section  */}
      <Box sx={{ width: "100%", display: "flex", gap: 2, marginTop: 5 }}>
        {/* video views  */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: 18,
              textTransform: "capitalize",
              width: "100%",
            }}
          >
            Total Views
          </Typography>
          <Typography
            sx={{
              fontSize: 24,
              fontWeight: "bold",
              width: "100%",
            }}
          >
            1.97m
          </Typography>
          <Typography
            sx={{
              fontSize: 15,
              fontWeight: "normal",
              width: "100%",
            }}
          >
            No. of people viewed influencer's posts
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              bgcolor: "#f8fafc",
              alignItems: "flex-start",
              borderRadius: 1,
              gap: 0.5,
              py: 1,
              px: 1.5,
              marginTop: 1,
            }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  gap: 0.3,
                  fontSize: 15,
                  alignItems: "center",
                }}
              >
                <FavoriteBorderOutlinedIcon fontSize="15px" />
                Total Likes
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: 16, color: "#3dbe72" }}
              >
                36.06k
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  gap: 0.3,
                  fontSize: 15,
                  alignItems: "center",
                }}
              >
                <ChatBubbleOutline fontSize="15px" />
                Total Comments
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: 16, color: "#3dbe72" }}
              >
                36.06k
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  gap: 0.3,
                  fontSize: 15,
                  alignItems: "center",
                }}
              >
                <RemoveRedEyeOutlinedIcon fontSize="15px" />
                Total View Rate
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: 16, color: "#3dbe72" }}
              >
                36.06%
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  display: "flex",
                  gap: 0.3,
                  fontSize: 15,
                  alignItems: "center",
                }}
              >
                <CurrencyRupeeOutlinedIcon fontSize="15px" />
                Total CPV
              </Typography>
              <Typography
                sx={{ fontWeight: "600", fontSize: 16, color: "#3dbe72" }}
              >
                ₹0.08
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* daily video views */}
        {/* <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            px: 2,
            borderLeft: 1,
            borderRight: 1,
          }}
        >
          <Typography
            sx={{
              fontSize: 18,
              textTransform: "uppercase",
              width: "100%",
            }}
          >
            Daily Video Views
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "100%",
              borderRadius: 2,
              margin: 2,
              marginX: "auto",
              overflow: "hidden",
            }}
          >
            <img
              width={"100%"}
              height={"100%"}
              alt="stats"
              src="https://images.pexels.com/photos/95916/pexels-photo-95916.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            />
          </Box>
        </Box> */}

        {/* difference view */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            sx={{
              fontSize: 18,
              textTransform: "capitalize",
              width: "100%",
            }}
          >
            Total Engagements
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              gap: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: "500",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                gap: 0.5,
                color: "#3dbe72",
              }}
            >
              {/* {currentEng < avgEng ? (
                <TrendingDownRounded fontSize="18px" sx={{ color: "red" }} />
              ) : (
                <TrendingUpRoundedIcon fontSize="18px" sx={{ color: "#000" }} />
              )} */}
              <TrendingUpRoundedIcon fontSize="18px" sx={{ color: "#000" }} />
              Progress
            </Typography>
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: "500",
                textTransform: "capitalize",
                display: "flex",
                alignItems: "center",
                gap: 0.5,
              }}
            >
              3.5%
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 2,
              marginTop: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: 2,
                px: 2,
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>18%</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.75,
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "normal" }}>Avg. ER</Typography>
                <CustomLinearProgress value={50} variant="determinate" />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
                gap: 2,
                px: 2,
                width: "100%",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>19%</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0.75,
                  width: "100%",
                }}
              >
                <Typography sx={{ fontWeight: "normal" }}>
                  Current ER
                </Typography>
                <CustomLinearProgress value={100} variant="determinate" />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* top words and hastags section  */}
      <Box sx={{ width: "100%", marginTop: 5, display: "flex", gap: 4 }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Tabs
            value={value4}
            onChange={handleChange4}
            aria-label="top words tabs"
            sx={{
              marginLeft: "auto",
              "& .MuiTabs-indicator": {
                display: "none", // Hide the underline
              },
            }}
          >
            <CustomTab
              isActive={value4 === 0}
              label="Top 20 Words"
              {...a11yProps4(0)}
            />
            <CustomTab
              isActive={value4 === 1}
              label="Word Cloud"
              {...a11yProps4(1)}
            />
          </Tabs>
          <CustomTabPanel value={value4} index={0}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(235, 235, 235, 0.5)",
                paddingLeft: 4,
                minHeight: "300px",
                borderRadius: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: 20,
                }}
              >
                {/* Left List */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    p: 2,
                  }}
                >
                  <ol
                    style={{
                      display: "flex",
                      gap: 20,
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    {listItems.slice(0, 10).map((item, index) => (
                      <li key={index}>
                        <Typography variant="body1">{item}</Typography>
                      </li>
                    ))}
                  </ol>
                </Box>

                {/* Right List */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    p: 2,
                  }}
                >
                  <ol
                    start={11}
                    style={{
                      display: "flex",
                      gap: 20,
                      flexDirection: "column",
                      justifyContent: "flex-start",
                    }}
                  >
                    {listItems.slice(10).map((item, index) => (
                      <li key={index}>
                        <Typography variant="body1">{item}</Typography>
                      </li>
                    ))}
                  </ol>
                </Box>
              </Box>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value4} index={1}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(235, 235, 235, 0.5)",
                borderRadius: 5,
                overflow: "hidden",
                p: 0,
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                minHeight: "300px",
              }}
            >
              <img
                width="100%"
                height={"100%"}
                alt="some illustration"
                style={{ objectFit: "cover" }}
                src="https://images.pexels.com/photos/278887/pexels-photo-278887.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
            </Box>
          </CustomTabPanel>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Tabs
            sx={{
              marginLeft: "auto",
              "& .MuiTabs-indicator": {
                display: "none", // Hide the underline
              },
            }}
            value={value5}
            onChange={handleChange5}
            aria-label="top hashtags tabs"
          >
            <CustomTab
              isActive={value5 === 0}
              label="Top 20 Hashtags"
              {...a11yProps5(0)}
            />
            <CustomTab
              isActive={value5 === 1}
              label="Hashtag Cloud"
              {...a11yProps5(1)}
            />
          </Tabs>
          <CustomTabPanel value={value5} index={0}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(235, 235, 235, 0.5)",
                paddingLeft: 4,
                minHeight: "300px",
                borderRadius: 3,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  gap: 20,
                }}
              >
                {/* Left List */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                  }}
                >
                  <ol
                    style={{
                      display: "flex",
                      gap: 20,
                      flexDirection: "column",
                    }}
                  >
                    {listItems.slice(0, 10).map((item, index) => (
                      <li key={index}>
                        <Typography variant="body1">{item}</Typography>
                      </li>
                    ))}
                  </ol>
                </Box>

                {/* Right List */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    p: 2,
                  }}
                >
                  <ol
                    start={11}
                    style={{
                      display: "flex",
                      gap: 20,
                      flexDirection: "column",
                    }}
                  >
                    {listItems.slice(10).map((item, index) => (
                      <li key={index}>
                        <Typography variant="body1">{item}</Typography>
                      </li>
                    ))}
                  </ol>
                </Box>
              </Box>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={value5} index={1}>
            <Box
              sx={{
                width: "100%",
                height: "100%",
                minHeight: "300px",
                backgroundColor: "rgba(235, 235, 235, 0.5)",
                borderRadius: 5,
                overflow: "hidden",
                p: 0,
                display: "flex",
                alignItems: "flex-start",
              }}
            >
              <img
                width="100%"
                height={"100%"}
                alt="some illustration"
                style={{ objectFit: "cover" }}
                src="https://images.pexels.com/photos/278887/pexels-photo-278887.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
            </Box>
          </CustomTabPanel>
        </Box>
      </Box>

      {/* Budget Section  */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          p: 2,
          border: 1,
          borderRadius: 3,
          boxShadow: 1,
        }}
      >
        <Box
          sx={{
            width: "100%",
            marginBottom: 2,
            py: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
            {OverviewData[1].titles[0].name}
          </Typography>
          <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
            {OverviewData[1].titles[0].value}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            flexWrap: "wrap",
            marginBottom: 5,
          }}
        >
          {OverviewData[1].titles.slice(1).map((data, sectionIndex) => (
            <Box
              key={sectionIndex}
              sx={{
                borderRadius: 2,
                width: "260px",
                height: "100px",
                border: 1,
                borderColor: "grey",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                py: 1,
                px: 2,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  {data.name}
                </Typography>
                <Typography sx={{ fontSize: 18, fontWeight: "normal" }}>
                  {data.value}
                </Typography>
              </Box>
              <Tooltip title={data.desc}>
                <IconButton>
                  <Help sx={{ color: "#3dbe72", fontSize: "15px" }} />
                </IconButton>
              </Tooltip>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Audience Analytics  */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          p: 2,
          border: 1,
          borderRadius: 3,
          boxShadow: 1,
          marginTop: 5,
        }}
      >
        <Box
          sx={{
            width: "100%",
            py: 1,
            marginBottom: 2,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            gap: 3,
          }}
        >
          <Typography sx={{ fontSize: "1rem", fontWeight: 500 }}>
            {OverviewData[2].heading}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          {OverviewData[2].titles.map((data, sectionIndex) => (
            <Box
              key={sectionIndex}
              sx={{
                borderRadius: 2,
                width: "260px",
                height: "100px",
                border: 1,
                borderColor: "grey",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-between",
                py: 1,
                px: 2,
                gap: 2,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1,
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  {data.name}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography sx={{ fontSize: 18, fontWeight: "normal" }}>
                    {data.values.percentage} %
                  </Typography>
                  <Typography sx={{ fontSize: 18, fontWeight: "normal" }}>
                    {data.values.num}
                  </Typography>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default OverviewTab;
