import React, { useEffect, useState } from "react";

import { Avatar, Typography, Box } from "@mui/material";

import { useNavigate, Link } from "react-router-dom";

import DataTable from "react-data-table-component";

// import { Influencer } from './collectionData';
import { getCollectionById } from "../../../service/collections api/getCollectionById";
import { getAllInfluencers } from "../../../service/getAllApi";

const temptDP =
  "https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg";

const CustomAvgLC = ({ row }) => (
  <span>
    {"106.6k"}/{"99   "}
  </span>
);

const customStyles = {
  table: {
    style: {
      overflowX: "auto", // Ensure horizontal scrolling if needed
    },
  },
  scrollbar: {
    width: "10px", // Width of the scrollbar
  },
  thumb: {
    background: "#ccc", // Color of the scrollbar thumb
    borderRadius: "5px", // Radius of the scrollbar thumb
  },
  track: {
    background: "#f0f0f0", // Color of the scrollbar track
  },
};

const Table = ({ influencerData, loading, rowsPerPage, setRowsPerPage }) => {
  const navigate = useNavigate();

  console.log("influencerData", influencerData);

  const [clickedRow, setClickedRow] = useState(null);

  const handleRowClick = (row) => {
    setClickedRow(row);
    alert(row);
    console.log(clickedRow);
  };

  // const getInfluencers = async () => {
  //     setLoading(true);

  //     // Ensure collectionData and influencers_id property exist and is not null
  //     if (collectionData && collectionData.influencers_id) {
  //         const collectionInfluencers = JSON.parse(collectionData.influencers_id);

  //         console.log("collectionInfluencers Arr", collectionInfluencers);

  //         const users = await getAllInfluencers();

  //         const influencers = users.filter(influencer => collectionInfluencers.includes(influencer.id));

  //         console.log(influencers);

  //         console.log("users", users);

  //         setData(influencers);
  //     } else {
  //         // Handle the case where influencers_id is null or undefined
  //         console.error("Collection influencers data is null or undefined");
  //         // setData([])
  //     }

  //     setLoading(false);
  // };

  const getCollectionUsingID = async () => {};

  const CustomAccount = ({ row }) => {
    // Encryption
    // Base64 encode function
    const encodeId = (id) => btoa(id);
    const encodedId = encodeId(row.id.toString());

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {row.influencer_data.photo_link === "" ||
        row.influencer_data.photo_link == null ? (
          <Avatar sx={{ bgcolor: "orange" }}>
            {row.influencer_data.name.charAt(0)}
            {/* row.influencer_data.name.length - 1 */}
          </Avatar>
        ) : (
          <Avatar
            alt={row.influencer_data.name}
            src={row.influencer_data.photo_link}
          />
        )}

        <Link
          to={`/dashboard/find-influencers/profile/${encodedId}`}
          style={{
            marginLeft: 8,
            textDecoration: "none",
            color: "black",
            transition: "color 0.3s ease",
            "&:hover": {
              color: "blue", // Change to your desired hover color
            },
          }}
          onMouseEnter={() => {
            document.body.style.cursor = "pointer";
          }}
          onMouseLeave={() => {
            document.body.style.cursor = "default";
          }}
        >
          {row.influencer_data.name}
        </Link>
      </Box>
    );
  };

  const columns = [
    {
      name: "Sr. No",
      selector: (row, index) => index + 1,
      sortable: true,
      center: true,
    },
    {
      name: "Account",
      selector: (row) => row.influencer_data.name,
      sortable: true,
      width: "180px",
      cell: (row) => <CustomAccount row={row} />,
    },
    {
      name: "Subscribers",
      selector: (row) => row.influencer_data.subscriber,
      sortable: true,
      reorder: true,
      center: true,
      // width: '190px',
      // cell: row => <Customcategory row={row} />
    },
    {
      name: "Category",
      selector: (row) => row.influencer_data.influencer_category || "N/A",
      sortable: true,
      reorder: true,
      center: true,
      // width: '190px',
      // cell: row => <Customcategory row={row} />
    },
    {
      name: "ER(%)",
      selector: (row) => "1.84%",
      sortable: true,
      reorder: true,
      center: true,
      // width: '120px',
    },
    {
      name: "Avg. L/C",
      selector: (row) => row.likes,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomAvgLC row={row} />,
      // width: '120px',
      center: true,
    },
  ].map((column) => ({
    ...column,
    // style: {
    //     fontSize: '13px',
    // }
  }));
  return (
    <DataTable
      columns={columns}
      data={influencerData}
      pagination
      paginationPerPage={rowsPerPage}
      onChangeRowsPerPage={(currentRowsPerPage) => {
        setRowsPerPage(currentRowsPerPage);
      }}
      progressPending={loading}
      fixedHeader
      pointerOnHover
      fixedHeaderScrollHeight="500px"
      onRowClicked={handleRowClick}
      // customStyles={customStyles}
    />
  );
};

export default Table;
