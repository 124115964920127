import React, { useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  Button,
  Item,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import SettingsIcon from "@mui/icons-material/Settings";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  borderRadius: "10px",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "90vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: 4,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  alignItems: "start",
  justifyContent: "flex-start",
};

function convertDurationToSeconds(duration) {
  const parts = duration.split(":");
  const hours = parseInt(parts[0], 10) || 0;
  const minutes = parseInt(parts[1], 10) || 0;
  const seconds = parseInt(parts[2], 10) || 0;

  // Convert duration to total seconds
  return hours * 3600 + minutes * 60 + seconds;
}

function calculateVideoEr(userdata) {
  if (userdata) {
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    let totalLikes = 0;
    let totalComments = 0;
    let totalViews = 0;

    mediaArray?.forEach((media) => {
      const durationInSeconds = convertDurationToSeconds(media.duration);

      // Only add if media duration is greater than 60 seconds (1 minute)
      if (durationInSeconds > 60) {
        totalLikes += parseInt(media.likecount) || 0;
        totalComments += parseInt(media.commentcount) || 0;
        totalViews += parseInt(media.viewcount) || 0;
      }
    });

    totalLikes = totalLikes / mediaArray.length;
    totalComments = totalComments / mediaArray.length;
    totalViews = totalViews / mediaArray.length;

    if (totalViews > 0) {
      const engagementRate = ((totalLikes + totalComments) / totalViews) * 100;

      return engagementRate.toFixed(2) + "%"; // Return the final engagement rate rounded to 2 decimal places
    } else {
      return 0; // Return 0 if there are no views
    }
  }
}

function calculateShortsEr(userdata) {
  if (userdata) {
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    let totalLikes = 0;
    let totalComments = 0;
    let totalViews = 0;

    mediaArray.forEach((media) => {
      const durationInSeconds = convertDurationToSeconds(media.duration);

      console.log(durationInSeconds);

      // Only add if media duration is greater than 60 seconds (1 minute)
      if (durationInSeconds < 60) {
        totalLikes += parseInt(media.likecount) || 0;
        totalComments += parseInt(media.commentcount) || 0;
        totalViews += parseInt(media.viewcount) || 0;
      }
    });

    totalLikes = totalLikes / mediaArray.length;
    totalComments = totalComments / mediaArray.length;
    totalViews = totalViews / mediaArray.length;

    if (totalViews > 0) {
      const engagementRate = ((totalLikes + totalComments) / totalViews) * 100;
      return engagementRate.toFixed(2) + "%"; // Return the final engagement rate rounded to 2 decimal places
    } else {
      return 0; // Return 0 if there are no views
    }
  }
}

function calculateAvgViewsVideo(userdata) {
  if (userdata) {
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    let totalViews = 0;

    mediaArray.forEach((media) => {
      const durationInSeconds = convertDurationToSeconds(media.duration);

      // Only add if media duration is greater than 60 seconds (1 minute)
      if (durationInSeconds > 60) {
        totalViews += parseInt(media.viewcount) || 0;
      }
    });

    totalViews = totalViews / mediaArray.length;

    return parseInt(totalViews);
  }
}

function calculateAvgViewsShorts(userdata) {
  if (userdata) {
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    let totalViews = 0;

    mediaArray.forEach((media) => {
      const durationInSeconds = convertDurationToSeconds(media.duration);

      // Only add if media duration is greater than 60 seconds (1 minute)
      if (durationInSeconds < 60) {
        totalViews += parseInt(media.viewcount) || 0;
      }
    });

    totalViews = totalViews / mediaArray.length;

    return parseInt(totalViews);
  }
}

function calculateAvgPostperWeek(userdata) {
  if (userdata) {
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    // Parse the published_at dates and sort the media by published_at
    const dates = mediaArray
      .map((media) => new Date(media.published_at))
      .sort((a, b) => a - b);

    // Find the earliest and latest post dates
    const earliestDate = dates[0];
    const latestDate = dates[dates.length - 1];

    // Calculate the total number of weeks between the earliest and latest post dates
    const msInOneWeek = 1000 * 60 * 60 * 24 * 7;
    const totalWeeks = (latestDate - earliestDate) / msInOneWeek;

    let avgPostsPerWeek = 0;

    // Calculate the average posts per week
    if (totalWeeks > 0) {
      avgPostsPerWeek = mediaArray.length / totalWeeks;
    }

    return parseInt(avgPostsPerWeek) > 0 ||
      isNaN(parseInt(avgPostsPerWeek)) ||
      isFinite(parseInt(avgPostsPerWeek))
      ? parseInt(avgPostsPerWeek)
      : "N/A"; // Return average posts per week rounded to 2 decimal places
  }
}

function calculateAvgPostperMonth(userdata) {
  if (userdata) {
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    // Parse the published_at dates and sort the media by published_at
    const dates = mediaArray
      .map((media) => new Date(media.published_at))
      .sort((a, b) => a - b);

    // Find the earliest and latest post dates
    const earliestDate = dates[0];
    const latestDate = dates[dates.length - 1];

    // Calculate total months between the earliest and latest post dates
    const totalMonths =
      (latestDate.getFullYear() - earliestDate.getFullYear()) * 12 +
      (latestDate.getMonth() - earliestDate.getMonth());

    // If the difference in months is 0, avoid division by 0 and return total posts
    const avgPostsPerMonth =
      totalMonths > 0 ? mediaArray.length / totalMonths : mediaArray.length;

    return parseInt(avgPostsPerMonth);
  }
}

function calculateAvgComments(userdata) {
  if (userdata) {
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    let totalcomment = 0;

    mediaArray.forEach((media) => {
      totalcomment += parseInt(media.commentcount) || 0;
    });

    totalcomment = totalcomment / mediaArray.length;

    return parseInt(totalcomment);
  }
}

function calculateLanguage(userdata) {
  // Check if the user has media and if the media array is not empty
  if (userdata) {
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    let lang = [];

    mediaArray.forEach((media) => {
      // Only add the language if it's not already in the lang array
      if (!lang.includes(media.audio_language)) {
        lang.push(media.audio_language);
      }
    });

    return lang.join(", ");
  }
}

function calculatetophasttags(userdata) {
  if (userdata) {
    // Check if the user has media and if the media array is not empty
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    // Use a Set to collect unique hashtags
    let hashtagsSet = new Set();

    mediaArray.forEach((media) => {
      // Check if media has keywords
      if (media.hashtags) {
        // Split the keywords string by comma and trim whitespace
        let keywordsArray = media.hashtags
          .slice(1, media.hashtags.length - 1)
          .replace(/'/g, "")
          .split(",")
          .map((keyword) => keyword.trim())
          .filter((keyword) => keyword !== "");

        keywordsArray.forEach((keyword) => {
          hashtagsSet.add(keyword); // Add each keyword to the Set
        });
      }
    });

    // Convert the Set to an array and limit to 5
    return Array.from(hashtagsSet).slice(0, 5).join(", ");
  }
}

function calculatementions(userdata) {
  if (userdata) {
    // Check if the user has media and if the media array is not empty
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    // Use a Set to collect unique hashtags
    let hashtagsSet = new Set();

    mediaArray.forEach((media) => {
      // Check if media has keywords
      let keywordsArray = media.tags
        .slice(1, media.tags.length - 1)
        .replace(/'/g, "")
        .split(",")
        .map((keyword) => keyword.trim())
        .filter((keyword) => keyword !== "");

      keywordsArray.forEach((keyword) => {
        hashtagsSet.add(keyword); // Add each keyword to the Set
      });
    });

    // Convert the Set to an array and limit to 5
    return Array.from(hashtagsSet).slice(0, 5).join(", ");
  }
}

function calculateinterests(userdata) {
  if (userdata) {
    // Check if the user has media and if the media array is not empty
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];

    // Use a Set to collect unique hashtags
    let hashtagsSet = new Set();

    mediaArray.forEach((media) => {
      // Check if media has keywords
      if (media.category) {
        hashtagsSet.add(media.category);
      }
    });
    // Convert the Set to an array and limit to 5
    return Array.from(hashtagsSet).slice(0, 5).join(", ");
  }
}

function calculateTopContentType(userdata) {
  if (userdata) {
    // Check if the user has media and if the media array is not empty
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    let mediaArray = userdata["media"];
    let reelsCount = 0;
    let videosCount = 0;

    mediaArray.forEach((media) => {
      if (media.duration) {
        let durationInSeconds = convertDurationToSeconds(media?.duration);
        // Count as reel if under 60 seconds, otherwise count as video
        if (durationInSeconds < 60) {
          reelsCount++;
        } else {
          videosCount++;
        }
      }
    });

    // Determine content type based on which has a higher count
    if (reelsCount > videosCount) {
      return "Reels";
    } else if (videosCount > reelsCount) {
      return "Long Videos";
    } else {
      return "Equal Reels and Videos";
    }
  }
}

function calculateTopMedia(userdata) {
  if (userdata) {
    // Check if the user has media and if the media array is not empty
    if (!userdata["media"] || userdata["media"].length <= 0) {
      return "No Media History";
    }

    // let mediaArray = userdata["media"];
    // const topMedia = mediaArray.sort((a, b) => {});

    const mediaArray = userdata.media
      .sort((a, b) => b.viewcount - a.viewcount)
      .map((media) => ({
        likes: media.likecount,
        comments: media.commentcount,
        views: media.viewcount,
        link: media.default_thumnail_link,
        title: media.title,
        media_id: media.media_id,
        duration: convertDurationToSeconds(media.duration),
        date: new Date(media.published_at).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
      }));

    // .forEach((media) => {
    //   // Initialize or update topMedia based on view count
    //   if (
    //     topMedia ||
    //     (media.viewcount && media.viewcount > topMedia.viewcount)
    //   ) {
    //     topMedia = media;
    //   }
    // });
    console.log("mediaArray: ", mediaArray);

    // Return URL of the media with the highest views, or a message if none found
    return mediaArray?.length >= 10 ? mediaArray.slice(0, 10) : mediaArray;
  }
}

function calculateSponsoredPost(userdata) {
  if (userdata) {
    // Check if the user has media and if the media array is not empty
    if (!userdata.media || userdata.media.length === 0) {
      return "No Media History";
    }

    // Define the keywords to search for
    const keywords = [
      "ad",
      "sponsored",
      "paidpartnership",
      "collab",
      "paid_collab",
    ];

    // Filter and map media items to return only sponsored posts with specific fields
    const sponsoredMedia = userdata.media
      .filter((media) =>
        // Check if description contains any of the keywords (case insensitive)
        keywords.some(
          (keyword) =>
            media.description &&
            media.description.toLowerCase().includes(keyword.toLowerCase())
        )
      )
      .map((media) => ({
        likes: media.likecount,
        comments: media.commentcount,
        date: new Date(media.published_at).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
        link: media.default_thumnail_link,
      }));

    return sponsoredMedia.length > 0 ? sponsoredMedia : [];
  }
}

function calculateRecentPost(userdata) {
  if (userdata) {
    // Check if the user has media and if the media array is not empty
    if (!userdata.media || userdata.media.length === 0) {
      return "No Media History";
    }

    const recentPosts = userdata.media
      .sort((a, b) => new Date(b.published_at) - new Date(a.published_at)) // Sort by published_at, most recent first
      .map((media) => ({
        likes: media.likecount,
        comments: media.commentcount,
        views: media.viewcount,
        link: media.default_thumnail_link,
        title: media.title,
        media_id: media.media_id,
        duration: convertDurationToSeconds(media.duration),
        date: new Date(media.published_at).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
      }));

    console.log("-----------------", userdata);

    return recentPosts;
  }
}

const ageGroup = [
  { min: 13, max: 17 },
  { min: 18, max: 24 },
  { min: 25, max: 34 },
  { min: 35, max: 44 },
  { min: 45, max: 54 },
  { min: 55, max: 64 },
  { min: 65, max: null },
];

function calculateAgeGroup(userdata) {
  for (const group of ageGroup) {
    if (userdata?.age >= group.min && userdata?.age <= group.max)
      return `${group.min} - ${group.max}`;
    else if (group.max == null) return `${group.min}+`;
  }
  return `Age not found`;
}

function GearDropdown1({ can, setCan, userdata }) {
  const [open, setOpen] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [priority, setPriority] = useState("");

  const AccordionOptionsYoutube = [
    {
      name: "Overview",
      titles: [
        {
          name: "Engagement",
          options: [
            {
              name: "Videos Engagement Rate",
              elementType: "text",
              key: "Engagement Rate",
              value: calculateVideoEr(userdata),
            },
            {
              name: "Shorts Engagement Rate",
              elementType: "text",
              key: "Shorts Engagement Rate",
              value: calculateShortsEr(userdata),
            },
            {
              name: "Average Number of Views on Videos",
              elementType: "text",
              key: "Average Number of Views on Videos",
              value: calculateAvgViewsVideo(userdata),
            },
            {
              name: "Average Number of Views on Shorts",
              elementType: "text",
              key: "Average Number of Views on Shorts",
              value: calculateAvgViewsShorts(userdata),
            },
            {
              name: "Average Number of Comments",
              elementType: "text",
              key: "Average Number of Comments",
              value: calculateAvgComments(userdata),
            },
            {
              name: "Average Posts Per Week",
              elementType: "text",
              key: "Average Posts Per Week",
              value: calculateAvgPostperWeek(userdata),
            },
            {
              name: "Average Posts Per Month",
              elementType: "text",
              key: "Average Posts Per Month",
              value: calculateAvgPostperMonth(userdata),
            },
          ],
        },
        {
          name: "More Details",
          options: [
            // {
            //   name: "Percentage of Channel Subscribers Credibility",
            //   elementType: "text", key: "Percentage of Channel Subscribers Credibility",
            //   value: "Not found"
            // },
            {
              name: "Age Group of the Channel",
              elementType: "text",
              key: "Age Group of the Channel",
              value: calculateAgeGroup(userdata),
            },
            {
              name: "Language of the Channel",
              elementType: "text",
              key: "Language of the Channel",
              value: calculateLanguage(userdata),
            },
            // { name: "Credibility of Channel Viewers", elementType: "text", key: "Channel Audience Viewers Cedibility Score", value: "Not Found" },
            {
              name: "Channel Content Count",
              elementType: "text",
              key: "Channel content count",
              value: userdata?.video_count,
            },
            {
              name: "Performance of Sponsored Videos vs Organic Videos",
              elementType: "text",
              key: "Performance of sponsored videos vs organic videos",
              value: "Not Found",
            },
          ],
        },
        {
          name: "Location",
          options: [
            {
              name: "Channel Location Country",
              elementType: "text",
              key: "Channel Location Country",
              value: userdata?.country,
            },
            {
              name: "Channel Location State",
              elementType: "text",
              key: "Channel Location State",
              value: userdata?.state ? userdata.state : "N/A",
            },
            {
              name: "Channel Location City",
              elementType: "text",
              key: "Channel Location City",
              value: userdata?.city,
            },
          ],
        },
        // {
        //   name: "Growth",
        //   options: [
        //     { name: "Subscribers Growth Rate", elementType: "fullgraph" },
        //     { name: "Channel Views Growth Rate", elementType: "fullgraph" },
        //     {
        //       name: "Brand Mentions (In the Past 3 Months)",
        //       elementType: "mention",
        //     },
        //   ],
        // },
      ],
    },
    {
      name: "Content",
      titles: [
        {
          name: "Top",
          options: [
            {
              name: "List of top hashtags used by the creator",
              elementType: "text",
              key: "List of top hashtags used by the creator",
              value: calculatetophasttags(userdata),
            },
            // {
            //   name: "List of top mentions used by the creator",
            //   elementType: "text",
            //   key: "List of top mentions used by the creator",
            //   value: calculatementions(userdata),
            // },
            {
              name: "List of top interests of the creator",
              elementType: "text",
              key: "List of top interests of the creator",
              value: calculateinterests(userdata),
            },
            // {
            //   name: "Name of the creator brand affinity",
            //   elementType: "text",
            //   key: "Name of the creator brand affinity",
            // },
            {
              name: "Channel Top Contents Type",
              elementType: "text",
              key: "",
              value: calculateTopContentType(userdata),
            },
            // {
            //   name: "Channel Top Contents URL",
            //   elementType: "text",
            //   key: "",
            //   value:
            //     "https://youtube.com/video/" +
            //     calculateTopMedia(userdata)?.media_id,
            // },
            // {
            //   name: "Channel Top Contents Description",
            //   elementType: "text",
            //   key: "",
            //   value: calculateTopMedia(userdata)?.description,
            // },
            // {
            //   name: "Channel Top Contents Thumbnail URL",
            //   elementType: "text",
            //   key: "",
            //   value: calculateTopMedia(userdata)?.high_thumnail_link,
            // },
            // {
            //   name: "Channel Top Contents",
            //   elementType: "carousel",
            //   key: "",
            //   value: calculateTopMedia(userdata),
            // },
            {
              name: "Channel Top Contents Engagement Views Count",
              elementType: "text",
              key: "",
              value:
                calculateTopMedia(userdata) &&
                calculateTopMedia(userdata)[0]?.views,
            },
            {
              name: "Channel Top Contents Engagement Like Count",
              elementType: "text",
              key: "",
              value:
                calculateTopMedia(userdata) &&
                calculateTopMedia(userdata)[0]?.likes,
            },
            {
              name: "Channel Top Contents Engagement Comment Count",
              elementType: "text",
              key: "",
              value:
                calculateTopMedia(userdata) &&
                calculateTopMedia(userdata)[0]?.comments,
            },
            // { name: "", elementType: "carousel" },
          ],
        },
        // {
        //   name: "Recent",
        //   options: [
        //     {
        //       name: "",
        //       elementType: "carousel",
        //       value: calculateRecentPost(userdata),
        //     },
        //   ],
        // },
        // {
        //   name: "Sponsored",
        //   options: [{ name: "", elementType: "carousel", value: calculateSponsoredPost(userdata) }],
        // },
      ],
    },
  ];

  const [toggleStates, setToggleStates] = useState({
    "Videos ER":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Shorts ER":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Avg Views on Videos":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Avg Views on Shorts":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Avg Comments":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Avg Posts/Week":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Avg Posts/Month":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
  });

  const [toggleStates2, setToggleStates2] = useState({
    "Age Group":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    Language:
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Content Count":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Performance of Sponsored vs Organic Videos":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
  });

  const [toggleStates3, setToggleStates3] = useState({
    "Channel Location Country":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Channel Location State":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Channel Location City":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
  });

  const [toggleStates4, setToggleStates4] = useState({
    "List of top hashtags used by the creator":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,

    "List of top interests of the creator":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Channel Top Contents Type":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Channel Top Contents Engagement Views Count":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Channel Top Contents Engagement Like Count":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
    "Channel Top Contents Engagement Comment Count":
      priority == "low"
        ? true
        : priority == "medium"
        ? true
        : priority == "high"
        ? true
        : false,
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleDropdownChange = (event) => {
    const priority = event.target.value;
    setDropdownValue(priority.toLowerCase());
    setCan(priority);

    switch (priority) {
      case "low":
        setToggleStates({
          "Videos ER": true,
          "Shorts ER": false,
          "Avg Views on Videos": true,
          "Avg Views on Shorts": false,
          "Avg Comments": true,
          "Avg Posts/Week": false,
          "Avg Posts/Month": false,
        });
        setToggleStates2({
          "Age Group": false,
          Language: true,
          "Content Count": true,
          "Performance of Sponsored vs Organic Videos": false,
        });
        setToggleStates3({
          "Channel Location Country": true,
          "Channel Location State": false,
          "Channel Location City": false,
        });
        setToggleStates4({
          "List of top hashtags used by the creator": true,
          "List of top interests of the creator": true,
          "Channel Top Contents Type": false,
          "Channel Top Contents Engagement Views Count": true,
          "Channel Top Contents Engagement Like Count": false,
          "Channel Top Contents Engagement Comment Count": false,
        });
        break;
      case "medium":
        setToggleStates({
          "Videos ER": true,
          "Shorts ER": false,
          "Avg Views on Videos": true,
          "Avg Views on Shorts": false,
          "Avg Comments": true,
          "Avg Posts/Week": false,
          "Avg Posts/Month": true,
        });
        setToggleStates2({
          "Age Group": true,
          Language: true,
          "Content Count": true,
          "Performance of Sponsored vs Organic Videos": false,
        });
        setToggleStates3({
          "Channel Location Country": true,
          "Channel Location State": false,
          "Channel Location City": true,
        });
        setToggleStates4({
          "List of top hashtags used by the creator": true,
          "List of top interests of the creator": true,
          "Channel Top Contents Type": false,
          "Channel Top Contents Engagement Views Count": true,
          "Channel Top Contents Engagement Like Count": false,
          "Channel Top Contents Engagement Comment Count": true,
        });
        break;
      case "high":
        setToggleStates({
          "Videos ER": true,
          "Shorts ER": true,
          "Avg Views on Videos": true,
          "Avg Views on Shorts": true,
          "Avg Comments": true,
          "Avg Posts/Week": true,
          "Avg Posts/Month": true,
        });
        setToggleStates2({
          "Age Group": true,
          Language: true,
          "Content Count": true,
          "Performance of Sponsored vs Organic Videos": true,
        });
        setToggleStates3({
          "Channel Location Country": true,
          "Channel Location State": true,
          "Channel Location City": true,
        });
        setToggleStates4({
          "List of top hashtags used by the creator": true,
          "List of top interests of the creator": true,
          "Channel Top Contents Type": true,
          "Channel Top Contents Engagement Views Count": true,
          "Channel Top Contents Engagement Like Count": true,
          "Channel Top Contents Engagement Comment Count": true,
        });
        break;
      default:
        break;
    }
  };

  const handleToggleChange = (event) => {
    setToggleStates({
      ...toggleStates,
      [event.target.name]: event.target.checked,
    });
  };

  const handleToggleChange2 = (event) => {
    setToggleStates2({
      ...toggleStates2,
      [event.target.name]: event.target.checked,
    });
  };
  const handleToggleChange3 = (event) => {
    setToggleStates3({
      ...toggleStates3,
      [event.target.name]: event.target.checked,
    });
  };
  const handleToggleChange4 = (event) => {
    setToggleStates4({
      ...toggleStates4,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <div>
      <IconButton onClick={handleOpen}>
        <SettingsIcon />
      </IconButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        sx={{ height: "100%", overflowY: "auto" }}
      >
        <Box sx={style}>
          <h2 id="modal-title">Hide Custom Fields</h2>
          <FormControl
            fullWidth
            sx={{
              border: "#3dbf73ff",
              color: "#3dbf73ff",
              "&:focus": { border: "#3dbf73ff", color: "#3dbf73ff" },
              "&:active": { border: "#3dbf73ff", color: "#3dbf73ff" },
              "&:outline": { border: "#3dbf73ff", color: "#3dbf73ff" },
            }}
          >
            <InputLabel
              sx={{ border: "#3dbf73ff", color: "#3dbf73ff" }}
              id="dropdown-label"
            >
              Priority
            </InputLabel>
            <Select
              labelId="dropdown-label"
              id="dropdown"
              value={dropdownValue}
              label="Priority"
              onChange={handleDropdownChange}
              sx={{
                border: "#3dbf73ff",
                color: "#3dbf73ff",
              }}
            >
              <MenuItem sx={{ fontWeight: "bold" }} value="low">
                Low
              </MenuItem>
              <MenuItem sx={{ fontWeight: "bold" }} value="medium">
                Medium
              </MenuItem>
              <MenuItem sx={{ fontWeight: "bold" }} value="high">
                High
              </MenuItem>
            </Select>
          </FormControl>
          <Box sx={{ width: "100%" }}>
            <Typography variant="h5">
              {AccordionOptionsYoutube[0].titles[0].name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "7px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {Object.keys(toggleStates).map((key, index) => {
                  if (index < 5)
                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Switch
                            checked={toggleStates[key]}
                            onChange={handleToggleChange}
                            name={key}
                            color="success"
                            sx={{
                              color: "#3dbf73ff",
                              "&:focus": {
                                color: "#3dbf73ff",
                              },
                              "&:active": {
                                color: "#3dbf73ff",
                              },
                            }}
                          />
                        }
                        label={
                          key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1")
                        }
                      />
                    );
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {Object.keys(toggleStates).map((key, index) => {
                  if (index >= 5)
                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Switch
                            checked={toggleStates[key]}
                            onChange={handleToggleChange}
                            name={key}
                            color="success"
                            sx={{
                              color: "#3dbf73ff",
                              "&:focus": {
                                color: "#3dbf73ff",
                              },
                              "&:active": {
                                color: "#3dbf73ff",
                              },
                            }}
                          />
                        }
                        label={
                          key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1")
                        }
                      />
                    );
                })}
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography variant="h5">
              {AccordionOptionsYoutube[0].titles[1].name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "7px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {Object.keys(toggleStates2).map((key, index) => {
                  if (index < 5)
                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Switch
                            checked={toggleStates2[key]}
                            onChange={handleToggleChange2}
                            name={key}
                            color="success"
                            sx={{
                              color: "#3dbf73ff",
                              "&:focus": {
                                color: "#3dbf73ff",
                              },
                              "&:active": {
                                color: "#3dbf73ff",
                              },
                            }}
                          />
                        }
                        label={
                          key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1")
                        }
                      />
                    );
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {Object.keys(toggleStates2).map((key, index) => {
                  if (index >= 5)
                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Switch
                            checked={toggleStates2[key]}
                            onChange={handleToggleChange2}
                            name={key}
                            color="success"
                            sx={{
                              color: "#3dbf73ff",
                              "&:focus": {
                                color: "#3dbf73ff",
                              },
                              "&:active": {
                                color: "#3dbf73ff",
                              },
                            }}
                          />
                        }
                        label={
                          key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1")
                        }
                      />
                    );
                })}
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography variant="h5">
              {AccordionOptionsYoutube[0].titles[2].name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "7px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {Object.keys(toggleStates3).map((key, index) => {
                  if (index < 5)
                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Switch
                            checked={toggleStates3[key]}
                            onChange={handleToggleChange3}
                            name={key}
                            color="success"
                            sx={{
                              color: "#3dbf73ff",
                              "&:focus": {
                                color: "#3dbf73ff",
                              },
                              "&:active": {
                                color: "#3dbf73ff",
                              },
                            }}
                          />
                        }
                        label={
                          key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1")
                        }
                      />
                    );
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {Object.keys(toggleStates3).map((key, index) => {
                  if (index >= 5)
                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Switch
                            checked={toggleStates3[key]}
                            onChange={handleToggleChange3}
                            name={key}
                            color="success"
                            sx={{
                              color: "#3dbf73ff",
                              "&:focus": {
                                color: "#3dbf73ff",
                              },
                              "&:active": {
                                color: "#3dbf73ff",
                              },
                            }}
                          />
                        }
                        label={
                          key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1")
                        }
                      />
                    );
                })}
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <Typography variant="h5">
              {AccordionOptionsYoutube[1].titles[0].name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                gap: "7px",
                alignItems: "flex-start",
                justifyContent: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {Object.keys(toggleStates4).map((key, index) => {
                  if (index < 5)
                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Switch
                            checked={toggleStates4[key]}
                            onChange={handleToggleChange4}
                            name={key}
                            color="success"
                            sx={{
                              color: "#3dbf73ff",
                              "&:focus": {
                                color: "#3dbf73ff",
                              },
                              "&:active": {
                                color: "#3dbf73ff",
                              },
                            }}
                          />
                        }
                        label={
                          key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1")
                        }
                      />
                    );
                })}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                {Object.keys(toggleStates4).map((key, index) => {
                  if (index >= 5)
                    return (
                      <FormControlLabel
                        key={key}
                        control={
                          <Switch
                            checked={toggleStates4[key]}
                            onChange={handleToggleChange4}
                            name={key}
                            color="success"
                            sx={{
                              color: "#3dbf73ff",
                              "&:focus": {
                                color: "#3dbf73ff",
                              },
                              "&:active": {
                                color: "#3dbf73ff",
                              },
                            }}
                          />
                        }
                        label={
                          key.charAt(0).toUpperCase() +
                          key.slice(1).replace(/([A-Z])/g, " $1")
                        }
                      />
                    );
                })}
              </Box>
            </Box>
          </Box>

          {/* <Box
            mt={2}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "7px",
              alignItems: "start",
              justifyContent: "center",
            }}
          >
            {Object.keys(toggleStates).map((key) => (
              <FormControlLabel
                key={key}
                control={
                  <Switch
                    checked={toggleStates[key]}
                    onChange={handleToggleChange}
                    name={key}
                    color="success"
                    sx={{
                      color: "#3dbf73ff",
                      "&:focus": {
                        color: "#3dbf73ff",
                      },
                      "&:active": {
                        color: "#3dbf73ff",
                      },
                    }}
                  />
                }
                label={
                  key.charAt(0).toUpperCase() +
                  key.slice(1).replace(/([A-Z])/g, " $1")
                }
              />
            ))}
          </Box> */}
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#3dbf73ff",
              "&:hover": {
                backgroundColor: "#3dbf73ff",
              },
            }}
            onClick={handleClose}
            fullWidth
          >
            Share
          </Button>
        </Box>
      </Modal>
    </div>
  );
}

export default GearDropdown1;
