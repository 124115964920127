import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Checkbox,
  Container,
  Grid2,
  Menu,
  MenuItem,
  styled,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import CreateCampaignTabs from "../subcomponents/campaign/CreateCampaignTabs";
import PropTypes from "prop-types";
import { useState } from "react";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import DescriptionIcon from "@mui/icons-material/Description";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import TextsmsIcon from "@mui/icons-material/Textsms";
import DeleteIcon from "@mui/icons-material/Delete";
import CampaignDataTable from "../subcomponents/campaign/CampaignDataTable";
import { deliverablesData } from "./campaigndata";
import OverviewTab from "../subcomponents/campaign/OverviewTab";

const img1 =
  "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcToQeYeClZMrqqq2aaTT2UdbuWtVktz8jQ9gw&usqp=CAU";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const Btn = styled(Button)(({ theme }) => ({
  color: "#FFF",
  textTransform: "none",
  padding: "5px 15px",
  // marginTop: "1rem",
  borderRadius: 20,
  background: "#3DBE72",
  boxShadow: "none",
  "&:hover": {
    background: "#3DBE72",
    boxShadow: "none",
  },
}));

const CustomTab = styled(Tab)(({ isActive }) => ({
  border: isActive ? `1px solid #3DBE72` : "none", // Change the color as needed
  borderRadius: 5,
  color: isActive ? "#3DBE72" : "#000", // Change text color for active tab
  "&:hover": {
    color: "#3DBE72", // Change text color on hover
  },
}));

const CustomTab1 = styled(Tab)(({ isActive }) => ({
  backgroundColor: isActive ? "#3DBE72" : "#fff", // Custom background color for active tab
  color: isActive ? "white !important" : "#000", // Custom text color for active tab
  borderRadius: isActive ? 30 : "0px", // Custom border radius for active tab
  "&:hover": {
    backgroundColor: isActive ? "#3DBE72" : "#f0f0f0", // Change background color on hover
    color: "#3DBE72", // Change text color on hover
  },
  minHeight: "30px !important",
  height: "30px !important",
  maxHeight: "30px !important",
  marginLeft: 3,
  marginRight: 3,
  paddingTop: 2,
  paddingBottom: 2,
}));

function formatNumber(num) {
  try {
    // Convert the input to a number in case it's a string
    const number = Number(num);

    // Check if the conversion was successful
    if (isNaN(number)) {
      throw new Error("Invalid number");
    }

    // Format the number based on its value
    if (number >= 1e6) {
      return (number / 1e6).toFixed(1) + "M"; // Convert to millions
    } else if (number >= 1e3) {
      return (number / 1e3).toFixed(1) + "K"; // Convert to thousands
    } else {
      return number.toString(); // Return the number as is
    }
  } catch (error) {
    return "error";
  }
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ padding: 0, width: "100%", margin: 0 }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            py: 3,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            paddingX: 0,
            marginX: 0,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `report-tab-${index}`,
    "aria-controls": `report-tabpanel-${index}`,
  };
}

const CustomAccount = ({ row }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 2, py: 1 }}>
    <Avatar alt={row.name} src={row.platform.logo} />
    <Typography sx={{ display: "flex", flexDirection: "column" }}>
      <span style={{ fontWeight: 550 }}>{row.name}</span>
      <span style={{ fontSize: 13 }}>{row?.handle}</span>
    </Typography>
  </Box>
);

const CustomDeliverables = ({ row }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
    <YouTubeIcon color="grey" sx={{ color: "grey", fontSize: "15px" }} />
    <Typography sx={{ fontSize: "14px" }}>
      <span style={{ fontWeight: 550 }}>8</span> of 8
    </Typography>
  </Box>
);

const CustomPlatform = ({ row }) => (
  <Box>
    <Avatar alt={row.name} src={row.platform.logo} sizes="20" />
  </Box>
);
const CustomVerified = ({ row }) => (
  <span style={{ marginLeft: "auto", marginRight: "auto" }}>
    {row?.isVerified ? (
      <CheckCircleIcon sx={{ color: "#75f94c" }} />
    ) : (
      <CancelIcon sx={{ color: "#eb3223" }} />
    )}
  </span>
);
const CustomReport = ({ row }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
    <DescriptionIcon
      color="action"
      sx={{ cursor: "pointer" }}
      onClick={() => console.log(row, "Report")}
    />
  </Box>
);
const CustomActions1 = ({ row }) => (
  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
    <AddCircleIcon
      color="action"
      // sx={{ cursor: "pointer" }}
      onClick={() => console.log(row, "Hide row")}
    />
    <TextsmsIcon
      color="action"
      // sx={{ cursor: "pointer" }}
      onClick={() => console.log(row, "Hide row")}
    />
    <DeleteIcon
      color="action"
      // sx={{ cursor: "pointer" }}
      onClick={() => console.log(row, "Hide row")}
    />
  </Box>
);

const Report = () => {
  const [value, setValue] = useState(0);

  const [selectedSortOption, setSelectedSortOption] = useState("video views");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [anchorElContentPosted, setAnchorElContentPosted] = useState(null);

  const openContentPosted = Boolean(anchorElContentPosted);

  // const handleRowClick = (row) => {
  //   setSelectedRow(row);
  //   setDialogOpen(true);
  // };

  const handleContentPostedClick = (event) => {
    setAnchorElContentPosted(event.currentTarget);
  };

  const handleContentPostedClose = (newStatus) => {
    setAnchorElContentPosted(null);
  };

  const handleSortChange = (event) => {
    setSelectedSortOption(event.target.value);
  };

  const sortOptions = [
    { value: "video views", label: "Video views" },
    { value: "engagement", label: "Engagement" },
    { value: "cpv", label: "CPV" },
    { value: "cpe", label: "CPE" },
  ];

  const contentPostedArr = [
    "All",
    "None",
    "1 Post",
    "2 Posts",
    "3 Posts",
    "4 Posts",
    "5 Posts",
    "6 Posts",
    "7 Posts",
    "8 Posts",
    "9 Posts",
    "10 Posts",
  ];

  const deliverableColumn = [
    {
      name: "S.NO.",
      selector: (row) => row.id,
      center: true,
      minWidth: "50px",
      maxWidth: "auto",
      width: "100px",
      reorder: true,
      cell: (row, rowIndex) => {
        return rowIndex + 1;
      },
    },
    {
      name: "Influencer Profile",
      selector: (row) => row.name,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomAccount row={row} />,
      width: "180px",
    },
    {
      name: "Deliverables",
      selector: (row) => row.deliverables,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomDeliverables row={row} />,
      width: "150px",
    },
    {
      name: "Total Cost",
      selector: (row) => row.total_cost,
      sortable: true,
      reorder: true,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.total_cost)}
        </Typography>
      ),
      width: "120px",
    },
    {
      name: "Likes",
      selector: (row) => row.likes,
      sortable: true,
      reorder: true,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.likes)}
        </Typography>
      ),
      width: "100px",
    },
    {
      name: "Comments",
      selector: (row) => row.comments,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.comments)}
        </Typography>
      ),
      width: "130px",
    },
    {
      name: "Video Views",
      selector: (row) => row.video_views,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.video_views)}
        </Typography>
      ),
      width: "140px",
    },
    {
      name: "CPV",
      selector: (row) => row.cpv,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>{row.cpv}</Typography>
      ),
      width: "80px",
    },
    {
      name: "CPE",
      selector: (row) => row.cpe,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>{row.cpe}</Typography>
      ),
      width: "80px",
    },
    {
      name: "Engagement Rate",
      selector: (row) => row.engagement_rate,
      sortable: true,
      reorder: false,
      cell: (row) => (
        <Typography sx={{ fontWeight: 500 }}>
          {formatNumber(row.engagement_rate)}
        </Typography>
      ),
      width: "max-content",
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
      headerAlign: "center",
    },
  }));

  const influencersColumn = [
    {
      name: "S.NO.",
      selector: (row) => row.id,
      center: true,
      minWidth: "50px",
      maxWidth: "auto",
      width: "fit-content",
      reorder: true,
      cell: (row, rowIndex) => {
        return (
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={false}
              onClick={(e) => {
                console.log("Clicked");
              }}
            />
          </Box>
        );
      },
    },
    {
      name: "Influencer Name",
      selector: (row) => row.brand,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomAccount row={row} />,
      width: "180px",
    },
    {
      name: "Platform",
      selector: (row) => row.platform.name,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomPlatform row={row} />,
      width: "120px",
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Followers",
      selector: (row) => row.followers,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Engagement",
      selector: (row) => row.engagement_rate,
      sortable: true,
      reorder: true,
      width: "140px",
    },
    {
      name: "Avg. Comments",
      selector: (row) => row.avg_comments,
      sortable: true,
      reorder: true,
      width: "180px",
    },
    {
      name: "Avg. Likes",
      selector: (row) => row.avg_likes,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
      reorder: true,
    },
    {
      name: "Verified",
      selector: (row) => row.isVerified,
      sortable: true,
      reorder: true,
      cell: (row) => <CustomVerified row={row} />,
    },
    {
      name: "Added date",
      selector: (row) => row.added_date,
      sortable: true,
      reorder: true,
      width: "140px",
    },
    {
      name: "% Male",
      selector: (row) => row.male_percentage,
      sortable: true,
      reorder: true,
    },
    {
      name: "% Female",
      selector: (row) => row.female_percentage,
      sortable: true,
      reorder: true,
      width: "120px",
    },
    {
      name: "Report",
      sortable: true,
      reorder: true,
      cell: (row) => <CustomReport row={row} />,
    },
    {
      name: " ",
      sortable: false,
      reorder: false,
      cell: (row) => <CustomActions1 row={row} />,
      width: "140px",
    },
  ].map((column) => ({
    ...column,
    style: {
      fontSize: "13px",
      headerAlign: "center",
    },
  }));

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: 2,
        paddingBottom: 5,
        paddingLeft: 3,
        paddingRight: 0,
      }}
    >
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            ml: "1rem",
            mb: "1rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.5rem",
              color: "#3dbf73ff",
              fontWeight: "bold",
              width: "25%",
            }}
          >
            Campaign Report
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
          }}
        >
          <Box
            sx={{
              ml: "1rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Avatar
              key={1}
              sx={{ width: 80, height: 80 }}
              alt={"default"}
              src={img1}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                width: "100%",
                mt: "1rem",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  ml: "3rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    pr: 10,
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                    }}
                  >
                    Campaign Name
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      fontSize: "1.5rem",
                    }}
                  >
                    12.3M
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontWeight: "thun",
                    fontSize: "1.2rem",
                    color: "grey",
                  }}
                >
                  25 Influencers
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "thun",
                    fontSize: "1rem",
                    color: "grey",
                  }}
                >
                  Campaign description
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Grid2
        container
        size={{ sm: 12 }}
        sx={{ paddingRight: 0, width: "100%", marginTop: 5 }}
      >
        <Box sx={{ width: "100%" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            TabIndicatorProps={{
              style: {
                backgroundColor: "#3dbe72",
                height: "2px",
              },
            }}
          >
            <Tab label="Overview" {...a11yProps(0)} />
            <Tab label="Influencers" {...a11yProps(1)} />
          </Tabs>
        </Box>

        {/* Overview tab  */}
        <CustomTabPanel value={value} index={0}>
          <OverviewTab />
        </CustomTabPanel>

        {/* Influencers tab  */}
        <CustomTabPanel value={value} index={1}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                width: "100%",
                justifyContent: "flex-end",
                px: 2,
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  id="contents-posted-button"
                  aria-controls={
                    openContentPosted ? "contents-posted" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openContentPosted ? "true" : undefined}
                  onClick={handleContentPostedClick}
                  sx={{
                    color: "#000",
                    borderRadius: 3,
                    borderColor: "#666666",
                  }}
                >
                  Contents Posted
                </Button>
                <Menu
                  id="contents-posted"
                  anchorEl={anchorElContentPosted}
                  open={openContentPosted}
                  onClose={handleContentPostedClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  sx={{
                    "& .MuiList-root": {
                      width: "150px",
                      height: "250px",
                      overflowY: "auto",
                    },
                  }}
                >
                  {contentPostedArr.map((content_posted) => {
                    return (
                      <MenuItem onClick={handleContentPostedClose}>
                        {content_posted}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "max-content",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Typography sx={{ fontSize: 14 }}>Sort by: </Typography>
                <TextField
                  id="outlined-select-sort"
                  select
                  // label="Select"
                  value={selectedSortOption}
                  onChange={handleSortChange}
                  sx={{
                    // bgcolor: "#dde8f8",
                    // borderRadius: 3,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        border: "none", // Remove the border
                      },
                      "&:hover fieldset": {
                        border: "none", // Remove the border on hover
                      },
                      "&.Mui-focused fieldset": {
                        border: "none", // Remove the border when focused
                      },
                    },
                  }}
                  size="small"
                  variant="outlined"
                >
                  {sortOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Box>
            <CampaignDataTable
              columns={influencersColumn}
              // onRowClick={handleRowClick}
              rowData={deliverablesData}
            />
          </Box>
        </CustomTabPanel>
      </Grid2>
    </Box>
  );
};

export default Report;
