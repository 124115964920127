import { Box, Button, Stack, styled } from "@mui/material";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import Header from "./subcomponents/findInfluencer/FIHeader";
import SearchBar from "./subcomponents/findInfluencer/SearchBar";
import AdvFilterDrawer2 from "./subcomponents/findInfluencer/AdvFilterDrawer2";
import InfluencersDataTable from "./subcomponents/findInfluencer/InfluencersDataTable";
import { data } from "./subcomponents/findInfluencer/InfluencersData.js";

import axiosInstance from "../../service/axiosInstance.js";

// APIs
import { getAllInfluencersWithLimit } from "../../service/influencers/fetchInfluencersWithLimit.js";
import { getAllInfluencers } from "../../service/getAllApi.js";
import AdvFilterDrawerY from "./subcomponents/findInfluencer/AdvFilterDrawerY.jsx";
import { useSelector } from "react-redux";
import { getAllLocations } from "../../service/influencers/getAllLocations.js";
import FindInfluencerDatatable from "./dataTables/findInfluencerDatatable";
import YTAdvancedFilters from "./drawers/ytAdvancedFilters.jsx";
import { getAllCollection } from "../../service/collections api/getAllCollection.js";

const genderList = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Other", value: "Other" },
];

const verifiedList = [
  { label: "Verified", value: "Verified" },
  { label: "Not Verified", value: "Not Verified" },
  { label: "CH Verified", value: "Ch Verified" },
  { label: "All", value: "All" },
];

const contactInfo = [
  { label: "Has Personal Email", value: "personal email" },
  { label: "Has Business Email", value: "business email" },
  { label: "Has Phone", value: "phone" },
  { label: "Has Whatsapp", value: "whatsapps" },
];

const verifiedContact = [
  { label: "Has Verified Email", value: "email" },
  { label: "Has Verified Phone", value: "phone" },
  { label: "Has Verified Whatsapp", value: "whatsapp" },
];

const socialPlatforms = [
  { label: "Youtube", value: "youtube" },
  { label: "Instagram", value: "instagram" },
  { label: "Twitter", value: "twitter" },
  { label: "Tiktok", value: "tiktok" },
  { label: "Facebook", value: "facebook" },
  { label: "LinkedIn", value: "linkedin" },
  { label: "Linktree", value: "linktree" },
];

const inTime = [
  { label: "Days", value: "Days" },
  { label: "Weeks", value: "Weeks" },
  { label: "Months", value: "Months" },
  { label: "Years", value: "Years" },
];

const inType = [
  { label: "Days", value: "Days" },
  { label: "Posts", value: "Posts" },
];

const keywordsList = [
  { label: "Anywhere", value: "Anywhere" },
  { label: "In description", value: "In description" },
  { label: "In content", value: "In content" },
  { label: "In channel name", value: "In channel name" },
  { label: "Exclude anywhere", value: "Exclude anywhere" },
];

const FindInfluencer = () => {
  const [open, setOpen] = useState(false);
  const [fetchingInfluencer, setFetchingInfluencer] = useState(false);
  const [InfluencerData, setInfluencerData] = useState(false);
  const [scrollStep, setScrollStep] = useState(0);
  const [selectedSocial, setSelectedSocial] = useState("");
  const [columnHeaders, setColumnHeaders] = useState([]);
  const [rowsPerPageParent, setRowsPerPageParent] = useState(25);

  const [filterData, setFilterData] = useState({});
  const [filterApplied, setFilterApplied] = useState(false);

  const [allCollection, setAllCollection] = useState();

  // advance and search bar common states
  const [subcribersSize, setSubcribersSize] = useState(null);
  const [location, setLocation] = useState(null);
  const [age, setAge] = useState(null);
  const [gender, setGender] = useState(null);
  const [language, setLanguage] = useState(null);
  const [category, setCategory] = useState(null);
  const [keywords, setKeywords] = useState(null);
  const [clearSubsSize, setClearSubsSize] = useState();
  const [clearLocation, setClearLocation] = useState();
  const [clearAge, setClearAge] = useState();
  const [clearGender, setClearGender] = useState();
  const [clearLanguage, setClearLanguage] = useState();
  const [clearCategory, setClearCategory] = useState();
  const [clearKeywords, setClearKeywords] = useState();

  const [clearFilter, setClearFilter] = useState();

  const URL = process.env.REACT_APP_BASE_URL;

  // Function to fetch influencers from the API wihtout any filters
  const fetchYTInfluencers = async (page, perpage) => {
    console.log(
      "fetching influencers",
      page,
      perpage,
      filterData,
      filterApplied
    );
    try {
      setFetchingInfluencer(true);
      let body = filterApplied ? filterData : {};
      const response = await axiosInstance.post(`${URL}/search/yt/get`, body, {
        params: {
          page: page,
          per_page: perpage,
          // "query": 1,
          // "delay": 1,
        },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      console.log("fetching YT influencers response: ", response);

      if (response.status === 200 && response.data?.data) {
        setInfluencerData(response.data?.data);
        setColumnHeaders(response.data.columns);
      }
    } catch (error) {
      console.error("Error fetching influencers:", error);
    } finally {
      setFetchingInfluencer(false);
    }
  };

  const fetchIGInfluencers = async (page, perpage) => {};

  const fetchInfluencers = (page, perpage) => {
    if (selectedSocial === "youtube") {
      fetchYTInfluencers(page, perpage);
    } else if (selectedSocial === "instagram") {
      fetchIGInfluencers(page, perpage);
    } else {
      fetchYTInfluencers(page, perpage);
    }
  };

  // Fetch influencers on page load
  useMemo(() => {
    if (selectedSocial) {
      fetchInfluencers(1, 25);
    } else {
      fetchInfluencers(1, 200);
    }
    // console.log("influencer data: ", InfluencerData);
    console.log("selectedSocial", selectedSocial);
  }, [selectedSocial]);

  const section1Ref = useRef(null);
  const section2Ref = useRef(null);
  const isScrollingRef = useRef(false);

  // useEffect(() => {
  //   console.log("filterData aagaya", filterData);
  // }, [filterData]);

  const filterYTInfluencers = () => {
    fetchInfluencers(1, rowsPerPageParent);
  };

  const [locations, setLocations] = useState([]);

  const fetchLocations = async () => {
    try {
      const response = await getAllLocations();
      setLocations(response.locations);

      console.log("location api response: ", response);
    } catch (error) {
      console.error("Error fetching locations: ", error);
    }
  };

  const getCollections = async () => {
    try {
      console.log("all collection hit");
      const response = await getAllCollection();
      setAllCollection(response.collection);
      console.log("allCollection :-", response);
    } catch (e) {
      console.error("error while fething all collections ", e);
    } finally {
      console.log(allCollection);
      console.log("all collection hit");
    }
  };

  useEffect(() => {
    fetchLocations();
    getCollections();
  }, []);

  // const handleScroll = () => {
  //   if (isScrollingRef.current) return;
  //   isScrollingRef.current = true;

  //   const section1 = section1Ref.current;
  //   const section2 = section2Ref.current;

  //   if (section1 && section2) {
  //     if (scrollStep === 0) {
  //       window.scrollTo({ top: section1.offsetTop, behavior: "smooth" });
  //       setScrollStep(1);
  //     } else if (scrollStep === 1) {
  //       window.scrollTo({ top: section2.offsetTop, behavior: "smooth" });
  //       setScrollStep(2);
  //     } else {
  //       window.scrollTo({ top: section1.offsetTop, behavior: "smooth" });
  //       setScrollStep(1);
  //     }
  //   }

  //   setTimeout(() => {
  //     isScrollingRef.current = false;
  //   }, 1000); // Adjust timeout to match the smooth scroll duration
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [scrollStep]);

  const isMiniOpen = useSelector((state) => state.sharedState.isOpen);
  // const [screenSize, setScreenSize] = useState(window.innerWidth);
  // console.log("screenSize", screenSize);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setScreenSize(window.innerWidth);
  //   };

  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  // let top_influencers = InfluencerData?.data?.filter((data) => {
  //   return data?.subscriber >= 100000;
  // });

  // console.log("Top influencers: ", top_influencers);

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexShrink: 1,
        height: "100%",
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box maxWidth={"100%"} height={"100%"} width={"100%"} flexGrow={"none"}>
        <Box
          id="section1"
          ref={section1Ref}
          sx={{ position: "relative", height: "max-content", width: "100%" }}
        >
          <Header />
          <SearchBar
            open={open}
            set={setOpen}
            isMiniOpen={isMiniOpen}
            // setInfluencers={setInfluencers}
            // influencers={influencers}
            influencerData={InfluencerData}
            setInfluencerData={setInfluencerData}
            fetchMoreInfluencers={() => {}}
            filterYTInfluencers={filterYTInfluencers}
            selectedSocial={selectedSocial}
            setSelectedSocial={setSelectedSocial}
            locations={locations}
            filterData={filterData}
            setFilterData={setFilterData}
            subcribersSize={subcribersSize}
            setSubcribersSize={setSubcribersSize}
            location={location}
            setLocation={setLocation}
            age={age}
            setAge={setAge}
            gender={gender}
            setGender={setGender}
            language={language}
            setLanguage={setLanguage}
            category={category}
            setCategory={setCategory}
            genders={genderList}
            clearSubsSize={clearSubsSize}
            setClearSubsSize={setClearSubsSize}
            clearLocation={clearLocation}
            setClearLocation={setClearLocation}
            clearAge={clearAge}
            setClearAge={setClearAge}
            clearGender={clearGender}
            setClearGender={setClearGender}
            clearLanguage={clearLanguage}
            setClearLanguage={setClearLanguage}
            clearCategory={clearCategory}
            setClearCategory={setClearCategory}
            keywords={keywords}
            setKeywords={setKeywords}
            clearKeywords={clearKeywords}
            setClearKeywords={setClearKeywords}
            essentialData={{
              locationList: locations,
              genderList: genderList,
              verifiedList: verifiedList,
              contactInfoList: contactInfo,
              verifiedContactList: verifiedContact,
              socialPlatformsList: socialPlatforms,
              inTimeList: inTime,
              keywordsList: keywordsList,
            }}
          />
        </Box>
        <Box
          id="section2"
          ref={section2Ref}
          display={"block"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          paddingX={2}
          marginTop={4}
          width={"100%"}
        >
          <Box
            sx={{
              // height: "max-content",
              height: "100%",
              width: "100%",
              transition: "width 0.4s ease-in-out", // Smooth transition over 0.3 seconds
            }}
          >
            <FindInfluencerDatatable
              setRowsPerPageParent={setRowsPerPageParent}
              columnHeaders={columnHeaders}
              fetchInfluencers={fetchInfluencers}
              influencerData={
                selectedSocial
                  ? InfluencerData
                  : {
                      ...InfluencerData,
                      data: InfluencerData?.data
                        ?.sort((a, b) => b.subscriber - a.subscriber)
                        ?.filter((data) => {
                          return data?.subscriber >= 100000;
                        }),
                    }
              }
              selectedSocial={selectedSocial}
              fetchingInProgress={fetchingInfluencer}
              fetchWithFilter={filterYTInfluencers}
              isFilterApplied={filterApplied}
            />
          </Box>
          {selectedSocial === "instagram" ? (
            <AdvFilterDrawer2
              open={open}
              set={setOpen}
              selectedSocial={selectedSocial}
            />
          ) : null}
          {selectedSocial === "youtube" ? (
            // <AdvFilterDrawerY
            //   open={open}
            //   set={setOpen}
            //   selectedSocial={selectedSocial}
            // />
            <YTAdvancedFilters
              open={open}
              set={setOpen}
              selectedSocial={selectedSocial}
              fetchInfluencers={fetchInfluencers}
              fetchWithFilters={filterYTInfluencers}
              setFilterState={setFilterApplied}
              filterStateParent={filterApplied}
              setFilterData={setFilterData}
              filterDataParent={filterData}
              essentialData={{
                locationList: locations,
                genderList: genderList,
                verifiedList: verifiedList,
                contactInfoList: contactInfo,
                verifiedContactList: verifiedContact,
                socialPlatformsList: socialPlatforms,
                inTimeList: inTime,
                inTypeList: inType,
                keywordsList: keywordsList,
                collectionList: allCollection,
              }}
              subcribersSize={subcribersSize}
              setSubcribersSize={setSubcribersSize}
              location={location}
              setLocation={setLocation}
              age={age}
              setAge={setAge}
              gender={gender}
              setGender={setGender}
              language={language}
              setLanguage={setLanguage}
              category={category}
              setCategory={setCategory}
              clearSubsSize={clearSubsSize}
              setClearSubsSize={setClearSubsSize}
              clearLocation={clearLocation}
              setClearLocation={setClearLocation}
              clearAge={clearAge}
              setClearAge={setClearAge}
              clearGender={clearGender}
              setClearGender={setClearGender}
              clearLanguage={clearLanguage}
              setClearLanguage={setClearLanguage}
              clearCategory={clearCategory}
              setClearCategory={setClearCategory}
              keywords={keywords}
              setKeywords={setKeywords}
              clearKeywords={clearKeywords}
              setClearKeywords={setClearKeywords}
            />
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default FindInfluencer;
