import {
  BeenhereTwoTone,
  CancelOutlined,
  CheckOutlined,
  CloseOutlined,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Grid2,
  styled,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import IG from "../../images/insta-sm.png";

const temptDP =
  "https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg";

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.5rem",
  fontWeight: "bold",
}));

const Btn = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "5px 15px",
  borderRadius: 20,
  color: "#fff",
  background: "#3DBE72",
  boxShadow: "none",
  "&:hover": {
    background: "rgb(41, 154, 90)",
    boxShadow: "none",
  },
}));

const Btn1 = styled(Button)(({ theme }) => ({
  textTransform: "none",
  padding: "5px 15px",
  borderRadius: 20,
  border: "1px solid #3DBE72 !important",
  color: "#3DBE72",
  backgroundColor: "#fff",
  boxShadow: "none",
  "&:hover": {
    background: "#f1f1f1",
    boxShadow: "none",
  },
}));

const InfluencerCampaignsInvite = () => {
  const navigate = useNavigate();
  const [openTerms, setOpenTerms] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleAccept = () => {
    navigate("/dashboard/influencer-campaigns/dashboard");
  };

  let campaignType = "barter";

  return (
    <Box
      sx={{
        width: "100%",
        minHeight: "100vh",
        paddingY: 5,
        paddingX: 3,
        background: "#dde7f3",
        overflowX: "hidden",
      }}
    >
      <Grid2 container sx={{ paddingRight: 0, width: "100%", marginBottom: 5 }}>
        <Grid2 item size={{ sm: 12, lg: 8 }} sx={{ marginBottom: 1 }}>
          <Title sx={{ color: "#3dbe72ff" }}>Campaign Name</Title>
        </Grid2>
        <Grid2 item size={{ sm: 12, lg: 8 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Title sx={{ fontSize: "1rem" }}>Brand Name</Title>
            <BeenhereTwoTone sx={{ color: "#3dbe72ff" }} />
          </Box>
        </Grid2>
      </Grid2>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2,minmax(50%,1fr))",
          gap: 4,
        }}
      >
        <Grid2 container sx={{ paddingRight: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 2,

              px: 2,
              py: 3,
            }}
          >
            <Title sx={{ fontSize: "1.25rem" }}>Overview</Title>
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                gap: 3,
              }}
            >
              <Avatar sx={{ width: 80, height: 80 }} src={temptDP} />
              <Box sx={{ marginTop: 1 }}>
                <Typography sx={{ fontSize: "1rem", fontWeight: "medium" }}>
                  Influencer Name
                </Typography>
                <Typography sx={{ fontSize: "1rem", fontWeight: "medium" }}>
                  @handle
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 2,
                  // background: "#fff",
                  marginLeft: "auto",
                  p: 1,
                  borderRadius: 2,
                }}
              >
                <Typography>Campaign Platform</Typography>
                <img src={IG} alt="Instagram icon" />
              </Box>
            </Box>
          </Box>
        </Grid2>
        {campaignType === "paid" ? (
          <Grid2 container sx={{ paddingRight: 0 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 2,
                px: 2,
                py: 3,
              }}
            >
              <Title sx={{ fontSize: "1.25rem" }}>Payment</Title>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 2,
                    background: "#FFF",
                    minWidth: "180px",
                    p: 2,
                  }}
                >
                  <Typography sx={{ width: "100%", fontWeight: "500" }}>
                    Compensation
                  </Typography>
                  <Typography sx={{ width: "100%" }}>12.89K</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 2,
                    background: "#FFF",
                    minWidth: "180px",
                    p: 2,
                  }}
                >
                  <Typography sx={{ width: "100%", fontWeight: "500" }}>
                    Payment Terms
                  </Typography>
                  <Typography sx={{ width: "100%" }}>some term</Typography>
                </Box>
              </Box>
            </Box>
          </Grid2>
        ) : campaignType === "barter" ? (
          <Grid2 container sx={{ paddingRight: 0 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 2,
                px: 2,
                py: 3,
              }}
            >
              <Title sx={{ fontSize: "1.25rem" }}>Product details</Title>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 2,
                    background: "#FFF",
                    minWidth: "180px",
                    p: 2,
                  }}
                >
                  <Typography sx={{ width: "100%", fontWeight: "500" }}>
                    Product name
                  </Typography>
                  <Typography sx={{ width: "100%" }}>name</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 2,
                    background: "#FFF",
                    minWidth: "180px",
                    p: 2,
                  }}
                >
                  <Typography sx={{ width: "100%", fontWeight: "500" }}>
                    Product value
                  </Typography>
                  <Typography sx={{ width: "100%" }}>value</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 2,
                    background: "#FFF",
                    minWidth: "180px",
                    p: 2,
                  }}
                >
                  <Typography sx={{ width: "100%", fontWeight: "500" }}>
                    Product link
                  </Typography>
                  <Typography sx={{ width: "100%" }}>link</Typography>
                </Box>
              </Box>
            </Box>
          </Grid2>
        ) : (
          <Grid2 container sx={{ paddingRight: 0 }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: 2,

                px: 2,
                py: 3,
              }}
            >
              <Title sx={{ fontSize: "1.25rem" }}>Custom</Title>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: 2,
                    background: "#FFF",
                    minWidth: "180px",
                    p: 2,
                  }}
                >
                  <Typography sx={{ width: "100%", fontWeight: "500" }}>
                    Custom
                  </Typography>
                  <Typography sx={{ width: "100%" }}>custom value</Typography>
                </Box>
              </Box>
            </Box>
          </Grid2>
        )}
        <Grid2 container sx={{ paddingRight: 0 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              gap: 2,
              px: 2,
              py: 3,
            }}
          >
            <Title sx={{ fontSize: "1.25rem" }}>Deliverables</Title>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 4,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 2,
                  background: "#FFF",
                  minWidth: "180px",
                  p: 2,
                }}
              >
                <Typography sx={{ width: "100%", fontWeight: "500" }}>
                  Type
                </Typography>
                <Typography sx={{ width: "100%" }}>Reel</Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  borderRadius: 2,
                  background: "#FFF",
                  minWidth: "180px",
                  p: 2,
                }}
              >
                <Typography sx={{ width: "100%", fontWeight: "500" }}>
                  Quantity
                </Typography>
                <Typography sx={{ width: "100%" }}>13k</Typography>
              </Box>
            </Box>
          </Box>
        </Grid2>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          width: "100%",
          p: 2,
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Checkbox onChange={() => setTermsAccepted(!termsAccepted)} />
          <Typography sx={{}}>
            Accept the{" "}
            <Button
              sx={{ px: 1 }}
              variant="text"
              onClick={() => setOpenTerms(true)}
            >
              terms and conditions
            </Button>
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            gap: 2,
            justifyContent: "flex-end",
          }}
        >
          <Btn
            disabled={termsAccepted === false}
            onClick={() => handleAccept()}
            startIcon={<CheckOutlined />}
          >
            Accept
          </Btn>
          <Btn1 variant="outlined" startIcon={<CloseOutlined />}>
            Not Interested
          </Btn1>
          <Btn>Counter Offer</Btn>
        </Box>
      </Box>
    </Box>
  );
};

export default InfluencerCampaignsInvite;
