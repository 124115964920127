import {
  Dialog,
  Box,
  Typography,
  IconButton,
  Tooltip,
  styled,
  Chip,
  Button,
  Checkbox,
  TextField,
  Autocomplete,
  TextareaAutosize,
  Switch,
  FormControl,
  InputLabel,
  Input,
  InputAdornment,
  MenuItem,
  Avatar,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import Grid from "@mui/material/Grid2";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import Preview from "../../../images/invitation.svg";
import {
  CancelOutlined,
  DeleteOutlineOutlined,
  Add,
} from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { borderRadius } from "@mui/system";
import IG from "../../../images/insta-sm.png";

const label = { inputProps: { "aria-label": "Switch demo" } };

const socials = [
  { value: "Instagram", label: "Instagram", logo: IG },
  { value: "Youtube", label: "Youtube", logo: IG },
  { value: "Twitter", label: "Twitter", logo: IG },
  { value: "Facebook", label: "Facebook", logo: IG },
];

const fileTypes = ["JPG", "PNG", "GIF"];
const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  fontSize: "1.1rem",
}));

const HtmlLabel = styled("label")(`
        color : #828282ff;
        font-size : '1.1rem';
    
    `);

const HtmlInput = styled("input")(`
        background-color : #dce7f7ff;
        border-radius : 8px;
        width : 100%;
        height : 40px;
        padding : 0px 20px;
        outlied : none;
        border : none;
    `);

const HtmlTextArea = styled("textarea")(`
    width : 100%;
    padding : 10px 20px;
    border-radius : 8px;
    height : 120px;
    background-color : #dce7f7ff;
    border : none;
    `);

const HtmlInputNum = styled("input")(`
        width : 80px;
        text-align : center;
       border : none;
        border-radius : 10px;
        margin-right : 8px;
        background-color : #dce7f7ff;
    `);

const InviteDialog1 = ({ open, set, selectedRow }) => {
  console.log("Selected Row: ", selectedRow);
  return (
    <Dialog
      open={open}
      onClose={() => set(false)}
      PaperProps={{
        sx: {
          borderRadius: 3,
          border: "2px solid black",
          padding: 4,
          minWidth: 300,
          width: 650,
          maxWidth: 800,
        },
      }}
    >
      <Box>
        <Grid container>
          <Grid item size={{ md: 11, xs: 11 }}>
            <Typography
              sx={{
                color: "#000",
                fontWeight: "bold",
                fontSize: "1.35rem",
              }}
            >
              Campaign Invitation
            </Typography>
          </Grid>
          <Grid item size={{ md: 1, xs: 1 }} align="end">
            <Tooltip title={"Close"} arrow placement="right">
              <IconButton size={"small"} onClick={() => set(false)}>
                <CancelOutlined />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              height: "100%",
              pt: 3,
              gap: 2,
              justifyContent: "space-between",
            }}
          >
            <FormControl
              variant="standard"
              sx={{
                border: "none",
                width: "60%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Input
                fullWidth
                disableUnderline
                sx={{
                  borderRadius: 30,
                  border: 1,
                  textDecoration: "none",
                  height: "100%",
                  px: 2,
                  py: 0,
                  ".MuiInputBase-root": {
                    py: 0,
                    borderRadius: 30,
                  },
                  //   ".MuiInput-root::before": { display: "none", border: "none" },
                }}
                placeholder="Profile URL, @handle OR User ID"
                id="input-with-icon-adornment"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <TextField
              sx={{ width: "40%" }}
              id="outlined-select-socials"
              select
              defaultValue="Instagram"
            >
              {socials.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  {/* <Avatar src={option.logo} sx={{ width: 24, height: 24 }} /> */}
                  <Typography sx={{ width: "100%", fontSize: 15 }}>
                    {option.label}
                  </Typography>
                </MenuItem>
              ))}
            </TextField>
          </Box>
          <Box
            sx={{
              py: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
            }}
          >
            <Typography
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
                color: "grey",
                fontSize: 15,
                bgcolor: "#fff",
                p: 2,
              }}
            >
              Or
            </Typography>
            <hr style={{ width: "100%", height: 2, backgroundColor: "grey" }} />
          </Box>
          <TextField
            size="small"
            sx={{
              width: "100%",
              ".MuiInputBase-root": { borderRadius: 3, bgcolor: "#f1f1f1" },
            }}
            placeholder="Search list"
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default InviteDialog1;
