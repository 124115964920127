// import React from "react";

// import { useState } from "react";
// import {
//   Typography,
//   Box,
//   Icon,
//   IconButton,
//   Button,
//   Tooltip,
//   Dialog,
//   styled,
//   TextField,
//   List,
//   ListItem,
//   ListItemButton,
//   ListItemText,
//   InputAdornment,
//   Divider,
//   AvatarGroup,
//   Avatar,
// } from "@mui/material";

// import Grid from "@mui/material/Grid2";
// import { Link } from "react-router-dom";

// import DP from "../../images/dp-lg.png";
// import dp from "../../images/dp.png";
// import insta from "../../images/insta-xsm.png";
// import insta_sm from "../../images/insta-sm.png";
// import youtube_sm from "../../images/youtube-sm.png";
// import whatsapp from "../../images/WhatsApp.png";
// import gmail from "../../images/Gmail.png";
// import TurnRightRoundedIcon from "@mui/icons-material/TurnRightRounded";
// import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
// import HelpOutlineRoundedIcon from "@mui/icons-material/HelpOutlineRounded";
// import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
// import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
// import SearchIcon from "@mui/icons-material/Search";

// import AccountAnalytics from "../collection/AccountAnalytics";

// const TemptDP =
//   "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU'";

// const PublicInfluencerProfile = () => {
//   const [showOverlay, setShowOverlay] = useState(false);
//   const [showDp, setShowDp] = useState(false);

//   // const handleClickDP = () => {
//   //     setShowDp(true)
//   // }

//   const handleClickDP = () => {
//     setShowDp(true);
//   };

//   return (
//     <Box style={{ padding: 20 }}>
//       <Dialog open={showDp}>
//         <img src={TemptDP} alt="" />

//         <IconButton size="small" onClick={() => setShowDp(false)}>
//           <Tooltip
//             title="Close"
//             arrow
//             componentsProps={{
//               arrow: {
//                 sx: {
//                   //   backgroundColor: 'red',
//                   color: "red",
//                 },
//               },
//               tooltip: {
//                 sx: {
//                   color: "white",
//                   backgroundColor: "red",
//                   // fontSize: "0.9rem",
//                 },
//               },
//             }}
//           >
//             <CancelOutlinedIcon />
//           </Tooltip>
//         </IconButton>
//       </Dialog>

//       <Tooltip
//         title="close"
//         arrow
//         componentsProps={{
//           arrow: {
//             sx: {
//               //   backgroundColor: 'red',
//               color: "red",
//             },
//           },
//           tooltip: {
//             sx: {
//               color: "white",
//               backgroundColor: "red",
//               // fontSize: "0.9rem",
//             },
//           },
//         }}
//       >
//         <IconButton
//           onClick={() => {
//             // close(false)
//           }}
//           style={{ position: "absolute", right: 20, padding: 0 }}
//         >
//           <CloseRoundedIcon style={{ padding: 0 }} />
//         </IconButton>
//       </Tooltip>

//       <Typography
//         style={{
//           color: "#3DBE72",
//           fontWeight: "bold",
//           fontSize: "1.6rem",
//           marginBottom: "1.5rem",
//         }}
//       >
//         Find Influencer
//       </Typography>

//       <Grid container sx={{ marginTop: "30px" }}>
//         <Grid item md={1.3} xs={3}>
//           <Box
//             position="relative"
//             onMouseEnter={() => setShowOverlay(true)}
//             onMouseLeave={() => setShowOverlay(false)}
//             onClick={handleClickDP}
//             style={{ cursor: "pointer", height: 150, width: 150 }}
//           >
//             <img
//               src={TemptDP}
//               alt=""
//               width="100%"
//               style={{ borderRadius: 8 }}
//             />
//             {showOverlay && (
//               <div
//                 style={{
//                   position: "absolute",
//                   bottom: 0,
//                   left: 0,
//                   width: "100%",
//                   height: "40%",
//                   backgroundColor: "rgba(255, 255, 255, 0.9)",
//                   // borderRadius: '50%',
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   zIndex: 1,
//                 }}
//               >
//                 {/* Your overlay content goes here */}
//                 <span
//                   style={{
//                     color: "green",
//                     fontSize: "0.8rem",
//                     fontWeight: "bold",
//                   }}
//                 >
//                   View
//                 </span>
//               </div>
//             )}
//           </Box>
//         </Grid>
//         <Grid
//           item
//           md={10}
//           sx={{
//             display: "flex",
//             flexDirection: "column",
//             paddingLeft: "20px",
//             justifyContent: "center",
//           }}
//         >
//           <Box sx={{ display: "flex" }}>
//             <Box>
//               <Box style={{ textDecoration: "none", color: "inherit" }}>
//                 <Typography style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
//                   {"Abhishek Malviya"}
//                 </Typography>
//                 <Typography style={{ fontSize: 10, color: "#7b7b7b" }}>
//                   {"10M"} total audience (India)
//                 </Typography>
//               </Box>
//             </Box>
//             <Box style={{ marginLeft: 10 }}>
//               <IconButton
//                 style={{
//                   color: "#3DBE72",
//                   padding: 5,
//                   border: "1px solid #3DBE72",
//                 }}
//                 aria-label="add to shopping cart"
//               >
//                 <TurnRightRoundedIcon style={{ fontSize: 29 }} />
//               </IconButton>
//             </Box>
//           </Box>
//           <Box style={{ marginTop: 15, marginBottom: 5 }}>
//             {/* <Button onClick={() => setShowCampaign(true)} variant="outlined" style={{ padding: '5px 20px', borderRadius: 20, fontSize: '11px', color: "#3DBE72", fontWeight: 'bold', textTransform: 'none', marginRight: '10px', '&:hover': { borderColor: '#3DBE72' } }}>Add to Campaign</Button> */}

//             <Button
//               size="small"
//               variant="contained"
//               style={{
//                 borderRadius: 20,
//                 fontSize: "11px",
//                 color: "white",
//                 fontWeight: "bold",
//                 background: "#3DBE72",
//                 textTransform: "none",
//                 boxShadow: "none",
//               }}
//             >
//               Share
//             </Button>

//             {/* <IconButton onClick={() => setShowCollection(true)} style={{ color: "#3DBE72" }} aria-label="add to shopping cart">
//                             <AddCircleRoundedIcon style={{ padding: 0, fontSize: 25 }} />
//                         </IconButton> */}
//           </Box>
//           <Box>
//             <IconButton size="small">
//               <img src={insta_sm} alt="" />
//             </IconButton>
//             <IconButton size="small">
//               <img src={youtube_sm} alt="" width={25} />
//             </IconButton>
//             <IconButton size="small">
//               <img
//                 src={
//                   "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/2560px-Gmail_icon_%282020%29.svg.png"
//                 }
//                 alt=""
//                 width={20}
//               />
//             </IconButton>
//             <IconButton size="small">
//               <img
//                 src={
//                   "https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/479px-WhatsApp_icon.png"
//                 }
//                 alt=""
//                 width={25}
//               />
//             </IconButton>
//           </Box>
//         </Grid>
//       </Grid>

//       <Box
//         style={{
//           background: "#F2F7FF",
//           borderRadius: 20,
//           padding: 30,
//           marginTop: 30,
//         }}
//       >
//         <Box style={{ display: "flex", alignItems: "center" }}>
//           <img
//             src={
//               "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRIqOomsUSZWC3NYJQVI-57KDvyIVgDCsOgEA&usqp=CAU"
//             }
//             alt=""
//             width="40px"
//             style={{ borderRadius: 50 }}
//           />
//           <Box style={{ marginLeft: 15 }}>
//             <Typography style={{ fontWeight: "bold", fontSize: "12px" }}>
//               @{"abhishek_malviya"}
//             </Typography>
//             <Typography style={{ fontSize: "10px" }}>
//               {10} Million Followers
//             </Typography>
//           </Box>
//         </Box>
//         <Typography style={{ fontSize: 12, margin: "10px 0" }}>
//           Live life on your own terms #jointhemvmt Watches, Eyewear & Premium
//           Accessori es For her @mvmtforher Shop our Site
//         </Typography>

//         <Typography style={{ fontWeight: "bold", fontSize: 12 }}>
//           {" "}
//           #Live #life #jointhemvmt #Watches #Eyewear #Premium #Accessori es{" "}
//         </Typography>
//       </Box>

//       <AccountAnalytics avglikes={"3500"} avgcomments={"100k"} er={"18.2%"} />
//     </Box>
//   );
// };

// export default PublicInfluencerProfile;

import React, { useCallback, useEffect, useState } from "react";
import {
  Typography,
  Box,
  Icon,
  IconButton,
  Button,
  styled,
  TextField,
  InputAdornment,
  Dialog,
  Avatar,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { useParams } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import BasicTabs from "../subcomponents/findInfluencer/BasicTabs";
import { getUserByID } from "../../../service/user/getUserByID";
import { YouTube } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { getAllCollection } from "../../../service/collections api/getAllCollection";
import { getCollectionById } from "../../../service/collections api/getCollectionById";
import { updateInfluencerCollection } from "../../../service/collections api/updateInfluencer";

const PublicInfluencerProfile = (props) => {
  const [user, setUser] = useState({});

  const { id } = useParams();

  // Base64 decode function
  const decodeId = (encodedId) => atob(encodedId);
  const decodedId = decodeId(id);
  console.log("decodedId : ", decodedId, typeof decodedId);

  const getUser = async () => {
    const user = await getUserByID({ id: decodedId });
    console.log("user from profile page : ", user);
    setUser(user);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getUser();
  }, []);

  const [searchTerm, setSearchTerm] = useState("");

  const [open, setOpen] = React.useState(false);
  const IsOpen = useSelector((state) => state.sharedState.isOpen);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  function removeEmailsAndPhones(text) {
    // Regular expression to match email addresses
    const emailPattern = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;

    // Regular expression to match phone numbers (generic pattern for digits with optional separators)
    const phonePattern =
      /(\+?\d{1,4}[\s.-]?)?(\(?\d{3}\)?[\s.-]?)?\d{3}[\s.-]?\d{4}/g;

    // Replace email addresses and phone numbers with an empty string
    return text?.replace(emailPattern, "")?.replace(phonePattern, "");
  }

  // function formatNumber(num) {
  //   try {
  //     const strNum = num.toString();

  //     if (strNum.endsWith("000000")) {
  //       return strNum.replace(/000000$/, "M"); // Replace trailing six zeros with 'M'
  //     } else if (strNum.endsWith("000")) {
  //       return strNum.replace(/000$/, "K"); // Replace trailing three zeros with 'K'
  //     } else {
  //       return num.toString(); // Return the number as is
  //     }
  //   } catch (error) {
  //     return "error";
  //   }
  // }

  function formatNumber(num) {
    try {
      // Convert the input to a number in case it's a string
      const number = Number(num);

      // Check if the conversion was successful
      if (isNaN(number)) {
        throw new Error("Invalid number");
      }

      // Format the number based on its value
      if (number >= 1e6) {
        return (number / 1e6).toFixed(1) + "M"; // Convert to millions
      } else if (number >= 1e3) {
        return (number / 1e3).toFixed(1) + "K"; // Convert to thousands
      } else {
        return number.toString(); // Return the number as is
      }
    } catch (error) {
      return "error";
    }
  }

  const [pending, setPending] = useState(false);
  const [allCollection, setAllCollection] = useState([]);
  const [isAdded, setIsAdded] = useState(false);
  const [view, setView] = useState("1");
  const [aCollection, setaCollection] = useState({});
  const [createdBy, setCreatedBy] = useState({});

  const [loading, setLoading] = useState(false);
  // const [addBrandDialog, setAddBrandDialog] = useState(false);
  const [isSucessful, setIsSuccessful] = useState(false);

  const [searchText, setSearchText] = useState("");

  const [isBrandFocus, setIsBrandFocus] = useState(false);
  const [brandName, setBrandName] = useState("");

  const [createCollectionDialog, setCreateCollectionDialog] = useState(false);

  const [newCollection, setNewCollection] = useState({});

  const [addBrandDialog, setAddBrandDialog] = useState(false);

  const [encodedNewCollectionId, setEncodedNewCollectionId] = useState("");

  const [showCollection, setShowCollection] = useState(false);

  const [allBrands, setAllBrands] = useState([]);

  const [collectionDesc, setCollectionDesc] = useState("");

  const [collectionName, setCollectionName] = useState("");

  const [selectedItemId, setSelectedItemId] = useState(null);

  const [isAddedSuccessful, setIsAddedSuccessful] = useState(false);

  const [brandId, setBrandId] = useState(null);

  const [selectedRowArr, setSelectedRowArr] = useState([]);

  let influencerIds = [decodedId];

  const getCreatedBy = async () => {
    try {
      // const id_ = {
      //   id: id,
      // };
      console.log("get user by id ki id: ", selectedItemId);
      const res = await getUserByID({ id: selectedItemId });
      console.log("created by", res);
      setCreatedBy(selectedItemId);
      console.log("created by state : ", createdBy);
      // if (aCollection.created_by) {
      //     console.log('got it ');
      // }
    } catch (e) {
      console.error(
        "error occurs while getting name of created by : ",
        e.message
      );
    }
  };

  useEffect(() => {
    if (isSucessful) getCollections();

    if (aCollection) {
      getCreatedBy();
      // Perform any action that depends on aCollection immediately after it updates
      console.log("aCollection updated:", aCollection);
    }
  }, [isSucessful, createdBy, aCollection]);

  // get all collections
  const getCollections = useCallback(async () => {
    setPending(true);
    try {
      const response = await getAllCollection();
      console.log("Get all collections response data: ", response.collection);

      setAllCollection(response.collection);
    } catch (e) {
      console.error("error while fething all collections ", e.message);
    } finally {
      console.log(allCollection);
      console.log("all collection hit");
      setPending(false);
    }
  }, []);

  const handleAddInfluencers = async () => {
    const data = {
      collection_id: createdBy,
      influencer_id: influencerIds,
    };

    console.log("handle add influencer log: ", data);

    try {
      const res = await updateInfluencerCollection(data);
      console.log("update influencer collection: ", res);
      setIsSuccessful(true);
      setIsAddedSuccessful(true);
    } catch (error) {
      console.error("Error updating influencer collection: ", error);
    }
  };

  const openCreateCollectionDialog = () => {
    setCreateCollectionDialog(true);
  };

  const closeAddToCollection = () => {
    setShowCollection(false);
    setIsAdded(false);
    setIsAddedSuccessful(false);
  };

  const handleAddToCollectionDialogOpen = () => {
    // Open the dialog
    setShowCollection(true);

    // Call the API function when the dialog is opened
    getCollections();
  };

  const getACollectionById = (collectionId) => {
    const selectedCollection = allCollection?.find(
      (collection) => collection.id === collectionId
    );
    return selectedCollection;
  };

  const detailedCollection = async (id) => {
    const data = getACollectionById(id);

    console.log("Get collection by id data: ", data);

    setaCollection(() => data);

    let created_by_id = data?.id;
    console.log("detailed collection - created by : ", created_by_id);

    setSelectedItemId(() => created_by_id);

    // getCreatedBy();
    const res = await getCollectionById(created_by_id);
    console.log("get collection by ID: ", res);
  };

  return (
    <Box container padding={2} paddingX={6} width={"100%"}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 3,
          width: "100%",
          flexDirection: {
            md: "row",
            xs: "column",
          },
        }}
      >
        <Box
          paddingX={4}
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          {user?.photo ? (
            <Avatar
              src={user?.photo}
              alt={user?.name}
              sx={{
                width: 150,
                height: 150,
                borderRadius: "50%",
                border: 3,
                borderColor: "#3DBE72",
              }}
            />
          ) : (
            <Avatar
              src={user?.photo_link}
              alt={user?.name}
              sx={{
                width: 150,
                height: 150,
                borderRadius: "50%",
                border: 3,
                borderColor: "#3DBE72",
              }}
            />
          )}
        </Box>
        <Box sx={{ padding: 1 }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "bold",
                  fontSize: "1.8rem",
                }}
              >
                {user?.name}
              </Typography>
              {user?.platform_verified && <VerifiedIcon color={"primary"} />}
              {user?.verified && <VerifiedIcon color={"primary"} />}
            </Box>
            <Box>
              <IconButton
                href={`https://www.youtube.com/channel/${user?.channel_id}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ color: "#FF0000" }}
              >
                <YouTube
                  sx={{
                    width: 32,
                    height: 32,
                  }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: "1.3rem",
                color: "#3dbf73ff",
                fontWeight: "bold",
              }}
            >
              {user?.handle ? user.handle : ""}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: 1,
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Subscribers: </b>{" "}
              {user?.subscriber ? formatNumber(user.subscriber)?.trim() : 0}
              {/* Followers
                  Location
                  Account type of the profile, such as Personal, Creator or Business
                  Gender of the profile
                  Bio
                  Content Categories
                  Hashtags */}
            </Typography>
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Location:</b>{" "}
              {user
                ? user.city && user.state
                  ? user.city + " , " + user.state
                  : user.city
                  ? user.city
                  : user.state
                  ? user.state
                  : user.country &&
                    user.country ===
                      "Country not found for the given shortcode."
                  ? "N/A"
                  : user.country
                : "N/A"}
            </Typography>
            {/* <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              Account: {user.AccountType ? user.AccountType : "Account"}
            </Typography> */}
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Gender:</b> {user?.gender ? user.gender?.trim() : ""}
            </Typography>
            {/* <Typography
                    sx={{
                      color: "black",
                      marginRight: "10px",
                    }}
                  >
                    {user.Bio ? user.Bio : "Bio"}
                  </Typography> */}
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Category:</b>{" "}
              {user.influencer_category === null
                ? JSON.parse(user?.topics?.replace(/'/g, '"'))[0]?.trim()
                : user.influencer_category}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: 1,
              gap: 2,
            }}
          >
            <Typography
              sx={{
                color: "black",
                marginRight: "10px",
              }}
            >
              <b>Tags:</b>{" "}
              {user?.Hashtags?.split(", ")?.length > 0
                ? user?.Hashtags?.split(", ").join(", ")
                : ""}
              <Box sx={{ display: "inline-flex", flexWrap: "wrap" }}>
                {user?.keywords
                  ? JSON.parse(user?.keywords?.replace(/'/g, '"')).map(
                      (data) => {
                        return data + ", ";
                      }
                    )
                  : ""}
              </Box>
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: "#F2F7FF",
          borderRadius: 5,
          padding: 3,

          marginTop: 4,
        }}
      >
        <Typography>
          {user?.description ? removeEmailsAndPhones(user.description) : "N/A"}
        </Typography>

        <Typography sx={{ fontWeight: "bold" }}>
          {user?.["List of top hashtags used by the creator"]
            ? user["List of top hashtags used by the creator"]
            : ""}
        </Typography>
      </Box>
      {/* <Box
        sx={{
          backgroundColor: "#F2F7FF",
          borderRadius: 5,
          padding: 5,
          marginTop: 10,
          mx: "auto",
          overflow: "hidden",
          maxWidth: IsOpen ? "92vw" : "78vw",
          transition: "all 0.5s ease-in-out",
        }}
      >
        {!IsOpen ? (
          <ProfileCarousel
            value={
              screenWidth > 1442
                ? 5
                : screenWidth > 1024
                ? 5
                : screenWidth > 768
                ? 3
                : 1
            }
          />
        ) : (
          <ProfileCarousel value={5} />
        )}
      </Box> */}
      <Grid item size={12}>
        <BasicTabs userdata={user} />
      </Grid>
    </Box>
  );
};

export default PublicInfluencerProfile;
