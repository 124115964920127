import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Tabs,
  Tab,
  Typography,
  Box,
  TabPanel,
  TextField,
  InputAdornment,
  Button,
  IconButton,
  styled,
  Avatar,
  useMediaQuery,
  Autocomplete,
  AccordionDetails,
  Checkbox,
  AccordionSummary,
  Accordion,
  FormControl,
  InputLabel,
  Select,
  Modal,
  CircularProgress,
  MenuItem,
  Container,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import {
  PersonSearchRounded,
  AddRounded,
  Add,
  AddTaskOutlined,
  WarningAmberOutlined,
  CloseRounded,
  Instagram,
  YouTube,
} from "@mui/icons-material";
import Insta from "../../images/insta-sm.png";
import Youtube from "../../images/youtube-sm.png";
import Table from "./Table";

import { assignedCampaign } from "./collectionData";
import { getAllInfluencers } from "../../../service/getAllApi";
import { getCollectionById } from "../../../service/collections api/getCollectionById";
import CsvFileUploader from "./CsvFileUploader";
import { getAllCollection } from "../../../service/collections api/getAllCollection";
import { updateCollection2 } from "../../../service/collections api/createCollection";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, pt: 2 }}>
          {/* <Typography>{children}</Typography> */}
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BasicTabs = ({ data, influencers, rowsPerPage, setRowsPerPage }) => {
  console.log("data basic tabs ; ", data);

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  console.log("basics tabs", data);
  console.log("basics tabs influencers data", influencers);

  const [value, setValue] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const [loading, setLoading] = useState(false);
  const [searchedData, setSearchedData] = useState(influencers);

  const [collection, setCollection] = useState({});
  const [createCollectionDialog, setCreateCollectionDialog] = useState(false);

  const [allCollection, setAllCollection] = useState([]);
  const [selectedCollection, setSelectedCollection] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [error, setError] = useState({
    message: "",
    status: false,
  });
  const [isSucessful, setIsSuccessful] = useState(false);
  const [isBrandFocus, setIsBrandFocus] = useState(false);
  const [brandName, setBrandName] = useState("");
  const [expanded, setExpanded] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [influencerName, setInfluencerName] = useState("");
  const [pending, setPending] = useState(false);
  const [getInfluencersData, setGetInfluencersData] = useState(null);
  const [fetchingInfluencers, setFetchingInfluencers] = useState(false);
  const [accountLink, setAccountLink] = useState("");
  const [linkType, setLinkType] = useState("");

  const fetchMoreInfluencers = async () => {
    try {
      setFetchingInfluencers(true);
      // if (getInfluencersData) {
      //   console.log("check this before sending to api", getInfluencersData);
      //   const response = await getAllInfluencers(getInfluencersData);
      //   console.log("influencers ", response);
      //   if (response?.data?.length > 0) {
      //     setInfluencers(response?.data);
      //   }
      // }
    } catch (error) {
      console.error("Error fetching influencers:", error);
    } finally {
      setFetchingInfluencers(false);
    }
  };

  useEffect(() => {
    if (getInfluencersData) fetchMoreInfluencers();
  }, [getInfluencersData]);

  const handleChangeAccordion = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);

    if (event.target.value.length > 1) {
      const filteredData = influencers.filter((influencer) =>
        influencer.influencer_data.name
          .toLowerCase()
          .includes(event.target.value.toLowerCase())
      );
      setSearchedData(filteredData);
    } else {
      setSearchedData(influencers);
    }
  };

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setCreateCollectionDialog(false);
    setError({ message: "", status: false });
    setIsSuccessful(false);
    setIsBrandFocus(false);
    setBrandName("");
    setCollection({ ...collection });
    setOpen(false);
    setSelectedCollection([]);
    // handleNewCollection(newCollection);
    // getCollection();
  };

  useEffect(() => {
    setSearchedData(influencers || []);
  }, [influencers]);

  // const getInfluencers = async () => {
  //   // const collection_id = {
  //   //     id: collectionData.id
  //   // }
  //   // const collection = await getCollectionById(collection_id);

  //   setLoading(true);

  //   const collectionInfluencers = data.influncers_id ? data.influncers_id : [];

  //   console.log("collectionInfluencers Arr", collectionInfluencers);

  //   const response = await getAllInfluencers();
  //   const users = response.users;

  //   let influencers = users?.filter((influncer) =>
  //     collectionInfluencers.includes(influncer.id)
  //   );

  //   console.log("influencer ddda", influencers);

  //   console.log("users", users);

  //   setInfluencerData(influencers);
  //   setSearchedData(influencers);

  //   setLoading(false);
  //   // console.log(allInfluencersRecord);
  // };

  useEffect(() => {
    // getInfluencers();
  }, []);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "45%",
    // overflowY: 'scroll',
    bgcolor: "background.paper",
    // height: "80%",
    alignItems: "center",
    boxSizing: "border-box",
    borderRadius: 7,
    padding: "2rem",
    "&:--webkit-scrollbar": {
      display: "none",
    },
  };

  const LoadingSpinner = styled(CircularProgress)(({ theme }) => ({
    color: "#FFFFFF",
  }));

  const handleCollectionSelect = (collectionId) => {
    console.log("Selected collection ID:", collectionId); // Debugging statement
    // // Check if the selected collection is already in the list
    // if (
    //   !selectedCollection.some((collection) => collection.id === collectionId)
    // ) {
    // Find the selected collection object from allCollection
    const selectedCollectionObj = allCollection?.find(
      (item) => item.id === collectionId
    );
    //   console.log("Selected Collection:", selectedCollectionObj); // Debugging statement
    //   // Update the list of selected collections
    //   setSelectedCollection([...selectedCollection, selectedCollectionObj]);
    setSelectedCollection([selectedCollectionObj]);
    // }
    // Close the menu after selection
    setMenuOpen(false);
  };

  const appendInfluencersToCollection = async () => {
    try {
      let collectionsource = selectedCollection[0];
      let destinationCollection = collection;

      let sourceInfluencerIDs = JSON.parse(collectionsource?.influncers_id);
      let destInfluencerIDs = JSON.parse(destinationCollection?.influncers_id);
      let newDestnationIDs = [...destInfluencerIDs];

      sourceInfluencerIDs?.forEach((id, index) => {
        if (!destInfluencerIDs.includes(id)) {
          newDestnationIDs.push(id);
        }
      });

      destinationCollection.influncers_id = newDestnationIDs;
      console.log("Updated Collection: ", destinationCollection);

      const response = await updateCollection2({
        id: destinationCollection.id,
        influncers_id: newDestnationIDs,
      });

      if (response !== false) {
        setCollection(response);
        getAllCollection();
      } else {
        console.log("wasn't able to add influencers from another collection");
      }
    } catch (e) {
      console.error("error while appending influencers to collection : ", e);
    }
  };

  const addInfluencersViaCSV = async (CSVdata) => {
    try {
      let newDestnationIDs = [...JSON.parse(collection.influncers_id)];

      CSVdata.forEach((row) => {
        newDestnationIDs.push(row);
      });

      console.log("collectionID", collection.id);
      console.log("newDestnationIDs", newDestnationIDs);

      const uniqueArray = [...new Set(newDestnationIDs)];

      const response = updateCollection2({
        id: collection.id,
        influncers_id: `[${uniqueArray.toString()}]`,
      });
      if (response != false) {
        getAllCollection();
        handleClose();
      } else {
        console.log("wasn't able to add influencers from another collection");
      }
    } catch (error) {
      console.error("Error sending data from CSV:", error);
    }
  };

  const appendInfluencersByName = async () => {
    try {
    } catch (error) {
      console.error("Error sending data from CSV:", error);
    }
  };

  const getCollections = async () => {
    setPending(true);
    try {
      console.log("all collection hit");
      const response = await getAllCollection();
      setAllCollection(response.collection);
      console.log("allCollection :-", response);
    } catch (e) {
      console.error("error while fething all collections ", e);
    } finally {
      console.log(allCollection);
      console.log("all collection hit");
      setPending(false);
    }
  };

  const appendInfluencersByLink = async () => {
    try {
      if (linkType === "instagram") {
        // Call your API function to append influencers to the collection
        // const response = await addInfluencerByInstaLink({
        //   instaurl: accountLink,
        // });
        // console.log("response 215", response);
      }
      if (linkType === "youtube") {
        // Call your API function to append influencers to the collection
        // const response = await addInfluencerByYtLink({ yturl: accountLink });
        // console.log("response 220", response);
      }
    } catch (e) {
      console.error("error while appending influencers by link : ", e.message);
    }
  };

  useEffect(() => {
    getCollections();
  }, []);

  return (
    <Box>
      {/* Add influencer modal  */}
      <Modal
        open={open}
        onClose={() => {
          handleClose();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton onClick={handleClose}>
              <CloseRounded />
            </IconButton>
          </Box>
          <Typography
            sx={{
              marginY: "1rem",
            }}
            id="modal-modal-title"
            variant="h6"
            component="h2"
          >
            Choose a option to add account
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
              // height: "80%",
              gap: "2rem",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                boxShadow: "none",
                gap: "1rem",
              }}
            >
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChangeAccordion("panel1")}
                sx={{
                  boxShadow: "none",
                  width: "100%",
                  borderRadius: 2,
                  border: "none",
                }}
              >
                <AccordionSummary
                  expandIcon=""
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    background: "#e0f7e9",
                    color: "#000000",
                    fontWeight: "bold",
                    borderRadius: 2,
                    boxShadow: "none",
                    "&:hover": {
                      background: "#e0f7e9",
                      color: "#000000",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "",
                        fontSize: "1.2rem",
                      }}
                    >
                      Upload CSV
                    </Typography>

                    <Checkbox
                      checked={expanded === "panel1" ? true : false}
                      label="Label"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    boxShadow: "none",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <CsvFileUploader
                    addInfluencersWithCSV={addInfluencersViaCSV}
                  />
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChangeAccordion("panel2")}
                sx={{
                  boxShadow: "none",
                  width: "100%",
                  borderRadius: 2,
                }}
              >
                <AccordionSummary
                  expandIcon=""
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    background: "#e0f7e9",
                    color: "#000000",
                    fontWeight: "bold",
                    borderRadius: 2,
                    boxShadow: "none",

                    "&:hover": {
                      background: "#e0f7e9",
                      color: "#000000",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "",
                        fontSize: "1.2rem",
                      }}
                    >
                      Select Existing Collection
                    </Typography>

                    <Checkbox
                      checked={expanded === "panel2" ? true : false}
                      label="Label"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    boxShadow: "none",
                  }}
                >
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Collections
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Collections"
                      >
                        {allCollection?.map(
                          ({ name, id, influencers_id }, index) => (
                            <MenuItem
                              key={index}
                              value={id}
                              aria-label="list"
                              sx={{
                                textTransform: "none",
                                fontSize: "1rem",
                                fontWeight: "bold",
                                border: "none",
                              }}
                              onClick={() => handleCollectionSelect(id)} // Handle influencer selection
                            >
                              {name}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>
                    <div
                      style={{
                        marginTop: "1rem",
                      }}
                    >
                      <h4>Selected Influencers:</h4>
                      <ul
                        style={{
                          display: "flex",
                          width: "100%",
                          flexWrap: "wrap",
                          gap: "1rem",
                          marginTop: "1rem",
                          marginLeft: "1rem",
                        }}
                      >
                        {selectedCollection?.map((Collection, index) => (
                          <li
                            style={{
                              marginRight: "1rem",
                              fontSize: "1rem",
                              fontWeight: 400,
                            }}
                            key={index}
                          >
                            {Collection.name}
                          </li>
                        ))}
                      </ul>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "100%",
                        }}
                      >
                        {selectedCollection?.length > 0 && (
                          <Button
                            onClick={() => {
                              appendInfluencersToCollection();
                              handleClose();
                            }}
                            variant="contained"
                            sx={{
                              padding: "0",
                              marginTop: "1rem",
                            }}
                          >
                            {" "}
                            Add{" "}
                          </Button>
                        )}
                      </Box>
                    </div>
                  </Box>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChangeAccordion("panel3")}
                sx={{
                  boxShadow: "none",
                  width: "100%",
                  borderRadius: 2,
                }}
              >
                <AccordionSummary
                  expandIcon=""
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    background: "#e0f7e9",
                    color: "#000000",
                    fontWeight: "bold",
                    borderRadius: 2,

                    "&:hover": {
                      background: "#e0f7e9",
                      color: "#000000",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "",
                        fontSize: "1.2rem",
                      }}
                    >
                      Add Account Links
                    </Typography>

                    <Checkbox
                      checked={expanded === "panel3" ? true : false}
                      label="Label"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    boxShadow: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <input
                      onChange={(e) => {
                        setAccountLink(e.target.value);
                      }}
                      type="text"
                      placeholder="Enter Account Link"
                      style={{
                        padding: "1rem",
                        borderRadius: "15px",
                        border: "2px solid black",
                        width: "80%",
                      }}
                    />

                    <FormControl
                      sx={{
                        width: "20%",
                        marginLeft: "1rem",
                      }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Platform
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Select Platform"
                        onChange={(e) => setLinkType(e.target.value)}
                        defaultValue={""}
                      >
                        <MenuItem
                          sx={{
                            padding: "5px",
                            margin: "5px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            mb: "1rem",
                            bgcolor: "#e6e6e6",
                          }}
                          value={"instagram"}
                        >
                          <Instagram sx={{ color: "red" }} />
                        </MenuItem>
                        <MenuItem
                          sx={{
                            padding: "5px",
                            margin: "5px",
                            borderRadius: "10px",
                            display: "flex",
                            justifyContent: "center",
                            mb: "1rem",
                            bgcolor: "#e6e6e6",
                          }}
                          value={"youtube"}
                        >
                          <YouTube sx={{ color: "red" }} />
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  {accountLink ? (
                    <>
                      <Button
                        onClick={appendInfluencersByLink}
                        variant="contained"
                        sx={{
                          padding: "0",
                          marginTop: "1rem",
                        }}
                      >
                        Add
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChangeAccordion("panel4")}
                sx={{
                  boxShadow: "none",
                  width: "100%",
                  borderRadius: 2,
                }}
              >
                <AccordionSummary
                  expandIcon=""
                  aria-controls="panel1-content"
                  id="panel1-header"
                  sx={{
                    background: "#e0f7e9",
                    color: "#000000",
                    fontWeight: "bold",
                    borderRadius: 2,

                    "&:hover": {
                      background: "#e0f7e9",
                      color: "#000000",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "",
                        fontSize: "1.2rem",
                      }}
                    >
                      Add Influencer From the Platform
                    </Typography>

                    <Checkbox
                      checked={expanded === "panel3" ? true : false}
                      label="Label"
                      sx={{
                        color: "white",
                        "&.Mui-checked": {
                          color: "white",
                        },
                      }}
                    />
                  </Box>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    boxShadow: "none",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "1rem",
                    }}
                  >
                    <Autocomplete
                      id="free-solo-demo"
                      freeSolo
                      // value={value}
                      onChange={(event, newValue) => {
                        console.log("new value", newValue);
                        console.log("event", event);
                      }}
                      style={{
                        padding: "1rem",
                        borderRadius: "15px",
                        border: "2px solid black",
                        width: "100%",
                      }}
                      options={influencers}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField {...params} label="influencername" />
                      )}
                    />
                    {/* <input
                      onChange={(e) => {
                        let textf = e.target.value.trimStart()
                        setInfluencerName(textf);
                      }}
                      type="text"
                      placeholder="Enter Name"
                      style={{
                        padding: "1rem",
                        borderRadius: "15px",
                        border: "2px solid black",
                        width: "100%",
                      }}
                    /> */}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      width: "100%",
                    }}
                  >
                    {influencerName.length ? (
                      <Button
                        onClick={() => {
                          appendInfluencersByName(influencerName);
                          setInfluencerName("");
                        }}
                        variant="contained"
                        sx={{
                          padding: "0",
                          marginTop: "1rem",
                          textAlign: "center",
                        }}
                      >
                        Add
                      </Button>
                    ) : null}
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                gap: "1rem",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Button
                variant="outlined"
                size="small"
                sx={{
                  textTransform: "none",
                  borderRadius: 10,
                  fontWeight: "bold",
                  marginRight: 1,
                  background: "#e0f7e9",
                  borderWidth: "2px",
                  color: "black",
                  borderColor: "#e0f7e9",
                  "&:hover": {
                    borderWidth: "2px",
                    borderColor: "#e0f7e9",
                    background: "#c5edd6ff",
                  },
                }}
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{
                  bgcolor: error.status ? "#e81e61ff" : "#3dbf73ff",
                  boxShadow: "none",
                  fontWeight: "bold",
                  borderRadius: 10,
                  // padding: '10px 30px',
                  "&:hover": {
                    bgcolor: error.status ? "#3dbf73ff" : "#4cb04fff",
                  },
                }}
                onClick={handleClose}
                endIcon={
                  loading ? (
                    <LoadingSpinner size={25} />
                  ) : isSucessful ? (
                    <AddTaskOutlined />
                  ) : (
                    error.status && <WarningAmberOutlined />
                  )
                }
              >
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {/* <Tab
            style={{ fontSize: "12px", color: "black", fontWeight: "bold" }}
            label="Instagram"
            {...a11yProps(0)}
            icon={<img width="20" src={Insta} />}
            iconPosition="start"
          /> */}
          <Tab
            style={{ fontSize: "12px", color: "black", fontWeight: "bold" }}
            label="Youtube"
            {...a11yProps(1)}
            icon={<img width="20" src={Youtube} />}
            iconPosition="start"
          />
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} sx={{ p: 0 }}>
        <Grid container justifyContent={"space-between"}>
          <Grid item size={{ md: 7.75, xs: 12 }}>
            <TextField
              size="small"
              placeholder="Search Influencer by Name"
              value={searchTerm} // Control the TextField with the searchTerm state
              onChange={handleSearchChange}
              sx={{
                borderRadius: 8, // Adjust the value as needed for your desired border radius

                "& .MuiOutlinedInput-root": {
                  borderRadius: 8, // Adjust the value as needed for your desired border radius
                  paddingRight: 0.4,
                  pl: 2,
                  "&:hover fieldset": {
                    borderColor: "black", // Set the border color for hover state
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "black", // Set the border color for focused state
                  },
                },
              }}
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <IconButton size='small'>
              //         <PersonSearchRounded />
              //       </IconButton>

              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>
          <Grid item size={{ md: 3.5, xs: 12 }}>
            <Box>
              <Button
                variant="contained"
                onClick={handleOpen}
                fullWidth
                sx={{
                  background: "#1f2933ff",
                  fontWeight: "bold",
                  borderRadius: 8,
                  boxShadow: "none",
                  "&:hover": {
                    background: "#1f2933ff",
                  },
                }}
                startIcon={<Add />}
              >
                Add Influencers
              </Button>
            </Box>
          </Grid>
          {/* 
          {isSmallScreen ? (
            <Grid item xs={2} align="center" justify="center">
              <IconButton
                sx={{
                  background: "black",
                  color: "white",
                  "&:hover": { background: "black", color: "white" },
                }}
              >
                <AddRounded />
              </IconButton>
            </Grid>
          ) : (
            <Grid item md={3} xs={5} align="end" justify={"center"}>
              <Button
                variant="contained"
                startIcon={<AddRounded />}
                sx={{
                  background: "black",
                  boxShadow: "none",
                  textTransform: "none",
                  fontSize: { md: "1rem", xs: ".6rem" },
                  // padding  : {md : '4px 5px', xs :'4px  5px'},
                  borderRadius: 10,
                  width: "90%",

                  "&:hover": {
                    background: "black",
                    boxShadow: "none",
                  },
                }}
              >
                Add Influencers
              </Button>
            </Grid>
          )} */}

          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Container
              // maxWidth={"xl"}
              sx={{
                minWidth: "100%",
                overflowX: "hidden",
                paddingX: "0 !important",
                paddingLeft: "0 !important",
                paddingRight: "0 !important",
                marginX: 0,
                marginTop: 0,
                width: "100%",
              }}
            >
              <Table
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                // collectionData={data}
                influencerData={searchedData}
                loading={loading}
              />
            </Container>
          </Grid>
          <Grid
            mt={2}
            item
            size={{ md: 12, xs: 12 }}
            sx={{
              bgcolor: "#f2f7ffff",
              borderRadius: 5,
            }}
          >
            <Grid container p={2}>
              <Grid item size={{ md: 12, xs: 12 }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Assigned Campaign
                </Typography>
              </Grid>
              <Grid item size={{ md: 12, xs: 12 }} p={3}>
                {/* {assignedCampaign?.map((item) => (
                  <Grid container key={item.id} mb={2}>
                    <Grid
                      item
                      md={1}
                      xs={3}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Avatar alt="Remy Sharp" src={item.img} />
                    </Grid>
                    <Grid
                      item
                      md={10}
                      xs={9}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography fontWeight={"bold"} fontSize={"1rem"}>
                        Kurkure
                      </Typography>
                      <Typography fontSize={".9rem"}>
                        Targetr : 1.23 Million Conversations
                      </Typography>
                    </Grid>
                  </Grid>
                ))} */}
              </Grid>
              {/* {
                assignedCampaign.map((item) => (
                  
                ))
              } */}

              {/* <Grid item md={1.5} xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>

                <Avatar alt="Remy Sharp" src='https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg' />
              </Grid>
              <Grid item md={10} xs={10} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography fontWeight={'bold'} fontSize={'1rem'}>Kurkure</Typography>
                <Typography fontSize={'.9rem'}>Targetr : 1.23 Million Conversations</Typography>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Grid container>
          <Grid item size={{ md: 9, xs: 10 }}>
            <TextField
              size="small"
              placeholder="Search Influencer by Name"
              sx={{
                borderRadius: 8, // Adjust the value as needed for your desired border radius

                "& .MuiOutlinedInput-root": {
                  borderRadius: 8, // Adjust the value as needed for your desired border radius
                  paddingRight: 0.4,
                  pl: 2,
                  "&:hover fieldset": {
                    borderColor: "black", // Set the border color for hover state
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "black", // Set the border color for focused state
                  },
                },
              }}
              fullWidth
              // InputProps={{
              //   endAdornment: (
              //     <InputAdornment position="end">
              //       <IconButton size='small'>
              //         <PersonSearchRounded />
              //       </IconButton>

              //     </InputAdornment>
              //   ),
              // }}
            />
          </Grid>

          {/* {isSmallScreen ? (
            <Grid item xs={2} align="center" justify="center">
              <IconButton
                sx={{
                  background: "black",
                  color: "white",
                  "&:hover": { background: "black", color: "white" },
                }}
              >
                <AddRounded />
              </IconButton>
            </Grid>
          ) : (
            <Grid item md={3} xs={5} align="end" justify={"center"}>
              <Button
                variant="contained"
                startIcon={<AddRounded />}
                sx={{
                  background: "black",
                  boxShadow: "none",
                  textTransform: "none",
                  fontSize: { md: "1rem", xs: ".6rem" },
                  // padding  : {md : '4px 5px', xs :'4px  5px'},
                  borderRadius: 10,
                  width: "90%",

                  "&:hover": {
                    background: "black",
                    boxShadow: "none",
                  },
                }}
              >
                Add Influencers
              </Button>
            </Grid>
          )} */}

          <Grid item size={{ md: 12, xs: 12 }} mt={2}>
            <Table influencerData={influencers} loading={loading} />
          </Grid>
          <Grid
            mt={2}
            item
            size={{ md: 12, xs: 12 }}
            sx={{
              bgcolor: "#f2f7ffff",
              borderRadius: 5,
            }}
          >
            <Grid container p={2}>
              <Grid item size={{ md: 12, xs: 12 }}>
                <Typography sx={{ fontWeight: "bold", fontSize: "1.2rem" }}>
                  Assigned Campaign
                </Typography>
              </Grid>
              <Grid item size={{ md: 12, xs: 12 }} p={3}>
                {assignedCampaign?.map((item) => (
                  <Grid container key={item.id} mb={2}>
                    <Grid
                      item
                      size={{ md: 1, xs: 3 }}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <Avatar alt="Remy Sharp" src={item.img} />
                    </Grid>
                    <Grid
                      item
                      size={{ md: 10, xs: 9 }}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                      }}
                    >
                      <Typography fontWeight={"bold"} fontSize={"1rem"}>
                        Kurkure
                      </Typography>
                      <Typography fontSize={".9rem"}>
                        Targetr : 1.23 Million Conversations
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              {/* {
                assignedCampaign.map((item) => (
                  
                ))
              } */}

              {/* <Grid item md={1.5} xs={1.5} sx={{ display: 'flex', alignItems: 'center' }}>

                <Avatar alt="Remy Sharp" src='https://icon-library.com/images/avatar-icon-images/avatar-icon-images-4.jpg' />
              </Grid>
              <Grid item md={10} xs={10} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Typography fontWeight={'bold'} fontSize={'1rem'}>Kurkure</Typography>
                <Typography fontSize={'.9rem'}>Targetr : 1.23 Million Conversations</Typography>
              </Grid> */}
            </Grid>
          </Grid>
        </Grid>
      </CustomTabPanel>
    </Box>
  );
};

export default BasicTabs;
